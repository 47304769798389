import { SectionContainer } from '../SectionContainer';
import { useGroupDocsQuery } from 'modules/group/hooks/use-group-query';
import { Spinner } from 'components/ui/Spinner';
import { GroupFiles } from './GroupFiles';
import { Common } from './Common';
import { useState } from 'react';
import { Group, SegmentedControl } from '@mantine/core';

export const Documents = () => {
  const [tab, setTab] = useState('groupFiles');
  const { data, isFetching } = useGroupDocsQuery();

  return (
    <SectionContainer>
      {isFetching && <Spinner fullscreen />}

      <Group align="center" position="center" mb={20}>
        <SegmentedControl
          value={tab}
          onChange={setTab}
          color="dark"
          radius={6}
          data={[
            { label: 'Сохраненные', value: 'groupFiles' },
            {
              label: 'Общие',
              value: 'commonFiles',
            },
          ]}
        />
      </Group>

      {tab === 'groupFiles' && <GroupFiles data={data} />}
      {tab === 'commonFiles' && <Common data={data?.common} />}
    </SectionContainer>
  );
};
