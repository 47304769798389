import { httpClient } from 'core/providers/HttpClientProvider';

export const authUser = async () => {
  const { data } = await httpClient.get('/auth');

  return data;
};

export const loginUser = async ({ email, password }) => {
  const { data } = await httpClient.post('/auth/login', {
    email: email?.toLowerCase(),
    password,
  });

  return data;
};

export const signupUser = async ({
  email,
  password,
  passwordConfirm,
  firstName,
  country,
  city,
}) => {
  const { data } = await httpClient.post('/auth/signup', {
    email: email?.toLowerCase(),
    password,
    passwordConfirm,
    firstName,
    country,
    city,
  });

  return data;
};

export const forgotPassword = async ({ email }) => {
  const { data } = await httpClient.post('/auth/forgot-password', {
    email: email?.toLowerCase(),
  });

  return data;
};

export const resetPassword = async (values) => {
  const { data } = await httpClient.post('/auth/reset-password', values);

  return data;
};
