import { createStyles, rem } from '@mantine/core';
import {
  greyDark,
  whiteDark,
  isDark,
  getBorderColor,
} from '../../core/theme/utils';

export const useStyles = createStyles((theme, { bgColor, padding }) => {
  return {
    content: {
      'backgroundColor': bgColor
        ? isDark(theme)
          ? theme.colors.dark[7]
          : bgColor
        : greyDark(theme),

      'label + div[id*="description"]': { paddingLeft: rem(5) },
      'label': { fontSize: '12px !important', paddingLeft: rem(5) },
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      padding: rem(16),
      backgroundColor: whiteDark(theme),
    },
    body: {
      padding: `${rem(padding ?? 16)} !important`,
      backgroundColor: 'transparent',
    },
    saveButton: {
      borderTop: `1px solid ${getBorderColor(theme)}`,
      backgroundColor: whiteDark(theme),
      padding: rem(16),
      position: 'sticky',
      bottom: 0,
      zIndex: theme.other.zIndex.drawer,
    },
  };
});
