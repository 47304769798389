import {
  IconInfoCircleFilled,
  IconInfoCircle,
  IconAlertCircle,
  IconAlertCircleFilled,
} from '@tabler/icons-react';
import { Tooltip, Flex, useMantineTheme, Text, Group } from '@mantine/core';
import { isDark } from 'core/theme/utils';

export const InfoTooltip = ({
  title,
  label,
  iconProps = {},
  size = '1rem',
  ...rest
}) => {
  const theme = useMantineTheme();
  const { color, solid = true } = iconProps;
  const iconColor =
    color || (isDark(theme) ? theme.white : theme.colors.dark[5]);

  return (
    <Group spacing={3} noWrap>
      {title && <Text lh={1}>{title}</Text>}

      <Tooltip
        label={label}
        withArrow
        multiline
        width={250}
        events={{ hover: true, touch: true }}
        {...rest}
      >
        <Flex c={iconColor} align="center" top={1} pos="relative">
          {solid ? (
            <IconInfoCircleFilled size={size} />
          ) : (
            <IconInfoCircle size={size} />
          )}
        </Flex>
      </Tooltip>
    </Group>
  );
};

export const AttentionTooltip = ({ label, iconProps = {}, ...rest }) => {
  return (
    <Tooltip
      label={label}
      withArrow
      multiline
      width={250}
      events={{ hover: true, touch: true }}
      {...rest}
    >
      <Flex c={iconProps.color}>
        {iconProps.solid ? (
          <IconAlertCircleFilled size={iconProps.size || 16} />
        ) : (
          <IconAlertCircle size={iconProps.size || 16} />
        )}
      </Flex>
    </Tooltip>
  );
};
