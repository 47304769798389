import { Title } from '@mantine/core';
import { useStyles } from './Settings.styles';
import { useSettingsQuery } from './hooks/use-settings-query';
// import { useUpdateSettings } from './hooks/use-settings-mutation';

export const Settings = () => {
  const { classes } = useStyles();
  const { data, isLoading } = useSettingsQuery();
  // const { mutate } = useUpdateSettings();

  if (isLoading) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Title>Настройки</Title>

      <div>App version 1.0.2</div>

      {data && <pre>{JSON.stringify(data, null, 2)}</pre>}
    </div>
  );
};
