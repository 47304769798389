import { IconSquareRoundedXFilled } from '@tabler/icons-react';
import { rem, Box } from '@mantine/core';

export const DeleteButton = ({ form, index }) => {
  return (
    <Box
      style={{
        cursor: 'pointer',
        position: 'absolute',
        zIndex: 1,
        top: rem(-7),
        right: rem(-7),
      }}
    >
      <IconSquareRoundedXFilled
        color="red"
        size="1.2rem"
        onClick={() => {
          form.removeListItem('schedule', index);
        }}
      />
    </Box>
  );
};
