import { Paper, createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  bgColor: {
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.black : theme.colors.gray[0],
    boxShadow: theme.other.shadow,
  },
}));

export const PaperBackground = ({ children, className, ...props }) => {
  const { classes, cx } = useStyles();

  return (
    <Paper className={cx(classes.bgColor, className)} {...props}>
      {children}
    </Paper>
  );
};
