import { SectionContainer } from '../SectionContainer';
import { useState } from 'react';
import { Group, SegmentedControl } from '@mantine/core';
import { Storage } from './Storage';
import { Orders } from './Orders';

export const Store = () => {
  const [tab, setTab] = useState('actual');

  return (
    <SectionContainer>
      <Group align="center" position="center" mb={20}>
        <SegmentedControl
          value={tab}
          onChange={setTab}
          color="dark"
          radius={6}
          data={[
            { label: 'В наличии', value: 'actual' },
            { label: 'Операции', value: 'operations' },
          ]}
        />
      </Group>

      {tab === 'actual' && <Storage />}
      {tab === 'operations' && <Orders />}
    </SectionContainer>
  );
};
