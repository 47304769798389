import { Card, CardTitle, Parameter } from '../components';
import { IconColorSwatch } from '@tabler/icons-react';

export const Meetings = ({ data }) => {
  const {
    count,
    participants,
    participantsAvg,
    money,
    moneyAvg,
    moneyLiterature,
    moneyLiteratureCount,
    moneyTransfer,
    moneyTransferCount,
    newcomers,
    newcomersAvg,
  } = data;

  return (
    <Card title="Собрания" Icon={IconColorSwatch}>
      <CardTitle value={count} label="проведено" />

      {!!count && (
        <>
          <Parameter name="новички" total={newcomers} average={newcomersAvg} />
          <Parameter
            name="посетили"
            total={participants}
            average={participantsAvg}
          />

          <Parameter name="наличные" total={money} average={moneyAvg} />
          <Parameter
            name="литература"
            total={moneyLiterature}
            count={moneyLiteratureCount}
            countColumnLabel="собрания"
          />
          <Parameter
            name="перевод"
            total={moneyTransfer}
            count={moneyTransferCount}
            countColumnLabel="собрания"
          />
        </>
      )}
    </Card>
  );
};
