import { Text } from '@mantine/core';
import { formatNumber } from 'utils/string';

export const CurrencyCell = ({
  value,
  currency,
  onlyValue = false,
  ...rest
}) => {
  if (!value) {
    return <Text {...rest}>0 {!onlyValue ? currency : ''}</Text>;
  }

  const formattedValue = formatNumber(value);

  if (!onlyValue && currency) {
    return (
      <Text {...rest}>
        {formattedValue} {currency}
      </Text>
    );
  }

  return <Text {...rest}>{formattedValue}</Text>;
};
