import { GLOBAL_ROLE, GROUP_ROLE } from '../roles';
import { createFullAccess } from '../utils';

export const orderPermissions = [
  ...createFullAccess(GLOBAL_ROLE.admin, 'order'),
  ...createFullAccess(GROUP_ROLE.group_admin, 'order'),
  ...createFullAccess(GROUP_ROLE.group_storekeeper, 'order'),
  ...createFullAccess(GROUP_ROLE.group_paymaster, 'order'),

  {
    role: GROUP_ROLE.group_member,
    resource: 'order',
    action: 'read',
  },
  {
    role: GROUP_ROLE.group_leader,
    resource: 'order',
    action: 'read',
  },
];
