import { DatePickerField } from 'components/ui/form';
import { Group } from '@mantine/core';

export const DateFilterForm = () => {
  return (
    <Group spacing={5} position="apart">
      <DatePickerField
        clearable
        name="dateFrom"
        label="Дата с:"
        valueFormat="DD/MM/YYYY"
        style={{ flexBasis: '48%' }}
      />

      <DatePickerField
        clearable
        name="dateTo"
        label="Дата до:"
        valueFormat="DD/MM/YYYY"
        style={{ flexBasis: '48%' }}
      />
    </Group>
  );
};
