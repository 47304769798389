import { Expandable } from '../../Expandable';
import { IconChevronDown } from '@tabler/icons-react';
import { Group, Text } from '@mantine/core';

const DefaultHeader = ({ toggle, opened }) => {
  return (
    <Group
      onClick={toggle}
      position="apart"
      align="center"
      style={{ cursor: 'pointer' }}
      w="100%"
    >
      <Text>{opened ? '' : 'Показать'}</Text>
      <IconChevronDown
        size={16}
        style={{ transform: opened ? 'rotate(180deg)' : '' }}
      />
    </Group>
  );
};

export const ExpandableCell = ({ Header, content }) => {
  return (
    <Expandable Header={Header || DefaultHeader} content={content} w="100%" />
  );
};
