import { useStyles } from './Table.styles';
import { Box } from '@mantine/core';

export const TableHeader = ({ columns = [] }) => {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.row, classes.header, classes.rowColumns)}>
      {columns.map((column) => (
        <Box
          miw={column.width}
          maw={column.maxWidth}
          className={classes.cell}
          key={column.name}
        >
          {column.headerRender ? column.headerRender() : column.title}
        </Box>
      ))}
    </div>
  );
};
