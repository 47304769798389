import { Link } from 'components/ui';
import { Center, Text, Box } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';

export const BackToMain = () => {
  return (
    <Link to="/" size="sm" ml={10}>
      <Text color="dimmed" size="sm" align="center">
        <Center inline>
          <IconArrowLeft size={12} stroke={1.5} />
          <Box ml={5}>На главную страницу</Box>
        </Center>
      </Text>
    </Link>
  );
};
