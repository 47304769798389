const commonRootStyles = {
  padding: `1.2rem 1rem !important`,
};

export const getCommonStyles = () => ({ root: commonRootStyles });

export const getErrorStyles = (theme) => ({
  root: {
    'backgroundColor': theme.colors.red[6],
    'borderColor': theme.colors.red[6],

    '&::before': { backgroundColor: theme.white },
    ...commonRootStyles,
    'paddingLeft': '1.8rem !important',
    'minHeight': '100px',
  },

  title: { color: theme.white },
  description: { color: theme.white },
  closeButton: {
    'color': theme.white,
    '&:hover': { backgroundColor: theme.colors.red[7] },
  },
});

export const getSuccessStyles = (theme) => ({
  root: {
    'backgroundColor': theme.colors.teal[7],
    // 'borderColor': theme.colors.teal[7],

    '&::before': { backgroundColor: theme.white },
    ...commonRootStyles,
  },
  icon: { color: theme.colors.teal[9], backgroundColor: theme.white },

  title: { color: theme.white },
  description: { color: theme.white },
  closeButton: {
    'color': theme.white,
    '&:hover': { backgroundColor: theme.colors.teal[6] },
  },
});

export const getInfoStyles = (theme) => ({
  root: {
    'backgroundColor': theme.colors.blue[6],
    'borderColor': theme.colors.blue[6],

    '&::before': { backgroundColor: theme.white },
    ...commonRootStyles,
  },

  title: {
    color: theme.white,
    fontWeight: 700,
    fontSize: '16px',
  },
  description: { color: theme.white },
  closeButton: {
    'color': theme.white,
    '&:hover': { backgroundColor: theme.colors.blue[7] },
  },
});
