import { FileButton, Button, Text, Box } from '@mantine/core';
import { showError } from 'core/notifications';
import { useRef } from 'react';

export const FileServerUpload = ({
  mimeType,
  sizeLimit = 10,
  onUpload,
  ...rest
}) => {
  const fileRef = useRef(null);

  return (
    <Box {...rest}>
      <FileButton
        ref={fileRef}
        onChange={(file) => {
          if (file.size / 1000000 > sizeLimit) {
            showError({
              title: 'Слишком большой размер файла',
              message: `Максимальный размер файла ${sizeLimit} МБ`,
            });
          } else {
            if (typeof onUpload === 'function') {
              onUpload({ file, fileName: encodeURI(file.name) });
            }
          }

          fileRef.current.value = null;
        }}
        accept={mimeType}
      >
        {(props) => (
          <Box display="inline-block">
            <Button {...props} size="xs" color="teal">
              Загрузить
            </Button>
            <Text color="dimmed" fz={11} mt={5} align="center">
              Не более {sizeLimit} Мб
            </Text>
          </Box>
        )}
      </FileButton>
    </Box>
  );
};
