import { CheckboxField, SelectField } from 'components/ui/form';
import { weekDaysOptions } from '../../../Schedule/form/const';
import { useGroup } from 'modules/group/context';

export const FilterForm = () => {
  const { jobs, jobEndingLimit } = useGroup();
  const endingLabel = `Подходят к концу (менее ${jobEndingLimit || 7} дней)`;

  const jobTitles = jobs.map((job) => job.title);
  const uniqueJobNames = new Set(jobTitles);

  const jobNamesOptions = Array.from(uniqueJobNames).map((jobName) => ({
    value: jobName,
    label: jobName,
  }));

  return (
    <>
      <SelectField
        label="День недели"
        clearable
        name="day"
        data={weekDaysOptions}
      />

      <SelectField
        label="Служение"
        clearable
        name="job"
        data={jobNamesOptions}
      />

      <CheckboxField name="available" label="Свободные служения" />
      <CheckboxField name="expired" label="Закончившиеся служения" />
      <CheckboxField name="ending" label={endingLabel} />
    </>
  );
};
