import { Anchor, TextInput, Button, Text, Group } from '@mantine/core';
import { getHotkeyHandler } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { useState } from 'react';
import { useForgotPasswordMutation } from '../hooks/use-auth-mutation';
import { showSuccess } from '../../../core/notifications';

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);

  const { mutate, isLoading } = useForgotPasswordMutation();

  const submitHandler = () => {
    const regExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    console.log('submitHandler', regExp.test(email));

    if (!regExp.test(email)) {
      setError(true);
    } else {
      console.log('send');
      mutate(
        { email },
        {
          onSuccess: () => {
            modals.closeAll();
            showSuccess({
              title: 'Письмо с инструкцией отправлено на ваш Email',
            });
          },
        },
      );
    }
  };

  return (
    <>
      <TextInput
        data-autofocus
        placeholder="Введите ваш Email"
        value={email}
        onChange={(event) => {
          if (error) {
            setError(false);
          }

          setEmail(event.target.value);
        }}
        error={error && 'Неверный формат Email'}
        onKeyDown={getHotkeyHandler([['Enter', submitHandler]])}
      />
      <Group mt={20} spacing={10} position="right">
        <Button disabled={isLoading} onClick={modals.closeAll}>
          Отмена
        </Button>
        <Button
          color="teal"
          disabled={!email}
          loading={isLoading}
          onClick={submitHandler}
        >
          Отправить
        </Button>
      </Group>
    </>
  );
};

export const ForgotPasswordLink = () => {
  return (
    <Anchor
      component="button"
      size="sm"
      onClick={() => {
        modals.open({
          title: (
            <Text fw={700} fz={18}>
              Сброс пароля
            </Text>
          ),
          children: <ForgotPasswordForm />,
          closeOnClickOutside: false,
          closeOnEscape: false,
          withCloseButton: false,
        });
      }}
    >
      Забыли пароль?
    </Anchor>
  );
};
