import { SectionContainer } from '../SectionContainer';
import { Operations } from './Operations';

export const Finance = () => {
  return (
    <SectionContainer>
      <Operations />
    </SectionContainer>
  );
};
