import { GLOBAL_ROLE, GROUP_ROLE } from '../roles';
import { createFullAccess } from '../utils';

export const storePermissions = [
  ...createFullAccess(GLOBAL_ROLE.admin, 'store'),
  ...createFullAccess(GROUP_ROLE.group_admin, 'store'),
  ...createFullAccess(GROUP_ROLE.group_storekeeper, 'store'),

  {
    role: GROUP_ROLE.group_paymaster,
    resource: 'store',
    action: 'read',
  },
  {
    role: GROUP_ROLE.group_member,
    resource: 'store',
    action: 'read',
  },
  {
    role: GROUP_ROLE.group_leader,
    resource: 'store',
    action: 'read',
  },
];
