// DB keeps geo location as a string in format: "lat,lng" anf that's ok for yandex Map.
// But for yandex geolocation link we should use "lng,lat"

const BASE_URL = 'https://yandex.ru/maps/';

export const getGeoLocationLink = (geoLocationCoords) => {
  if (geoLocationCoords) {
    const [lat, lng] = geoLocationCoords.split(',');
    const coords = `${lng.trim()},${lat.trim()}`;

    return `${BASE_URL}/?pt=${coords}&z=18&l=map`;
  }

  return '';
};
