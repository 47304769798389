import { Menu } from '@mantine/core';
import {
  IconTrash,
  IconSearch,
  IconDotsVertical,
  IconEdit,
  IconChecks,
} from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { useTable } from '../context';
import { useGrantAccess } from 'hooks';

export const ActionsCell = ({ row, className }) => {
  const navigate = useNavigate();
  let { canGroup } = useGrantAccess();

  const { actions, disableActions, resource, useGroupIdFromRow } = useTable();
  let { onDelete, onView, onEdit, onComplete } = actions;

  const disabledActions =
    typeof disableActions === 'function' ? disableActions(row) : [];

  const canDelete = canGroup('delete', resource, useGroupIdFromRow && row.id);
  const canUpdate = canGroup('update', resource, useGroupIdFromRow && row.id);
  const canRead = canGroup('read', resource, useGroupIdFromRow && row.id);

  onDelete = !canDelete || disabledActions.includes('delete') ? null : onDelete;
  onView = !canRead || disabledActions.includes('view') ? null : onView;
  onEdit = !canUpdate || disabledActions.includes('edit') ? null : onEdit;
  onComplete =
    !canUpdate || disabledActions.includes('complete') ? null : onComplete;

  if (!onDelete && !onView && !onEdit && !onComplete) {
    return null;
  }

  return (
    <Menu
      h="100%"
      openDelay={100}
      closeDelay={400}
      position="bottom-end"
      offset={5}
      shadow="lg"
      withinPortal
      className={className}
    >
      <Menu.Target>
        <IconDotsVertical size="1rem" />
      </Menu.Target>

      <Menu.Dropdown>
        {onView && (
          <Menu.Item
            icon={<IconSearch size={14} />}
            onClick={(e) => {
              e.preventDefault();

              navigate(`./${row?.id}`);
            }}
          >
            Открыть
          </Menu.Item>
        )}

        {onEdit && (
          <Menu.Item
            icon={<IconEdit size={14} />}
            onClick={(e) => {
              e.preventDefault();

              if (typeof onEdit === 'function') {
                onEdit(row);
              }
            }}
          >
            Редактировать
          </Menu.Item>
        )}

        {onComplete && (
          <Menu.Item
            icon={<IconChecks size={14} />}
            onClick={(e) => {
              e.preventDefault();

              if (typeof onComplete === 'function') {
                onComplete(row);
              }
            }}
          >
            Завершить
          </Menu.Item>
        )}

        {onDelete && (
          <Menu.Item
            color="red"
            icon={<IconTrash size={14} />}
            onClick={(e) => {
              e.preventDefault();

              if (typeof onDelete === 'function') {
                onDelete(row);
              }
            }}
          >
            Удалить
          </Menu.Item>
        )}
      </Menu.Dropdown>
    </Menu>
  );
};
