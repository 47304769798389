import { createStore, createEvent } from 'effector';
import { useStore } from 'effector-react';

export const setGroup = createEvent();
export const setJobs = createEvent();

export const $group = createStore({});

// $group.watch((s) => console.log('group store', s));

$group.on(setGroup, (_, group) => group);
$group.on(setJobs, (state, jobs) => ({ ...state, jobs }));

export const useGroup = () => {
  return useStore($group);
};
