import { httpClient } from 'core/providers/HttpClientProvider';

export const getAllGroups = async () => {
  const { data } = await httpClient.get(`/group/all`);

  return data;
};

export const getGroup = async (id) => {
  const { data } = await httpClient.get(`/group/${id}`);

  return data;
};

export const getGroupMembers = async (id) => {
  const { data } = await httpClient.get(`/member/group-members/${id}`);

  return data;
};

export const addGroup = async (group) => {
  const { data } = await httpClient.post('/group', group);

  return data;
};

export const deleteGroup = async (id) => {
  const { data } = await httpClient.delete(`/group/${id}`);

  return data;
};

export const updateGroup = async ({ id, group }) => {
  const { data } = await httpClient.put(`/group/${id}`, group);

  return data;
};

export const updateGroupSchedule = async ({ groupId, schedule }) => {
  const { data } = await httpClient.post(
    `/group/${groupId}/schedule`,
    schedule,
  );

  return data;
};

/*
   MEETING
*/

export const addMeeting = async ({ groupId, meeting }) => {
  const { data } = await httpClient.post(`/meeting/${groupId}`, meeting);

  return data;
};

export const updateMeeting = async ({ groupId, meeting }) => {
  const { data } = await httpClient.put(
    `/meeting/${groupId}/${meeting.id}`,
    meeting,
  );

  return data;
};

export const deleteMeeting = async ({ groupId, id }) => {
  const { data } = await httpClient.delete(`/meeting/${groupId}/${id}`);

  return data;
};

/*
   STORE
*/

export const getStore = async (groupId) => {
  const result = await httpClient.get(`/store/${groupId}`);

  return result.data;
};

export const addStoreItem = async ({ groupId, item }) => {
  const { data } = await httpClient.post(`/store/${groupId}`, item);

  return data;
};

export const editStoreItem = async ({ groupId, item }) => {
  const { data } = await httpClient.put(`/store/${groupId}/${item.id}`, item);

  return data;
};

export const deleteStoreItem = async ({ groupId, itemId }) => {
  const { data } = await httpClient.delete(`/store/${groupId}/${itemId}`);

  return data;
};

export const fixStore = async ({ groupId, data }) => {
  const result = await httpClient.post(`/store/${groupId}/fix`, data);

  return result.data;
};

/*
   ORDERS
*/

export const addOrder = async ({ groupId, order }) => {
  const { data } = await httpClient.post(`/order/${groupId}`, order);

  return data;
};

export const editOrder = async ({ groupId, order }) => {
  const { data } = await httpClient.put(`/order/${groupId}/${order.id}`, order);

  return data;
};

export const completeOrder = async ({ groupId, order }) => {
  const { data } = await httpClient.put(
    `/order/${groupId}/${order.id}/complete`,
    order,
  );

  return data;
};

export const deleteOrder = async ({ groupId, id }) => {
  const { data } = await httpClient.delete(`/order/${groupId}/${id}`);

  return data;
};

/*
   JOBS
*/

export const getJobs = async (groupId) => {
  const result = await httpClient.get(`/job/${groupId}`);

  return result.data;
};

export const addJob = async ({ groupId, job }) => {
  const { data } = await httpClient.post(`/job/${groupId}`, job);

  return data;
};

export const editJob = async ({ groupId, job }) => {
  const { data } = await httpClient.put(`/job/${groupId}/${job.id}`, job);

  return data;
};

export const deleteJob = async ({ groupId, id }) => {
  const { data } = await httpClient.delete(`/job/${groupId}/${id}`);

  return data;
};

/*
   REQUESTS
*/

export const getGroupMemberRequests = async (groupId) => {
  const { data } = await httpClient.get(`/request/${groupId}`);

  return data;
};

export const addMemberRequest = async ({ groupId, userId }) => {
  const { data } = await httpClient.post(`/request/${groupId}/${userId}`);

  return data;
};

export const acceptMemberRequest = async ({ requestId, groupId }) => {
  const { data } = await httpClient.put(`/request/${groupId}/${requestId}`);

  return data;
};

export const declineMemberRequest = async ({ requestId, groupId }) => {
  const { data } = await httpClient.delete(`/request/${groupId}/${requestId}`);

  return data;
};

/*
   FINANCE
*/

export const addFinanceOperation = async ({ groupId, operation }) => {
  const { data } = await httpClient.post(`/finance/${groupId}`, operation);

  return data;
};

export const deleteFinanceOperation = async ({ groupId, id }) => {
  const { data } = await httpClient.delete(`/finance/${groupId}/${id}`);

  return data;
};

/*
   REPORTS
*/

export const getGroupReport = async (groupId, params) => {
  const { data } = await httpClient.get(`/report/${groupId}`, { params });

  return data;
};

/*
   OTHER
*/

export const getGroupDocs = async (groupId) => {
  const { data } = await httpClient.get(`/docs/${groupId}`);

  return data;
};

export const downloadGroupFile = async ({ groupId, fileName }) => {
  const { data } = await httpClient.get(`/docs/${groupId}/${fileName}`, {
    responseType: 'blob',
  });

  return data;
};

export const downloadGroupCommonFile = async ({ groupId, fileName }) => {
  const { data } = await httpClient.get(`/docs/common/${groupId}/${fileName}`, {
    responseType: 'blob',
  });

  return data;
};

export const removeGroupFile = async ({ groupId, fileName }) => {
  const { data } = await httpClient.delete(`/docs/${groupId}/${fileName}`);

  return data;
};

export const uploadGroupFile = async ({ groupId, data }) => {
  const response = await httpClient.post(`/docs/${groupId}`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response?.data;
};
