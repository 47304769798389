import { initialFormState } from './schema';
import {
  useForm,
  FormProvider,
  DatePickerField,
  TextField,
  SelectField,
  TextAreaField,
} from 'components/ui/form';
import { DrawerFactory } from 'core/providers/DrawerProvider';
import { Stack, Checkbox, Button } from '@mantine/core';
import { getFullName } from 'utils/string';
import { useEditJob } from 'modules/group/hooks/use-job-mutation';

const calculateEndDate = (startDateValue, period) => {
  const startDate = new Date(startDateValue);
  const endDateMonth = startDate.getMonth() + period;

  return new Date(startDate.setMonth(endDateMonth));
};

export const EditForm = ({ job, members = [] }) => {
  const startDate = job.userInfo?.startDate || new Date();
  const endDate =
    job.userInfo?.endDate || calculateEndDate(startDate, job.period);

  const form = useForm({
    ...initialFormState,
    initialValues: {
      ...initialFormState.initialValues,
      ...job.userInfo,
      startDate,
      endDate,
    },
  });

  const isUnregistered = form.values.isUnregistered;

  const memberOptions = members.map((member) => {
    const memberName = getFullName(member);
    const label = memberName ? `${memberName} (${member.email})` : member.email;

    return { value: member.email, label };
  });

  const updateMutation = useEditJob();

  const submitHandler = (values) => {
    updateMutation.mutate(
      { ...job, userInfo: values },
      {
        onSuccess: () => {
          form.reset();
          DrawerFactory.close();
        },
      },
    );
  };

  return (
    <FormProvider form={form}>
      <Stack spacing={5} mb={20}>
        <DatePickerField
          clearable
          name="startDate"
          label="Начало"
          valueFormat="DD/MM/YYYY"
          onChange={(value) => {
            const endDate = calculateEndDate(value, job.period);

            form.setFieldValue('endDate', endDate);
          }}
        />
        <DatePickerField
          clearable
          name="endDate"
          label="Конец"
          valueFormat="DD/MM/YYYY"
        />

        <SelectField
          label="Служащий"
          clearable
          searchable
          name="email"
          data={memberOptions}
          disabled={isUnregistered}
        />

        <Checkbox
          size="xs"
          {...form.getInputProps('isUnregistered')}
          onChange={(event) => {
            form.setFieldValue('isUnregistered', event.currentTarget.checked);

            if (!event.currentTarget.checked) {
              form.setFieldValue('unregisteredUserName', '');
            } else {
              form.clearFieldError('email');
            }
          }}
          checked={form.getInputProps('isUnregistered').value}
          label="Служащий не зарегистрирован"
          mt={15}
          mb={5}
          disabled={!!form.values.email}
        />

        <TextField
          name="unregisteredUserName"
          placeholder="Имя"
          disabled={!isUnregistered}
        />

        <TextAreaField name="comment" minRows={5} label="Комментарий" />
      </Stack>

      <Button
        fullWidth
        size="md"
        mt={40}
        onClick={() => {
          const { hasErrors } = form.validate();

          if (!hasErrors) {
            submitHandler(form.values);
          }
        }}
        disabled={!(form.isValid() && form.isDirty())}
      >
        Сохранить
      </Button>
    </FormProvider>
  );
};
