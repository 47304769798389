import { DatePickerField, SelectField } from 'components/ui/form';
import {
  financeOperationOptions,
  financeSourceOptions,
} from 'modules/group/const';
import { Group } from '@mantine/core';

export const FilterForm = () => {
  return (
    <>
      <Group spacing={5} position="apart">
        <DatePickerField
          clearable
          name="dateFrom"
          label="Дата с:"
          valueFormat="DD/MM/YYYY"
          style={{ flexBasis: '49%' }}
        />

        <DatePickerField
          clearable
          name="dateTo"
          label="Дата до:"
          valueFormat="DD/MM/YYYY"
          style={{ flexBasis: '49%' }}
        />
      </Group>

      <SelectField
        clearable
        name="operationType"
        label="Тип операции"
        data={financeOperationOptions}
      />

      <SelectField
        clearable
        label="Категория"
        name="sourceType"
        data={financeSourceOptions}
      />
    </>
  );
};
