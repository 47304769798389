import { GLOBAL_ROLE, GROUP_ROLE } from '../roles';
import { createFullAccess } from '../utils';

export const reportPermissions = [
  ...createFullAccess(GLOBAL_ROLE.admin, 'report'),
  ...createFullAccess(GROUP_ROLE.group_admin, 'report'),
  ...createFullAccess(GROUP_ROLE.group_paymaster, 'report'),

  // { role: GROUP_ROLE.group_member, resource: 'report', action: 'read' },
  // { role: GROUP_ROLE.group_leader, resource: 'report', action: 'read' },
  {
    role: GROUP_ROLE.group_storekeeper,
    resource: 'report',
    action: 'read',
  },
];
