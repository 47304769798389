import { useYandexMap } from 'hooks/use-yandex-map';

const defaultMapOptions = {
  id: 'yandex-map-id',
  center: [41.2995, 69.2401],
  controls: ['typeSelector', 'zoomControl', 'fullscreenControl'],
  zoom: 12,
};

export const Map = (props) => {
  const options = Object.assign({}, defaultMapOptions, props);

  useYandexMap(options);

  return <div id={options.id} style={{ height: '100%', width: '100%' }}></div>;
};
