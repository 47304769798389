import { useMutation } from '@tanstack/react-query';
import { addFinanceOperation, deleteFinanceOperation } from '../api';
import { useOptions } from './use-query-options';

const mutationOptions = ({ queryClient, spinner, groupId }) => ({
  onMutate: () => {
    spinner.setActive(true);
  },
  onSettled: () => {
    spinner.setActive(false);
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['group', groupId] });
    queryClient.invalidateQueries({ queryKey: [`finance-${groupId}`] });
  },
});

export const useAddFinanceOperation = () => {
  const options = useOptions();

  return useMutation({
    mutationFn: (operation) =>
      addFinanceOperation({ groupId: options.groupId, operation }),
    ...mutationOptions(options),
  });
};

export const useDeleteFinanceOperation = () => {
  const options = useOptions();

  return useMutation({
    mutationFn: (id) =>
      deleteFinanceOperation({ groupId: options.groupId, id }),
    ...mutationOptions(options),
  });
};
