import { useQuery } from '@tanstack/react-query';
import {
  getGroup,
  getAllGroups,
  getGroupMembers,
  getGroupMemberRequests,
  getGroupReport,
  getGroupDocs,
} from '../api';
import { useParams } from 'react-router-dom';

export const useGroupId = () => {
  const { groupId } = useParams();

  return groupId;
};

export const useAllGroupQuery = () => {
  return useQuery({
    queryKey: ['groups-all'],
    queryFn: getAllGroups,
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 300000,
  });
};

export const useGroupQuery = () => {
  const groupId = useGroupId();

  return useQuery({
    queryKey: ['group', groupId],
    queryFn: () => getGroup(groupId),
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const useMembersQuery = () => {
  const groupId = useGroupId();

  return useQuery({
    queryKey: [`members-${groupId}`],
    queryFn: () => getGroupMembers(groupId),
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const useGroupRequestsQuery = () => {
  const groupId = useGroupId();

  return useQuery({
    queryKey: [`requests`],
    queryFn: () => getGroupMemberRequests(groupId),
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
};

export const useGroupReportQuery = (params) => {
  const groupId = useGroupId();
  const { dateFrom, dateTo } = params;

  return useQuery({
    queryKey: [`reports-${groupId}`, dateFrom, dateTo],
    queryFn: () => getGroupReport(groupId, { dateFrom, dateTo }),
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 60000,
    enabled: Boolean(dateFrom || dateTo),
  });
};

export const useGroupDocsQuery = () => {
  const groupId = useGroupId();

  return useQuery({
    queryKey: [`docs`],
    queryFn: () => getGroupDocs(groupId),
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
};
