import { useJFTQuery } from '../hooks/use-jft-query';
import {
  Title,
  Box,
  Text as TextComponent,
  Group,
  useMantineTheme,
  Skeleton,
} from '@mantine/core';
import { getDateFromJsDate } from 'utils/date';
import { MobileDivider } from 'components/Table/mobile';
import { Card } from 'components/ui';
import { isDark } from 'core/theme/utils';
import { useMediaQuery } from 'hooks';

const Text = ({ bold, color, ...rest }) => {
  const theme = useMantineTheme();

  return (
    <TextComponent
      fw={bold ? 700 : 500}
      fz={14}
      c={color || (isDark(theme) ? 'white' : 'black')}
      {...rest}
    />
  );
};

export const JFT = () => {
  const { month, day } = getDateFromJsDate(new Date());
  const { isMobile } = useMediaQuery();
  const theme = useMantineTheme();

  const { data, isLoading } = useJFTQuery(`${day}-${month}`);
  const renderPlacehloder = !data || isLoading;

  return (
    <Box mb={40}>
      <Title mb={20}>Ежедневник</Title>

      {renderPlacehloder && <Skeleton height={200} />}

      {data && (
        <Card height={200}>
          <Box p={isMobile ? 16 : 30} pb={isMobile ? 24 : 30}>
            <Group spacing={5}>
              <Text tt="uppercase" bold color={theme.colors.blue[6]}>
                {data.dateString}
              </Text>
              <Text tt="uppercase" bold>
                {data.title}
              </Text>
            </Group>

            <MobileDivider mb={15} mt={15} />

            <Text mt={10} italic>
              &laquo;{data.quote}&raquo;
            </Text>
            <Text fz={12} mt={5} color="dimmed">
              &mdash; {data.source}
            </Text>

            <Text mt={15}>
              <div dangerouslySetInnerHTML={{ __html: data.text }} />
            </Text>

            <MobileDivider mb={15} mt={15} />

            <Text bold color={theme.colors.blue[6]}>
              ТОЛЬКО СЕГОДНЯ:
            </Text>
            <Text>
              <div dangerouslySetInnerHTML={{ __html: data.justForToday }} />
            </Text>
          </Box>
        </Card>
      )}
    </Box>
  );
};
