import { useForm, FormProvider } from 'components/ui/form';
import { initialFormState } from './schema';
import { BaseForm } from './BaseForm';
import { useSpinner } from 'core/providers/SpinnerProvider';
import { useEffect } from 'react';
import { useAllGroupQuery } from 'modules/group/hooks/use-group-query';
import {
  useUpdateNews,
  useDeleteNews,
} from 'pages/Home/hooks/use-news-mutation';
import { formatDateToISOString } from 'utils/date';
import { DrawerFactory } from 'core/providers/DrawerProvider';

export const EditNewsForm = ({ data, isGroupMode }) => {
  const form = useForm({
    ...initialFormState,
    initialValues: { ...data, date: data.date && new Date(data.date) },
  });
  const spinner = useSpinner();

  const updateMutation = useUpdateNews(isGroupMode && data.groupId);
  const deleteMutation = useDeleteNews(isGroupMode && data.groupId);

  const { data: groupList = [], isFetching } = useAllGroupQuery();

  useEffect(() => {
    spinner.setActive(isFetching);
  }, [isFetching]);

  const submitHandler = () => {
    let dateISO = formatDateToISOString(form.values.date);

    updateMutation.mutate(
      {
        ...form.values,
        date: dateISO,
        groupTitle: groupList.find(
          (group) => group.id === Number(form.values.groupId),
        )?.title,
      },
      {
        onSuccess: () => {
          form.reset();
          DrawerFactory.close();
        },
      },
    );
  };

  return (
    <FormProvider form={form}>
      <BaseForm
        onSubmit={submitHandler}
        groups={groupList}
        onDelete={() => {
          deleteMutation.mutate(data, {
            onSuccess: () => {
              DrawerFactory.close();
            },
          });
        }}
      />
    </FormProvider>
  );
};
