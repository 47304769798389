import AccessControl from 'accesscontrol';
import {
  meetingPermissions,
  groupPermissions,
  jobPermissions,
  orderPermissions,
  storePermissions,
  userPermissions,
  requestPermissions,
  memberPermissions,
  financePermissions,
  reportPermissions,
  newsPermissions,
  settingsPermissions,
  docsPermissions,
  logPermissions,
} from './permissions';

const permissions = [
  ...meetingPermissions,
  ...groupPermissions,
  ...storePermissions,
  ...orderPermissions,
  ...userPermissions,
  ...jobPermissions,
  ...requestPermissions,
  ...memberPermissions,
  ...financePermissions,
  ...reportPermissions,
  ...newsPermissions,
  ...settingsPermissions,
  ...docsPermissions,
  ...logPermissions,
];

export const rbac = new AccessControl(permissions);

// console.log({ rbac: rbac.getGrants() });
