import { useGroup } from 'modules/group/context';
import { Table } from 'components/Table';
import { CurrencyCell } from 'components/Table/cells';
import { AddButton } from 'components/ui';
import { Group } from '@mantine/core';
import { IconCoins } from '@tabler/icons-react';
import { useGrantAccess } from 'hooks';
import { columnsConfig } from './columns-config';
import { AddForm } from './form';
import { DrawerFactory } from 'core/providers/DrawerProvider';
import { useDeleteFinanceOperation } from 'modules/group/hooks/use-finance-mutation';
import { MobileRender } from './MobileRender';
import { ExpandableRowRender } from './ExpandableRowRender';
import { filter } from './filter';
import { useGroupId } from 'modules/group/hooks/use-group-query';

export const Operations = () => {
  const group = useGroup();
  const groupId = useGroupId();

  const { canGroup } = useGrantAccess();

  const deleteMutation = useDeleteFinanceOperation();

  const addHandler = () => {
    DrawerFactory.open({ header: 'Новая операция', component: <AddForm /> });
  };

  return (
    <Table
      noPadding
      resource="finance"
      resourceEndpoint={`/finance/${groupId}`}
      tableKey={`finance-${groupId}`}
      columns={columnsConfig}
      onView={false}
      onAdd={addHandler}
      onDelete={(row) => {
        deleteMutation.mutate(row.id);
      }}
      titleRender={() => (
        <Group align="center" position="apart" mb={20}>
          {group.money !== undefined && (
            <Group align="center" spacing={5}>
              <IconCoins size={20} color="red" />
              <CurrencyCell value={group.money} />
            </Group>
          )}
          {canGroup('create', 'finance') && (
            <AddButton label="Новая операция" onClick={addHandler} />
          )}
        </Group>
      )}
      mobileRender={(row) => <MobileRender row={row} />}
      expandableRowRender={(row) => <ExpandableRowRender row={row} />}
      filter={filter}
    />
  );
};
