import { ActionIcon, Badge, Group } from '@mantine/core';
import { IconX } from '@tabler/icons-react';

const RemoveIcon = ({ onClick, color }) => (
  <ActionIcon size="sm" color={color} onClick={onClick}>
    <IconX size={12} />
  </ActionIcon>
);

export const FilterBadge = ({ name, label, setFilter, color = 'pink' }) => {
  return (
    <Badge
      size="sm"
      p={10}
      pr={3}
      style={{ border: '1px solid' }}
      color={color}
    >
      <Group spacing={0}>
        {label}
        <RemoveIcon
          color={color}
          onClick={() => setFilter((state) => ({ ...state, [name]: '' }))}
        />
      </Group>
    </Badge>
  );
};
