import { SectionContainer } from '../SectionContainer';
import { useState } from 'react';
import { Group, SegmentedControl } from '@mantine/core';
import { Staff } from './Staff';
import { Requests } from './Requests';
import { useGrantAccess, useSearch } from 'hooks';

export const Members = () => {
  const { tab: tabFromParams } = useSearch();
  const [tab, setTab] = useState(tabFromParams || 'staff');
  const { canGroup } = useGrantAccess();

  return (
    <SectionContainer>
      <Group align="center" position="center" mb={20}>
        <SegmentedControl
          value={tab}
          onChange={setTab}
          color="dark"
          radius={6}
          data={[
            { label: 'Список', value: 'staff' },
            {
              label: 'Заявки',
              value: 'requests',
              disabled: !canGroup('read', 'request'),
            },
          ]}
        />
      </Group>

      {tab === 'staff' && <Staff />}
      {tab === 'requests' && <Requests />}
    </SectionContainer>
  );
};
