import { Box, Text } from '@mantine/core';
import { useTable } from '../context';
import { useRow } from './MobileCard';
import { useStyles } from '../Table.styles';

export const MobileCell = ({
  children,
  withHeader = true,
  name,
  upperCase,
  align = 'left',
}) => {
  const { columns } = useTable();
  const row = useRow();
  const { classes } = useStyles();

  if (upperCase) {
    return (
      <Text fw={700} tt="uppercase" c="blue">
        {row[name]}
      </Text>
    );
  }

  let title = '';

  if (withHeader && name) {
    title = columns.find((column) => column.name === name)?.title;
  }

  const content = children || <Text color="dimmed">{row[name]}</Text>;

  return (
    <Box p={5} align={align}>
      {title && (
        <Text className={classes?.cellHeader} mb={5}>
          {title}
        </Text>
      )}
      {content}
    </Box>
  );
};
