import {
  createStore,
  createApi,
  createEvent,
  createEffect,
  forward,
} from 'effector';
import { httpClient } from 'core/providers/HttpClientProvider';
import { tokenStorageApi, userStorageApi } from 'core/local-storage';
import { authUser } from './api';

const token = tokenStorageApi.get();

const initialState = {
  user: userStorageApi.get(),
  token: token,
  isLogged: !!token,
  isLoading: false,
};

export const $auth = createStore(initialState);

export const authApi = createApi($auth, {
  setUser: (state, payload) => {
    return {
      ...state,
      user: payload,
      isLogged: !!payload,
      isLoading: false,
    };
  },
  startLoading: (state) => ({ ...state, isLoading: true }),
  completeLoading: (state) => ({ ...state, isLoading: false }),
  setToken: (state, payload) => ({ ...state, token: payload }),
  logout: (state) => ({
    ...state,
    token: null,
    isLogged: false,
    user: null,
    isLoading: false,
  }),
});

// $auth.watch((state) => console.log('auth state', state));

export const loadUser = createEvent();

export const loadUserFx = createEffect(async () => {
  return await authUser();
});

export const applyAuthTokenFX = createEffect((token) => {
  httpClient.defaults.headers['Authorization'] = `Bearer ${token}`;
  tokenStorageApi.set(token);
});

export const removeAuthTokenFX = createEffect(() => {
  delete httpClient.defaults.headers['Authorization'];
  tokenStorageApi.remove();
});

export const addUserToStorageFX = createEffect((user) => {
  userStorageApi.set(user);
});

export const removeUserFromStorageFX = createEffect(() => {
  userStorageApi.remove();
});

forward({ from: authApi.setToken, to: [applyAuthTokenFX] });

forward({ from: loadUser, to: [authApi.startLoading, loadUserFx] });

forward({ from: loadUserFx.doneData, to: [authApi.setUser] });

forward({ from: loadUserFx.fail, to: [authApi.logout] });

forward({ from: authApi.setUser, to: [addUserToStorageFX] });

forward({
  from: authApi.logout,
  to: [removeAuthTokenFX, removeUserFromStorageFX],
});
