import { addUserInitialFormState } from './schema';
import { useForm, FormProvider, TextField } from 'components/ui/form';
import { DrawerFactory } from 'core/providers/DrawerProvider';
import { Text, Group, Button, ActionIcon, Loader, Card } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import { useState } from 'react';
import { searchUserByEmail } from 'modules/user/api';
import { EmailCell } from 'components/Table/cells';
import { useAddUserGroup } from 'modules/user/hooks/use-user-mutation';
import { showError } from 'core/notifications';
import { useGroupId } from 'modules/group/hooks/use-group-query';

export const AddForm = () => {
  const [isBusy, setBusy] = useState(false);
  const [user, setUser] = useState();
  const groupId = useGroupId();

  const form = useForm(addUserInitialFormState);

  const addMutation = useAddUserGroup();

  const searchHandler = async () => {
    setBusy(true);

    searchUserByEmail({ email: form.values.email, groupId })
      .then((result) => {
        setUser(result);
      })
      .catch(() => {
        console.log('error');
      })
      .finally(() => {
        setBusy(false);
      });
  };

  return (
    <FormProvider form={form}>
      <Group align="flex-start" spacing={10} mt={20}>
        <TextField
          name="email"
          placeholder="Введите email"
          style={{ flexGrow: 1 }}
          rightSection={isBusy ? <Loader size="xs" /> : null}
        />
        <ActionIcon
          variant="light"
          color="blue"
          size="xl"
          onClick={searchHandler}
          disabled={!form.isValid() || isBusy}
        >
          <IconSearch />
        </ActionIcon>
      </Group>

      {user === null && (
        <Text align="center" mt={30} color="dimmed">
          Не найден пользоватлем с таким email
        </Text>
      )}

      {user && (
        <Card mt={20} padding="lg" radius="md" withBorder>
          <EmailCell row={user} size={60} />

          <Button
            fullWidth
            size="md"
            mt={40}
            onClick={() => {
              const { hasErrors } = form.validate();

              if (!hasErrors) {
                addMutation.mutate(user.id, {
                  onSuccess: () => {
                    form.reset();
                    DrawerFactory.close();
                  },
                  onError: ({ response }) => {
                    if (response.status === 422) {
                      showError({
                        title: 'Внимание',
                        message: 'В списке группы уже есть данный пользователь',
                        autoClose: 20000,
                      });
                    }
                    form.reset();
                    setUser(undefined);
                  },
                });
              }
            }}
            disabled={!user}
          >
            Добавить
          </Button>
        </Card>
      )}
    </FormProvider>
  );
};
