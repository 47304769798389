import { MobileCard, MobileCell, MobileDivider } from 'components/Table/mobile';
import { Group } from '@mantine/core';
import { RoleCell } from './cells';
import { JobNameCell } from '../ActiveList/cells';

export const MobileRender = ({ row }) => {
  return (
    <MobileCard key={row.id} row={row}>
      <MobileCell>
        <JobNameCell row={row} isMobile />
      </MobileCell>

      <MobileDivider />

      <Group position="apart" align="flex-start">
        <MobileCell name="roles">
          <RoleCell row={row} />
        </MobileCell>
        <MobileCell name="period" />
        <MobileCell name="condition" />
      </Group>

      {row.description && (
        <>
          <MobileDivider />

          <MobileCell name="description" />
        </>
      )}
    </MobileCard>
  );
};
