@keyframes opacity {
    0% {
        opacity: 1;
        transform: translate(-50%, 0px);
    }
    100% {
        opacity: 0.5;
        transform: translate(-50%, 3px);
    }
}

.cardExpendIcon {
    cursor: pointer;
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    transition: all 0.5s ease-in-out;
    animation: .5s linear 0s infinite alternate opacity;
}

.cardExpendIconOpened {
    bottom: 0;
}
