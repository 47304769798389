import { MobileCard, MobileCell, MobileDivider } from 'components/Table/mobile';
import { Group, Text, ThemeIcon } from '@mantine/core';
import { Link } from 'components/ui';
import { IconShieldCheckFilled } from '@tabler/icons-react';

export const MobileRender = ({ row }) => {
  return (
    <MobileCard key={row.id} row={row}>
      <Link to={`./${row.id || row['_id']}`} style={{ textDecoration: 'none' }}>
        <Group spacing={8} align="center" noWrap>
          <ThemeIcon variant="light" color="pink" size={28}>
            <IconShieldCheckFilled size={18} />
          </ThemeIcon>

          <Text tt="uppercase" color="blue" fw={700}>
            {row.title}
          </Text>
        </Group>

        <MobileDivider />

        <Group position="apart">
          <MobileCell name="city" />

          <MobileCell name="country" align="right" />
        </Group>

        {row.address && (
          <>
            <MobileDivider />

            {row.address && <MobileCell name="address" />}
          </>
        )}

        {row.phone && (
          <>
            <MobileDivider />

            <MobileCell name="phone" />
          </>
        )}
      </Link>
    </MobileCard>
  );
};
