import { Main } from 'pages/Main';
import { Page404 } from 'pages/404';
import { Outlet } from 'react-router-dom';
import { getRoutes, config } from 'core/navigation';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { Error } from 'pages/Error';
import { useGrantAccess } from 'hooks';

export const useProtectedRoutes = () => {
  const { can } = useGrantAccess();

  return [
    {
      path: '/',
      element: (
        <ErrorBoundary fallback={Error}>
          <Main>
            <Outlet />
          </Main>
        </ErrorBoundary>
      ),
      children: getRoutes(config, can),
    },
    { path: '*', element: <Page404 /> },
  ];
};
