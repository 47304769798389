import {
  Title,
  Box,
  Group,
  Text,
  useMantineTheme,
  Skeleton,
} from '@mantine/core';
import { getToday } from 'utils/date';
import { useMediaQuery, useUserLocation } from 'hooks';
import { useTodayGroupsQuery } from 'pages/Home/hooks/use-today-groups-query';
import { Card } from 'components/ui';
import { MobileDivider } from 'components/Table/mobile';
import { useNavigate } from 'react-router-dom';
import { isDark } from 'core/theme/utils';

const MobileRender = ({ group, isCompleted }) => {
  const theme = useMantineTheme();

  return (
    <Group style={{ opacity: isCompleted ? 0.4 : 1 }} noWrap>
      <Box style={{ flexGrow: 1 }}>
        <Text fw={700} fz={14} c={theme.colors.blue[6]}>
          {group.title}
        </Text>
        <Text fz={13} c="dimmed">
          {group.city}, {group.address}
        </Text>
      </Box>

      <Group spacing={5} noWrap>
        {/* <Box w={6} h={6} bg={indicatorColor} style={{ borderRadius: '50%' }} /> */}
        <Text
          fz={15}
          fw={700}
          c={isDark(theme) ? theme.white : theme.colors.dark[9]}
        >
          {group.schedule.time}
        </Text>
      </Group>
    </Group>
  );
};

const DesktopRender = ({ group, isCompleted }) => {
  return (
    <Group
      key={group.id}
      spacing={15}
      align="flex-start"
      noWrap
      style={{ opacity: isCompleted ? 0.4 : 1 }}
    >
      <Text fz={14} fw={700}>
        {group.schedule.time}
      </Text>
      <Box style={{ flexGrow: 1 }}>
        <Text fw={700} fz={14} c="blue">
          {group.title}
        </Text>
      </Box>
      <Text fz={13} color="dimmed">
        {group.city}, {group.address}
      </Text>
    </Group>
  );
};

export const TodayGroups = () => {
  const today = getToday();
  const { isMobile } = useMediaQuery();
  const navigate = useNavigate();

  const { country, city, isLoading } = useUserLocation();

  const { data, isFetching } = useTodayGroupsQuery({
    country,
    city,
    day: today,
    isLoading,
  });

  const renderData = !(isLoading || isFetching);

  return (
    <Box mb={40}>
      <Title mb={20}>Собрания сегодня</Title>

      {!renderData && <Skeleton height={isMobile ? 50 : 80} />}

      {renderData && (
        <Card height={500}>
          <Box p={isMobile ? 16 : 30}>
            {!data?.length && (
              <Text c="dimmed" align="center">
                Нет собраний
              </Text>
            )}
            {data?.map((group, index) => {
              const isLast = index === data.length - 1;

              const date = new Date();
              const currentHour = date.getHours();
              const groupScheduleHour = Number(
                group.schedule.time.split(':')[0],
              );
              const isCompleted = currentHour > groupScheduleHour;

              return (
                <Box
                  key={group.id}
                  mb={isLast ? 0 : 10}
                  onClick={() => {
                    navigate(`/groups/${group.id}/location`);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {isMobile && (
                    <MobileRender group={group} isCompleted={isCompleted} />
                  )}
                  {!isMobile && (
                    <DesktopRender group={group} isCompleted={isCompleted} />
                  )}

                  {!isLast && <MobileDivider />}
                </Box>
              );
            })}
          </Box>
        </Card>
      )}
    </Box>
  );
};
