import { Badge } from '@mantine/core';

export const StatusCell = ({ row }) => {
  if (!row.status) {
    return null;
  }

  const color = row.status.name === 'active' ? 'teal' : 'red';

  return <Badge color={color}>{row.status.title}</Badge>;
};
