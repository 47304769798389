import { Text, Group } from '@mantine/core';
import { RoleCell } from './cells';
import { JobWithDay } from 'components/JobWithDay';

export const columnsConfig = [
  {
    title: 'Название',
    name: 'title',
    // width: 300,
    maxWidth: 200,
    render: (row) => <JobWithDay job={row} />,
  },
  {
    title: 'Роль',
    name: 'roles',
    render: (row) => <RoleCell row={row} />,

    maxWidth: 100,
  },
  {
    title: 'Ценз',
    name: 'condition',
    render: (row) => row.condition,
    maxWidth: 50,
  },
  {
    title: 'Срок',
    name: 'period',
    headerRender: () => (
      <Group spacing={5}>
        <Text>Срок</Text>
        <Text fz={10} color="dimmed">
          (месяцы)
        </Text>
      </Group>
    ),
    render: (row) => row.period,
    maxWidth: 130,
  },
  {
    title: 'Описание',
    name: 'description',
    render: (row) => row.description,
  },
];
