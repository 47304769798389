import { Collapse, Button, Group, Box } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconPlus, IconMinus } from '@tabler/icons-react';

const ButtonHeader = ({ opened, toggle, label, gradient }) => {
  const icon = opened ? <IconMinus size={16} /> : <IconPlus size={16} />;

  return (
    <Group position="center" mb={opened ? 10 : 0}>
      <Button
        variant={gradient ? 'gradient' : 'filled'}
        gradient={{
          from: opened ? 'teal' : 'blue',
          to: opened ? 'blue' : 'teal',
          deg: 60,
        }}
        w="100%"
        size="sm"
        onClick={toggle}
        color={opened ? 'blue' : 'teal'}
        leftIcon={icon}
      >
        {label}
      </Button>
    </Group>
  );
};

export const Expandable = ({
  Header,
  content,
  label,
  buttonGradient,
  isOpened = false,
  ...rest
}) => {
  const [opened, { toggle }] = useDisclosure(isOpened);

  return (
    <Box {...rest}>
      {label && (
        <ButtonHeader
          opened={opened}
          toggle={toggle}
          label={label}
          gradient={buttonGradient}
        />
      )}
      {!label && <Header toggle={toggle} opened={opened} />}

      <Collapse in={opened}>{content}</Collapse>
    </Box>
  );
};
