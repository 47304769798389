import { useQuery } from '@tanstack/react-query';
import { getJFT } from 'pages/Home/api';

const queryOptions = {
  retry: false,
  refetchOnWindowFocus: false,
  refetchOnMount: true,
  keepPreviousData: true,
  staleTime: 3600 * 1000,
};

export const useJFTQuery = (date) => {
  return useQuery({
    queryKey: ['jft'],
    queryFn: () => getJFT(date),
    ...queryOptions,
  });
};
