import { createStyles, rem } from '@mantine/core';
import { getBorderColor, whiteBlack } from 'core/theme/utils';

export const useStyles = createStyles((theme) => ({
  container: {
    padding: 0,
    paddingBottom: rem(50),
    maxWidth: rem(700),

    [theme.fn.smallerThan('md')]: { maxWidth: '100%' },
  },
  titleGroup: { paddingBottom: rem(20) },
  title: { fontSize: rem(24) },
  titleSecond: { color: theme.colors.blue[6] },
  formWrapper: {
    border: `1px solid ${getBorderColor(theme)}`,
    padding: `${rem(30)} ${rem(40)}`,
    borderRadius: theme.radius.md,
    background: whiteBlack(theme),
    boxShadow: theme.other.shadow,

    [theme.fn.smallerThan('md')]: {
      padding: `${rem(16)} ${rem(16)}`,
      // paddingBottom: rem(32),

      borderRadius: 0,
    },
  },
  form: {
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    'gap': rem(5),

    '> div': { width: '100%' },

    'label': { fontSize: '12px !important', paddingLeft: '5px' },
  },

  field: { flexGrow: 1 },
  fieldsGroup: {
    'alignItems': 'flex-start',
    'width': '100%',
    'gap': rem(10),

    '> div': { flexGrow: 1, width: 'calc(50% - 5px)' },
  },
  rentField: {
    width: 'calc(50% - 5px) !important',
    // marginLeft: 'auto',
    alignSelf: 'flex-start',

    [theme.fn.smallerThan('xs')]: {
      // width: 'calc(50% - 5px) !important',
      width: '100% !important',
    },
  },
  submit: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',

    [theme.fn.smallerThan('xs')]: { width: '100%' },
  },
}));
