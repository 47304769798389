import { createStore, createEvent } from 'effector';
import { useStore } from 'effector-react';
import { DrawerComponent } from 'components/Drawer';

export const open = createEvent();
export const close = createEvent();
export const $drawer = createStore({});

$drawer.on(open, (_, item) => ({ ...item }));
$drawer.on(close, () => ({}));

export const DrawerFactory = { open, close };

export const DrawerProvider = () => {
  const drawer = useStore($drawer);

  const {
    header,
    onClose,
    component,
    position,
    bgColor,
    onSubmit,
    canSubmit,
    submitLabel,
    size,
    padding,
  } = drawer;

  return (
    <DrawerComponent
      bgColor={bgColor}
      position={position}
      size={size}
      opened={!!component}
      header={header}
      onClose={() => {
        if (typeof onClose === 'function') {
          onClose();
        }

        DrawerFactory.close();
      }}
      onSubmit={onSubmit}
      canSubmit={canSubmit}
      submitLabel={submitLabel}
      padding={padding}
    >
      {component}
    </DrawerComponent>
  );
};
