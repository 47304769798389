import {
  useFormContext,
  TextField,
  SelectField,
  DatePickerField,
  CheckboxField,
} from 'components/ui/form';
import {
  Button,
  Stack,
  Divider,
  Select,
  MultiSelect,
  Box,
  Group,
  Text,
  rem,
} from '@mantine/core';
import { globalRoleOptions, groupRoleOptions } from 'core/rbac';
import { useAllGroupQuery } from 'modules/group/hooks/use-group-query';
import { useSpinner } from 'core/providers/SpinnerProvider';
import { useEffect } from 'react';
import { IconPlus, IconSquareRoundedXFilled } from '@tabler/icons-react';
import { FileUpload } from 'components/ui';
import { formatDateToISOString } from 'utils/date';
import { countryOptions } from 'core/const';
import { createCityOptions } from 'utils/create-city-options';

const SectionTitle = ({ title }) => (
  <>
    <Divider mb={20} mt={30} />

    <Text fz={14} color="dimmed" mb={10}>
      {title}
    </Text>
  </>
);

const AddGroupButton = ({ form, isActive }) => {
  return (
    <>
      <Button
        disabled={!isActive}
        size="xs"
        color="teal"
        leftIcon={<IconPlus size={14} />}
        onClick={() => {
          form.insertListItem('groups', {
            groupId: '',
            groupTitle: null,
            roles: [],
          });
        }}
      >
        Добавить
      </Button>
    </>
  );
};

const DeleteGroupButton = ({ form, index }) => {
  return (
    <Box
      style={{
        cursor: 'pointer',
        position: 'absolute',
        zIndex: 1,
        top: rem(-6),
        right: rem(-6),
      }}
    >
      <IconSquareRoundedXFilled
        color="red"
        size="1.2rem"
        onClick={() => {
          form.removeListItem('groups', index);
        }}
      />
    </Box>
  );
};

export const UserForm = ({ onSubmit, isOwnProfile = false }) => {
  const form = useFormContext();
  const canSubmit = form.isValid() && form.isDirty();

  const spinner = useSpinner();
  const { data: groupList = [], isFetching } = useAllGroupQuery();

  const cityOptions = createCityOptions(form.values.country);

  useEffect(() => {
    spinner.setActive(isFetching);
  }, [isFetching]);

  const currentUserGroups = form.values.groups?.map((group) => group.groupId);

  const groupOptions = groupList?.map((group) => {
    return {
      value: group.id,
      label: group.title,
      disabled: currentUserGroups.includes(group.id),
    };
  });

  const canAdd =
    form.isValid() &&
    groupOptions.filter((option) => !option.disabled).length > 0;

  return (
    <>
      <Stack spacing={5} mb={20}>
        <Text fz={14} color="dimmed" mb={10}>
          Общее
        </Text>
        <TextField label="Имя" name="firstName" />
        <TextField label="Фамилия" name="lastName" />
        <TextField label="Email" name="email" required disabled />
        <TextField
          label="Телефон"
          name="phone"
          onChange={(e) => {
            let value = e.currentTarget.value;
            const rexExp = /^\+?[0-9]*$/;

            if (rexExp.test(value)) {
              value = value.length === 1 && value !== '+' ? `+${value}` : value;
              form.setFieldValue('phone', value);
            }
          }}
        />

        {isOwnProfile === false && (
          <SelectField label="Роль" name="role" data={globalRoleOptions} />
        )}

        <SectionTitle title="Текущее местоположение" />

        <SelectField
          name="country"
          label="Страна"
          data={countryOptions}
          required
          onChange={() => {
            form.setFieldValue('city', '');
            form.setFieldValue('useManualLocation', true);
          }}
          description="Используется для отображения актуальных новостей и событий в вашем регионе"
        />

        <SelectField
          name="city"
          label="Город"
          required
          data={cityOptions}
          description="Используется для отображения актуальных новостей и событий в вашем городе"
        />

        <CheckboxField
          name="useManualLocation"
          label="Всегда использовать в качестве основной локации (отключите, если хотите, чтобы браузер определял местоположение автоматически по вашим геоданным)"
        />

        <SectionTitle title="Другое" />

        <DatePickerField
          label="Счетчик чистого времени"
          name="cleanDate"
          valueFormat="DD/MM/YYYY"
          clearable
        />

        <FileUpload
          asAvatar
          label="Аватар"
          mt={10}
          sizeLimit={5}
          fileUrl={form.values.avatar?.fileUrl}
          onUpload={(file) => {
            form.setFieldValue('avatarImage', file);
          }}
          onReset={() => {
            form.setFieldValue('avatar.fileUrl', '');
            form.setFieldValue('avatarImage', null);
          }}
        />
      </Stack>

      {isOwnProfile === false && (
        <Box>
          <Divider mb={20} mt={30} />

          <Group position="apart" mb={20}>
            <Text fz={14} color="dimmed" mb={10}>
              Группы
            </Text>
            <AddGroupButton form={form} isActive={canAdd} />
          </Group>

          {form.values.groups?.map((group, index) => {
            return (
              <Box key={index} mb={20} style={{ position: 'relative' }}>
                <DeleteGroupButton form={form} index={index} />

                <Select
                  placeholder="Название"
                  mb={5}
                  {...form.getInputProps(`groups.${index}.groupTitle`)}
                  value={group.groupId}
                  data={groupOptions}
                  onChange={(value) => {
                    const title = groupList.find(
                      (group) => group.id === value,
                    )?.title;

                    form.setFieldValue(`groups.${index}.groupId`, value);
                    form.setFieldValue(`groups.${index}.groupTitle`, title);
                  }}
                />
                <MultiSelect
                  placeholder="Роль"
                  {...form.getInputProps(`groups.${index}.roles`)}
                  data={groupRoleOptions}
                />
              </Box>
            );
          })}
        </Box>
      )}

      <Button
        fullWidth
        size="md"
        mt={40}
        onClick={() => {
          const { hasErrors } = form.validate();

          if (!hasErrors) {
            onSubmit({
              ...form.values,
              cleanDate: formatDateToISOString(form.values.cleanDate) || '',
            });
          }
        }}
        disabled={!canSubmit}
      >
        Сохранить
      </Button>
    </>
  );
};
