export const createFullAccess = (role, resource) => {
  return [
    {
      role,
      resource,
      action: 'create',
    },
    {
      role,
      resource,
      action: 'read',
    },
    {
      role,
      resource,
      action: 'update',
    },
    {
      role,
      resource,
      action: 'delete',
    },
  ];
};
