import { useForm, FormProvider } from 'components/ui/form';
import { initialFormState } from './schema';
import { BaseForm } from './BaseForm';
import { useSpinner } from 'core/providers/SpinnerProvider';
import { useEffect } from 'react';
import {
  useAllGroupQuery,
  useGroupId,
} from 'modules/group/hooks/use-group-query';
import { useAddNews } from 'pages/Home/hooks/use-news-mutation';
import { formatDateToISOString } from 'utils/date';
import { DrawerFactory } from 'core/providers/DrawerProvider';
import { showSuccess } from 'core/notifications';

export const AddNewsForm = ({ isGroupMode }) => {
  const form = useForm(initialFormState);
  const groupId = useGroupId();

  const addMutation = useAddNews(isGroupMode && groupId);
  const spinner = useSpinner();

  const { data: groupList = [], isFetching } = useAllGroupQuery();

  useEffect(() => {
    spinner.setActive(isFetching);
  }, [isFetching]);

  const submitHandler = () => {
    let dateISO = formatDateToISOString(form.values.date);

    addMutation.mutate(
      {
        ...form.values,
        date: dateISO,
        groupTitle: groupList.find(
          (group) => group.id === Number(form.values.groupId),
        )?.title,
      },
      {
        onSuccess: () => {
          showSuccess({ title: 'Событие добавлено!' });
          form.reset();
          DrawerFactory.close();
        },
      },
    );
  };

  return (
    <FormProvider form={form}>
      <BaseForm onSubmit={submitHandler} groups={groupList} />
    </FormProvider>
  );
};
