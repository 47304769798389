import { Drawer } from '@mantine/core';
import { useStyles } from './PageLayout.styles';

export const MenuDrawer = ({ opened, onClose, content }) => {
  const { classes } = useStyles();

  return (
    <Drawer.Root opened={opened} onClose={onClose} size="80%">
      <Drawer.Overlay />

      <Drawer.Content>
        <Drawer.Header>
          <Drawer.CloseButton />
        </Drawer.Header>

        <Drawer.Body className={classes.drawer}>{content}</Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
};
