import { formatDate } from 'utils/date';
import { Text } from '@mantine/core';

export const DateTimeCell = ({
  dateValue,
  withTime = false,
  dateSeparator,
  postfix,
  prefix,
  ...rest
}) => {
  let date = formatDate({
    value: dateValue,
    withTime,
    dateSeparator,
  });

  date = prefix ? `${prefix} ${date}` : date;
  date = postfix ? `${date} ${postfix}` : date;

  return <Text {...rest}>{date}</Text>;
};
