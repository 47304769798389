import { Notifications as NotificationsMantine } from '@mantine/notifications';
import { useMantineTheme } from '@mantine/core';

export const Notifications = () => {
  const theme = useMantineTheme();

  console.log({ theme });

  const zIndex = theme.other.zIndex.notification;

  return (
    <NotificationsMantine
      limit={1}
      position="top-center"
      zIndex={zIndex}
      // transitionDuration={100}
    />
  );
};
