import {
  Button,
  Group,
  Paper,
  PasswordInput,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { object, string } from 'yup';
import { useLoginMutation } from '../hooks/use-auth-mutation';
import { Link } from 'components/ui';
import { authLinks } from '../routing/routes';
import { showSuccess, showError } from 'core/notifications';
import { useAuth } from '../use-auth';
import { BackToMain } from '../components/BackToMain';
import { getHotkeyHandler } from '@mantine/hooks';
import { ForgotPasswordLink } from '../components/ForgotPasswordLink';

const loginSchema = object({
  email: string().required().email(),
  password: string().required().min(8),
});

const initialState = {
  initialValues: { email: '', password: '' },
  validate: yupResolver(loginSchema),
  validateInputOnBlur: true,
};

export const Login = () => {
  const { mutate, isLoading, reset } = useLoginMutation();
  const { login } = useAuth();
  const form = useForm(initialState);
  const canSubmit = form.isValid() && form.isDirty();

  const submitHandler = () => {
    if (canSubmit) {
      mutate(form.values, {
        onSuccess: (data) => {
          let title = 'Добро пожаловать!';

          if (data?.user.firstName) {
            title = `Добро пожаловать, ${data?.user.firstName}!`;
          }

          login(data);

          showSuccess({ title });
        },
        onError: ({ response }) => {
          // reset loading status when no connection
          if (!response?.status) {
            reset();
          }

          if (response?.status === 422) {
            showError({
              title: 'Ошибка авторизации',
              message: response.data?.error?.message,
              autoClose: 15000,
            });
          }
          if (response?.status === 401) {
            showError({
              title: 'Необходимо завершить регистрацию',
              message:
                'Вам было отправлено письмо для подтверждения регистрации. Пожалуйста проверьте вашу почту.',
              autoClose: 30000,
            });
          }
        },
      });
    }
  };

  return (
    <div>
      <Title align="center" fw={900}>
        Добро пожаловать!
      </Title>
      <Text color="dimmed" size="sm" align="center" mt={5}>
        Вы еще не с нами?{' '}
        <Link to={authLinks.signup} size="sm">
          Регистрация
        </Link>
      </Text>

      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <TextInput
          label="Email"
          placeholder="you@mantine.dev"
          {...form.getInputProps('email')}
          onKeyDown={getHotkeyHandler([['Enter', submitHandler]])}
        />
        <PasswordInput
          label="Пароль"
          placeholder="********"
          mt="md"
          {...form.getInputProps('password')}
          onKeyDown={getHotkeyHandler([['Enter', submitHandler]])}
        />
        <Group position="center" mt="lg" noWrap>
          <ForgotPasswordLink />
        </Group>
        <Button
          fullWidth
          mt="xl"
          disabled={!canSubmit}
          loading={isLoading}
          onClick={submitHandler}
        >
          Войти
        </Button>

        <BackToMain />
      </Paper>
    </div>
  );
};
