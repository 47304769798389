import { GLOBAL_ROLE, GROUP_ROLE } from '../roles';
import { createFullAccess } from '../utils';

export const groupPermissions = [
  ...createFullAccess(GLOBAL_ROLE.admin, 'group'),
  ...createFullAccess(GLOBAL_ROLE.admin, 'schedule'),

  {
    role: GLOBAL_ROLE.user,
    resource: 'group',
    action: 'read',
  },
  {
    role: GLOBAL_ROLE.guest,
    resource: 'group',
    action: 'read',
  },
  {
    role: GLOBAL_ROLE.newsMaker,
    resource: 'group',
    action: 'read',
  },

  {
    role: GROUP_ROLE.group_admin,
    resource: 'group',
    action: 'read',
  },
  {
    role: GROUP_ROLE.group_admin,
    resource: 'group',
    action: 'update',
  },
  {
    role: GROUP_ROLE.group_admin,
    resource: 'schedule',
    action: 'update',
  },

  {
    role: GROUP_ROLE.group_member,
    resource: 'group',
    action: 'read',
  },
  {
    role: GROUP_ROLE.group_leader,
    resource: 'group',
    action: 'read',
  },
  {
    role: GROUP_ROLE.group_paymaster,
    resource: 'group',
    action: 'read',
  },
  {
    role: GROUP_ROLE.group_storekeeper,
    resource: 'group',
    action: 'read',
  },
];
