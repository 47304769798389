import { CheckboxField, SelectField } from 'components/ui/form';
import { storageItemOptions } from '../../const';
import { useGroup } from 'modules/group/context';

export const FilterForm = () => {
  const { storeEndingLimit } = useGroup();
  const endingLabel = `Заканчиваются (менее ${storeEndingLimit || 3} штук)`;

  return (
    <>
      <SelectField
        label="Категория"
        clearable
        name="type"
        data={storageItemOptions}
      />

      <CheckboxField name="ended" label="Нет в наличии" />
      <CheckboxField name="ending" label={endingLabel} />
    </>
  );
};
