import { useStyles } from './FileUpload.styles';
import { useState } from 'react';
import { Avatar, Button, FileButton, Text, Box, Image } from '@mantine/core';
import { showError } from 'core/notifications';

export const FileUpload = ({
  asAvatar = false,
  imageRender,
  label,
  onUpload,
  onReset,
  fileUrl,
  sizeLimit = 10,
  mimeType = 'image/png,image/jpeg,image/jpg',
  ...rest
}) => {
  const { classes } = useStyles();
  const [image, setImage] = useState(fileUrl);

  return (
    <Box {...rest}>
      {label && (
        <Text fz={12} pl={5} pb={3}>
          {label}
        </Text>
      )}
      <div className={classes.uploader}>
        {imageRender && imageRender(image)}

        {!imageRender && asAvatar && (
          <Avatar radius="50%" size={100} src={image} />
        )}

        {!imageRender && !asAvatar && (
          <Image
            radius="sm"
            src={image}
            alt="image"
            fit="cover"
            withPlaceholder
            imageProps={{ style: { minHeight: 100 } }}
            mb={10}
          />
        )}

        {!image && (
          <FileButton
            onChange={(file) => {
              if (file.size / 1000000 > sizeLimit) {
                showError({
                  title: 'Слишком большой размер файла',
                  message: `Максимальный размер файла ${sizeLimit} МБ`,
                });
              } else {
                setImage(URL.createObjectURL(file));

                if (typeof onReset === 'function') {
                  onUpload(file);
                }
              }
            }}
            accept={mimeType}
          >
            {(props) => (
              <>
                <Button {...props} size="xs" color="teal">
                  Загрузить
                </Button>
                <Text color="dimmed" fz={12}>
                  Не более {sizeLimit} Мб
                </Text>
              </>
            )}
          </FileButton>
        )}

        {image && (
          <Button
            size="xs"
            color="red"
            onClick={() => {
              URL.revokeObjectURL(image);
              setImage(null);

              if (typeof onReset === 'function') {
                onReset();
              }
            }}
          >
            Удалить
          </Button>
        )}
      </div>
    </Box>
  );
};
