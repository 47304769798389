import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  addGroup,
  deleteGroup,
  updateGroup,
  updateGroupSchedule,
} from '../api';
import { useSpinner } from 'core/providers/SpinnerProvider';
import { useGroupId } from './use-group-query';

const mutationOptions = ({ queryClient, spinner, groupId }) => ({
  onMutate: () => {
    spinner.setActive(true);
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['groups'] });
    queryClient.invalidateQueries({ queryKey: ['group', groupId] });
  },

  onSettled: () => {
    spinner.setActive(false);
  },
});

const useOptions = () => {
  const queryClient = useQueryClient();
  const spinner = useSpinner();
  const groupId = useGroupId();

  return {
    queryClient,
    spinner,
    groupId,
  };
};

export const useAddGroup = () => {
  const options = useOptions();

  return useMutation({ mutationFn: addGroup, ...mutationOptions(options) });
};

export const useUpdateGroup = () => {
  const options = useOptions();

  return useMutation({ mutationFn: updateGroup, ...mutationOptions(options) });
};

export const useDeleteGroup = () => {
  const options = useOptions();

  return useMutation({ mutationFn: deleteGroup, ...mutationOptions(options) });
};

export const useUpdateGroupSchedule = () => {
  const options = useOptions();

  return useMutation({
    mutationFn: (schedule) =>
      updateGroupSchedule({ groupId: options.groupId, schedule }),
    ...mutationOptions(options),
  });
};
