import { useQuery } from '@tanstack/react-query';
import { getGroupsByDay } from 'pages/Home/api';

const queryOptions = {
  retry: false,
  refetchOnWindowFocus: false,
  refetchOnMount: true,
  keepPreviousData: true,
  staleTime: 5000,
};

export const useTodayGroupsQuery = ({ country, city, day, isLoading }) => {
  return useQuery({
    queryKey: ['today-groups', country, city, day],
    queryFn: () => getGroupsByDay({ country, city, day }),
    ...queryOptions,
    enabled: !isLoading,
  });
};
