import { GLOBAL_ROLE, GROUP_ROLE } from '../roles';
import { createFullAccess } from '../utils';

export const memberPermissions = [
  ...createFullAccess(GLOBAL_ROLE.admin, 'member'),
  ...createFullAccess(GROUP_ROLE.group_admin, 'member'),

  {
    role: GROUP_ROLE.group_member,
    resource: 'member',
    action: 'read',
  },
  {
    role: GROUP_ROLE.group_leader,
    resource: 'member',
    action: 'read',
  },
  {
    role: GROUP_ROLE.group_paymaster,
    resource: 'member',
    action: 'read',
  },
  {
    role: GROUP_ROLE.group_storekeeper,
    resource: 'member',
    action: 'read',
  },
];
