import { Text, Box, Group } from '@mantine/core';
import { formatNumber } from 'utils/string';
import { useGroup } from 'modules/group/context';

export const BooksCell = ({ data, accessor, inline = false }) => {
  const group = useGroup();

  if (!Array.isArray(data)) {
    return null;
  }

  const items = data.map((item, index) => {
    if (inline && !accessor) {
      return (
        <Group key={index} mb={5} spacing={5}>
          <Text>{item.title}:</Text>
          <Text color="dimmed">{item.qty}шт.</Text>
        </Group>
      );
    }

    let amount = formatNumber(item[accessor] * item.qty);

    amount = Number.isNaN(amount) ? 'Не задано' : amount;

    return (
      <Box key={index} mb={5}>
        <Text>{item.title}</Text>
        <Text color="dimmed">
          {item.qty}шт. - {amount} {group.currency || ''}
        </Text>
      </Box>
    );
  });

  return <Box>{items}</Box>;
};
