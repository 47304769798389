import { Title, Group } from '@mantine/core';
import {
  useGrantAccess,
  useMediaQuery,
  useSearch,
  getQueryString,
} from 'hooks';
import { useNavigate } from 'react-router-dom';
import { TableRow, TableHeader, TableBody, NoResults } from './index';
import { useStyles } from './Table.styles';
import { TableContext } from './context';
import { AddButton } from 'components/ui';
import { Fragment } from 'react';
import { Filter } from 'components/Filter';
import { Pagination } from './Pagination';
import { SearchInput } from 'components/ui/form';

export const TableRender = ({
  id,
  title,
  columns,
  onAdd,
  onDelete,
  onEdit,
  onComplete,
  onView = true,
  bodyClasses,
  titleRender,
  disableActions,
  expandableRowRender,
  noPadding = false,
  mobileRender,
  data = [],
  pagination = {},
  // needed to validate user access
  resource,
  useGroupIdFromRow = false,
  filter = {},
  placeFilterInHeader = false,
}) => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { isSmallDevice, isMobile } = useMediaQuery();
  const { canGroup } = useGrantAccess();
  const searchParams = useSearch();

  const canCreate = canGroup('create', resource);
  const shouldRenderAddButton = canCreate && typeof onAdd === 'function';

  const setFilter = (filter) => {
    const filterParams =
      typeof filter === 'function' ? filter(searchParams) : filter;

    const queryParams = { ...searchParams, ...filterParams };
    const queryParamsString = getQueryString(queryParams);

    navigate({ search: queryParamsString });
  };

  const isMobileRender = isSmallDevice && mobileRender;

  const tableContext = {
    resource,
    columns,
    actions: {
      onDelete,
      onEdit,
      onComplete,
      onView,
    },
    disableActions,
    expandableRowRender,
    useGroupIdFromRow,
  };

  const showFilter =
    !!filter.Form && !(!Object.keys(searchParams).length && !data.length);

  const FilterRender = (props) => (
    <Filter
      initialState={filter.initialState}
      labels={filter.labels}
      Form={filter.Form}
      filter={searchParams}
      setFilter={setFilter}
      total={pagination?.total}
      {...props}
    />
  );

  return (
    <TableContext.Provider value={tableContext}>
      <div>
        {typeof titleRender === 'function' && titleRender(data, pagination)}

        {title && !titleRender && (
          <div className={classes.infoWrapper}>
            <div className={classes.info}>
              <Title size="xxl" pr={10}>
                {title}
              </Title>

              <Group w="100%">
                <SearchInput
                  w={
                    shouldRenderAddButton
                      ? isMobile
                        ? '60%'
                        : 'auto'
                      : isMobile
                      ? '100%'
                      : 'auto'
                  }
                  ml={shouldRenderAddButton || isMobile ? 0 : 'auto'}
                />

                {shouldRenderAddButton && <AddButton onClick={onAdd} />}
              </Group>
            </div>

            {placeFilterInHeader && showFilter && (
              <FilterRender mb={0} mt={15} />
            )}
          </div>
        )}

        {showFilter && !placeFilterInHeader && <FilterRender />}

        {!data?.length && <NoResults />}

        {Boolean(data?.length) && (
          <div>
            {isSmallDevice && <Pagination {...pagination} />}

            <TableBody className={bodyClasses} noPadding={noPadding}>
              {!isMobileRender && <TableHeader columns={columns} />}

              {data.map((row) =>
                isMobileRender ? (
                  <Fragment key={row.id || row['_id']}>
                    {mobileRender(row)}
                  </Fragment>
                ) : (
                  <TableRow id={id} key={row.id || row['_id']} row={row} />
                ),
              )}
            </TableBody>

            <Pagination {...pagination} />
          </div>
        )}
      </div>
    </TableContext.Provider>
  );
};
