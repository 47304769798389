import { DateTimeCell } from 'components/Table/cells';
import { MemberCell, JobNameCell } from './cells';

export const columnsConfig = [
  {
    title: 'Служение',
    name: 'title',
    maxWidth: 300,
    render: (row) => <JobNameCell row={row} />,
  },
  {
    title: 'Начало',
    name: 'startDate',
    maxWidth: 120,
    render: (row) => <DateTimeCell dateValue={row.userInfo?.startDate} />,
  },
  {
    title: 'Конец',
    name: 'endDate',
    maxWidth: 120,
    render: (row) => <DateTimeCell dateValue={row.userInfo?.endDate} />,
  },
  {
    title: 'Служащий',
    name: 'member',
    maxWidth: 300,
    width: 250,
    render: (row) => <MemberCell userInfo={row.userInfo} />,
  },
  {
    title: 'Комментарий',
    name: 'comment',
    render: (row) => row.userInfo?.comment,
  },
];
