import { Box } from '@mantine/core';
import { useRef, useState, useEffect } from 'react';
import * as css from './Card.module.css';
import { IconChevronsDown, IconChevronsUp } from '@tabler/icons-react';
import { useStyles } from './Card.styles';

export const Card = ({ children, height, width, ...rest }) => {
  const { classes, cx } = useStyles({ width });
  const contentRef = useRef();
  const [expanded, setExpanded] = useState(false);
  const [overSized, setOverSized] = useState(false);

  useEffect(() => {
    if (contentRef.current && height) {
      const contentHeight = contentRef.current.clientHeight;

      if (contentHeight > parseInt(height)) {
        setOverSized(true);
      }
    }
  }, [contentRef.current?.clientHeight]);

  return (
    <Box
      className={cx(
        classes.card,
        overSized && !expanded && classes.cardOverlay,
      )}
      style={{ maxHeight: expanded ? '100%' : height }}
      {...rest}
    >
      <div ref={contentRef} className="card-content">
        {children}
      </div>

      {overSized && (
        <div
          className={cx(
            css.cardExpendIcon,
            expanded && css.cardExpendIconOpened,
          )}
          onClick={() => {
            setExpanded((state) => !state);
          }}
        >
          {!expanded && <IconChevronsDown size={24} color="#228be6" />}
          {expanded && <IconChevronsUp size={24} color="#228be6" />}
        </div>
      )}
    </Box>
  );
};
