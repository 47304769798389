import { BaseForm } from './BaseForm';
import { useAddJob } from 'modules/group/hooks/use-job-mutation';
import { initialFormState } from './schema';
import { useForm, FormProvider } from 'components/ui/form';
import { DrawerFactory } from 'core/providers/DrawerProvider';

export const AddForm = () => {
  const form = useForm(initialFormState);

  const addMutation = useAddJob();

  const submitHandler = (values) => {
    addMutation.mutate(values, {
      onSuccess: () => {
        form.reset();
        DrawerFactory.close();
      },
    });
  };

  return (
    <FormProvider form={form}>
      <BaseForm onSubmit={submitHandler} />
    </FormProvider>
  );
};
