import { showSuccess } from 'core/notifications';
import { useAddMemberRequest } from 'modules/group/hooks/use-request-mutation';
import { useGroupRequestsQuery } from 'modules/group/hooks/use-group-query';
import { Button, Badge, Text } from '@mantine/core';

export const MemberRequest = ({ user }) => {
  const addRequestMutation = useAddMemberRequest();

  const {
    data: groupRequests,
    isLoading,
    isFetching,
  } = useGroupRequestsQuery();

  if (isLoading || isFetching) {
    return null;
  }

  if (groupRequests?.length) {
    const request = groupRequests.find((r) => +r.userId === +user?.id);

    if (request) {
      return (
        <Badge ml="auto" color="orange" size="lg">
          <Text fz={10}>Заявка отправлена</Text>
        </Badge>
      );
    }
  }

  return (
    <Button
      size="xs"
      color="teal"
      ml="auto"
      display="block"
      onClick={() => {
        addRequestMutation.mutate(user?.id, {
          onSuccess: () => {
            showSuccess({
              title: 'Заявка отправлена',
              message:
                'Ждите подтверждения по вашему запросу. Письмо придет на ваш почтовый ящик.',
            });
          },
        });
      }}
    >
      Вступить
    </Button>
  );
};
