import { MobileCard, MobileCell, MobileDivider } from 'components/Table/mobile';
import { Group } from '@mantine/core';
import { CurrencyCell } from 'components/Table/cells';
import { TitleCell, QuantityCell } from './cells';

export const MobileRender = ({ row }) => {
  return (
    <MobileCard key={row.id} row={row}>
      <TitleCell row={row} isMobile />

      <MobileDivider />

      <Group position="apart" align="flex-start">
        <MobileCell name="qty">
          <QuantityCell row={row} color="dimmed" />
        </MobileCell>

        <MobileCell name="priceIn">
          <CurrencyCell value={row.priceIn} color="dimmed" />
        </MobileCell>
        <MobileCell name="priceOut">
          <CurrencyCell value={row.priceOut} color="dimmed" />
        </MobileCell>
      </Group>

      {row.description && (
        <>
          <MobileDivider />

          <MobileCell name="description" />
        </>
      )}
    </MobileCard>
  );
};
