import { Card, CardTitle, Parameter } from '../components';
import { FINANCE_SOURCE_TYPE } from 'modules/group/const';
import { IconZoomMoney } from '@tabler/icons-react';

export const Finance = ({ data }) => {
  const { expense, income } = data;

  return (
    <Card title="Финансы" Icon={IconZoomMoney}>
      <CardTitle value={income.total} label="приход" />
      {income.items.map((item) => {
        const countColumnLabel =
          item.category === 'literature' ? 'собрания' : 'операции';

        return (
          <Parameter
            key={item.category}
            {...item}
            name={FINANCE_SOURCE_TYPE[item.category].label}
            countColumnLabel={countColumnLabel}
          />
        );
      })}

      <CardTitle value={expense.total} label="расход" />
      {expense.items.map((item) => {
        const countColumnLabel =
          item.category === 'literature' ? 'заказы' : 'операции';

        return (
          <Parameter
            key={item.category}
            {...item}
            name={FINANCE_SOURCE_TYPE[item.category].label}
            countColumnLabel={countColumnLabel}
          />
        );
      })}
    </Card>
  );
};
