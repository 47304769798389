import { Anchor } from '@mantine/core';
import { getGeoLocationLink } from 'utils/geo-location';

// поставить метку
// https://yandex.ru/maps/?pt=69.29596,41.305576&z=18&l=map

export const MapLink = ({ geoLocation, title = 'На карте' }) => {
  const link = getGeoLocationLink(geoLocation);

  return (
    <Anchor href={link} target="_blank">
      {title}
    </Anchor>
  );
};
