import { Login, SignUp, Confirmation, PasswordReset } from 'modules/auth/pages';
import { AuthLayout } from 'components/layouts/AuthLayout';
import { Outlet, Navigate } from 'react-router-dom';

export const AUTH_BASE_PATH = '/auth';

export const AUTH_ROUTES_NAMES = {
  login: 'login',
  resetPassword: 'reset-password',
  signup: 'signup',
  confirmRegistration: 'confirm-registration',
};

export const authLinks = (() => {
  return Object.values(AUTH_ROUTES_NAMES).reduce((acc, name) => {
    acc[name] = AUTH_BASE_PATH + '/' + name;

    return acc;
  }, {});
})();

export const authRoutes = [
  {
    path: AUTH_BASE_PATH,
    element: (
      <AuthLayout>
        <Outlet />
      </AuthLayout>
    ),
    children: [
      { index: true, element: <Navigate to={AUTH_ROUTES_NAMES.login} /> },
      { path: AUTH_ROUTES_NAMES.login, element: <Login /> },
      { path: AUTH_ROUTES_NAMES.signup, element: <SignUp /> },
      {
        path: AUTH_ROUTES_NAMES.confirmRegistration,
        element: <Confirmation />,
      },
      { path: AUTH_ROUTES_NAMES.resetPassword, element: <PasswordReset /> },
    ],
  },
];
