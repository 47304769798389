import { Filter as FilterComponent } from 'components/Filter';
import { WEEK_DAYS } from 'core/const';
import { FilterForm } from './FilterForm';

export const filterInitialState = {
  day: '',
  job: '',
  available: '',
  expired: '',
  ending: '',
};

const filterLabels = {
  day: (filterValue) => WEEK_DAYS[filterValue],
  job: (filterValue) => filterValue,
  available: 'Свободные',
  expired: 'Закончились',
  ending: 'Заканчиваются',
};

export const Filter = ({ filter, setFilter, ...rest }) => {
  return (
    <FilterComponent
      initialState={filterInitialState}
      labels={filterLabels}
      Form={FilterForm}
      filter={filter}
      setFilter={setFilter}
      {...rest}
    />
  );
};
