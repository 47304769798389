import { NavLink } from '@mantine/core';
import { useNavigate, useMatch } from 'react-router-dom';
import { getNavItems } from './utils';
import { config } from './config';
import { useNavigationMenu } from 'components/layouts/PageLayout/state';
import { useGrantAccess } from '../../hooks';

const baseNavItems = getNavItems(config);

const MenuItem = ({ title, Icon, path, children, onlyNavigation }) => {
  const navigate = useNavigate();
  const match = path && useMatch(path);
  const { api } = useNavigationMenu();

  return (
    <NavLink
      label={title}
      icon={Icon && <Icon size="1rem" stroke={1.5} />}
      onClick={() => {
        if (path && !onlyNavigation) {
          navigate(path);
          api.close();
        }
      }}
      active={!!match}
      childrenOffset={children ? 35 : 0}
    >
      {children}
    </NavLink>
  );
};

export const SidebarNavigation = ({ navItems }) => {
  const { can } = useGrantAccess();

  const items = navItems || baseNavItems;

  const allowedItems = items.filter(({ resource }) => {
    if (!resource) {
      return true;
    }

    return can('read', resource);
  });

  return allowedItems.map(({ icon, subMenu, ...props }) => {
    if (subMenu?.length) {
      return (
        <MenuItem key={props.title} Icon={icon} {...props}>
          {subMenu?.map(({ icon, path, title, ...rest }) => {
            const subItemPath = `${props.path}/${path}`;

            return (
              <MenuItem
                key={title}
                title={title}
                path={subItemPath}
                Icon={icon}
                {...rest}
              />
            );
          })}
        </MenuItem>
      );
    }

    return <MenuItem key={props.title} Icon={icon} {...props} />;
  });
};
