import { SelectField, useFormContext } from 'components/ui/form';
import { countryOptions } from 'core/const';
import { createCityOptions } from 'utils/create-city-options';
import { weekDaysOptions } from 'modules/group/pages/Group/sections/Schedule/form/const';

export const FilterForm = () => {
  const form = useFormContext();

  const cityOptions = createCityOptions(form.values.country);

  return (
    <>
      <SelectField
        clearable
        label="Страна"
        name="country"
        data={countryOptions}
        onChange={() => {
          form.setFieldValue('city', '');
        }}
      />

      <SelectField clearable label="Город" name="city" data={cityOptions} />

      <SelectField name="day" label="День недели" data={weekDaysOptions} />
    </>
  );
};
