import { ModalsProvider } from '@mantine/modals';
import { useMantineTheme } from '@mantine/core';

export const ModalProvider = ({ children }) => {
  const theme = useMantineTheme();

  return (
    <ModalsProvider
      modalProps={{
        zIndex: theme.other.zIndex.modal,
        padding: 'lg',
        radius: 'md',
        overlayProps: { opacity: 0.5 },
        centered: true,
      }}
    >
      {children}
    </ModalsProvider>
  );
};
