import { Container, Box } from '@mantine/core';
import { useAuth } from 'modules/auth/use-auth';
import { Navigate } from 'react-router-dom';
import { greyDark } from 'core/theme/utils';
import bgAuth from 'assets/bg-auth.png';

export const AuthLayout = ({ children }) => {
  const { isLogged } = useAuth();

  if (isLogged) {
    return <Navigate to="/" />;
  }

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: greyDark(theme),
        width: '100%',
        height: window.innerHeight || '100vh',
        backgroundImage: `url(${bgAuth})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        overflow: 'auto',
      })}
    >
      <Container size={420} pt={40} pb={100}>
        {children}
      </Container>
    </Box>
  );
};
