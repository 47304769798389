import { Table } from 'components/Table';
import { columnsConfig } from './columns-config';
import { useDeleteMeeting } from 'modules/group/hooks/use-meeting-mutation';
import { openConfirmModal } from 'components/ui/Modal';
import { showSuccess } from 'core/notifications';
import { DrawerFactory } from 'core/providers/DrawerProvider';
import { EditForm } from '../MeetingForm/EditForm';
import { Box } from '@mantine/core';
import { useGroupId } from 'modules/group/hooks/use-group-query';
import { useMediaQuery } from 'hooks';
import { MobileRender } from './MobileRender';
import { ExpandableRowRender } from './ExpandableRowRender';
import { DateFilterForm, formatDateLabel } from 'components/Filter';

export const Summary = () => {
  const deleteMeetingMutation = useDeleteMeeting();
  const groupId = useGroupId();
  const { isSmallDevice } = useMediaQuery();

  const editHandler = (row) => {
    DrawerFactory.open({
      header: 'Редактирование отчета',
      component: <EditForm meeting={row} />,
      size: 'xl',
    });
  };

  const deleteHandler = (id) => {
    openConfirmModal({
      title: 'Удалить отчет за эту дату?',
      children: 'Проданная литература и 7-ая традиция будут отменены',
      onConfirm: () => {
        deleteMeetingMutation.mutate(id, {
          onSuccess: () => {
            showSuccess({ title: 'Отчет удален' });
          },
        });
      },
    });
  };

  return (
    <Box pl={isSmallDevice ? 16 : 40} pr={isSmallDevice ? 16 : 40} pb={50}>
      <Table
        noPadding
        resource="meeting"
        resourceEndpoint={`/meeting/${groupId}`}
        tableKey={`summary-${groupId}`}
        columns={columnsConfig}
        onView={false}
        onEdit={editHandler}
        onDelete={({ id }) => deleteHandler(id)}
        disableActions={(row) =>
          row.status === 'inactive' ? ['edit', 'delete'] : []
        }
        mobileRender={(row) => <MobileRender row={row} />}
        expandableRowRender={(row) => <ExpandableRowRender row={row} />}
        filter={{
          initialState: { dateFrom: '', dateTo: '' },
          labels: {
            dateFrom: formatDateLabel('с: '),
            dateTo: formatDateLabel('до: '),
          },
          Form: DateFilterForm,
        }}
      />
    </Box>
  );
};
