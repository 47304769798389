import { DateTimeCell } from 'components/Table/cells';
import { Box, Group } from '@mantine/core';
import { useGroup } from 'modules/group/context';
import { JobWithDay } from 'components/JobWithDay';
import { MobileDivider } from 'components/Table/mobile';

export const JobsCell = ({ row, withDivider = false, ...rest }) => {
  const { jobs = [] } = useGroup();

  const userJobs = jobs.filter(
    (job) => job.userInfo?.email?.toLowerCase() === row.email?.toLowerCase(),
  );

  if (!userJobs?.length) {
    return null;
  }

  return (
    <Box {...rest}>
      {withDivider && <MobileDivider />}

      {userJobs.map((userJob, index) => {
        return (
          <Box key={index} mb={10}>
            <JobWithDay job={userJob} inline color="blue.6" />

            <Group noWrap spacing={0}>
              <DateTimeCell
                color="dimmed"
                dateValue={userJob.userInfo.startDate}
                postfix="-&nbsp;"
              />
              <DateTimeCell
                color="dimmed"
                dateValue={userJob.userInfo.endDate}
              />
            </Group>
          </Box>
        );
      })}
    </Box>
  );
};
