import { object, string, array } from 'yup';
import { yupResolver } from '@mantine/form';
import { messages } from 'utils/messages';

export const scheduleSchema = object({
  schedule: array().of(
    object({
      day: string().required(messages.required),
      time: string().required(messages.required),
      subject: string(),
      description: string(),
    }),
  ),
});

export const initialFormState = {
  initialValues: { schedule: [] },
  validate: yupResolver(scheduleSchema),
  validateInputOnChange: true,
};
