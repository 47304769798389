import { SectionContainer } from '../SectionContainer';
import { useGroupReportQuery } from 'modules/group/hooks/use-group-query';
import { useEffect } from 'react';
import { Flex, Text } from '@mantine/core';
import { useMediaQuery, useSearch } from 'hooks';
import { Meetings, Finance, Literature } from './sections';
import { Filter } from './components';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'components/ui/Spinner';
import { formatDateToISOString, addZero } from 'utils/date';

const setSearchQuery = (defaultPeriod) => {
  const date = new Date();

  if (defaultPeriod === 'lastDay') {
    return `?dateFrom=${formatDateToISOString(date)}`;
  }

  const month = addZero(date.getMonth() + 1);

  return `?dateFrom=${date.getFullYear()}-${month}-01`;
};

export const Reports = ({ group }) => {
  const { isMobile } = useMediaQuery();
  const navigate = useNavigate();
  const { dateFrom, dateTo } = useSearch();
  const shouldRenderReports = dateFrom || dateTo;

  const { data, isFetching } = useGroupReportQuery({ dateFrom, dateTo });

  // set default period for the first page load (if no query params)
  useEffect(() => {
    if (!shouldRenderReports) {
      navigate({ search: setSearchQuery(group?.reportDefaultPeriod) });
    }
  }, []);

  if (isFetching && shouldRenderReports) {
    return <Spinner fullscreen />;
  }

  const { meetings, finance, literature } = data || {};

  return (
    <SectionContainer>
      <Filter />

      {!shouldRenderReports && (
        <Text color="dimmed" align="center">
          Укажите период для создания отчета
        </Text>
      )}

      {shouldRenderReports && data && (
        <Flex
          align="flex-start"
          direction={isMobile ? 'column' : 'row'}
          gap={20}
          wrap="wrap"
        >
          <Meetings data={meetings} />
          <Finance data={finance} />
          <Literature data={literature} />
        </Flex>
      )}
    </SectionContainer>
  );
};
