import { createStyles, rem } from '@mantine/core';
import { getBorderColor, whiteDark } from 'core/theme/utils';
import bgMain from 'assets/bg-main.png';

export const useStyles = createStyles((theme) => {
  const asideWidth = theme.other.aside.width;
  const headerHeight = theme.other.header.height;

  const borderColor = getBorderColor(theme);

  return {
    background: {
      'position': 'relative',

      '::after': {
        content: '""',
        position: 'fixed',
        minHeight: '100vh',
        top: 0,
        left: 0,
        right: 0,
        zIndex: -1,
        backgroundImage: `url(${bgMain})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        opacity: 0.1,
        transform: 'scale(1.2)',
        transition: 'all 300ms ease',
      },
    },
    aside: {
      width: rem(asideWidth),
      position: 'fixed',
      top: rem(headerHeight),
      bottom: 0,
      borderRight: `1px solid ${borderColor}`,
      backgroundColor: whiteDark(theme),

      [theme.fn.smallerThan('md')]: { display: 'none' },
    },
    main: {
      position: 'relative',
      marginLeft: rem(asideWidth),
      marginTop: rem(headerHeight),
      height: 'fit-content',
      // height: `calc(100vh - ${rem(headerHeight)})`,

      [theme.fn.smallerThan('md')]: { marginLeft: 0 },
    },
    header: {
      position: 'fixed',
      height: rem(headerHeight),
      width: '100%',
      zIndex: theme.other.zIndex.header,
      backgroundColor: whiteDark(theme),
      padding: `0 ${rem(20)}`,

      [theme.fn.smallerThan('md')]: {
        padding: `0 ${rem(16)}`,
      },
    },
    burger: { [theme.fn.largerThan('md')]: { display: 'none' } },
    logo: {
      [theme.fn.smallerThan('md')]: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
    },
    drawer: { '& span': { fontSize: '16px' } },
  };
});
