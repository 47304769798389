import { DatePickerField, SelectField } from 'components/ui/form';
import { Group } from '@mantine/core';
import { ORDER_TYPE, STATUS_LABELS } from '../../const';

const orderTypeOptions = Object.values(ORDER_TYPE).map((item) => ({
  value: item.name,
  label: item.label,
}));

const statusOptions = Object.keys(STATUS_LABELS).map((key) => ({
  value: key,
  label: STATUS_LABELS[key],
}));

export const FilterForm = () => {
  return (
    <>
      <Group spacing={5} position="apart">
        <DatePickerField
          clearable
          name="dateFrom"
          label="Дата с:"
          valueFormat="DD/MM/YYYY"
          style={{ flexBasis: '49%' }}
        />

        <DatePickerField
          clearable
          name="dateTo"
          label="Дата до:"
          valueFormat="DD/MM/YYYY"
          style={{ flexBasis: '49%' }}
        />
      </Group>

      <SelectField
        clearable
        name="orderType"
        label="Тип операции"
        data={orderTypeOptions}
      />

      <SelectField
        clearable
        label="Статус"
        name="status"
        data={statusOptions}
      />
    </>
  );
};
