import { Group, Button, Burger, Box, Text } from '@mantine/core';
import { useStyles } from './Header.styles';
import { ThemeSwitcher } from 'components/ThemeSwitcher';
import { useAuth } from 'modules/auth/use-auth';
import { useNavigate } from 'react-router-dom';
import { AUTH_BASE_PATH } from 'modules/auth/routing/routes';
import { UserInfo } from './UserInfo';
import { Logo } from 'components/Logo';
import { useNavigationMenu } from 'components/layouts/PageLayout/state';
import { useUserLocation } from 'hooks';

export const Header = () => {
  const { classes } = useStyles();
  const { isLogged, logout, user } = useAuth();
  const navigate = useNavigate();
  const { state, api } = useNavigationMenu();
  const { country, city } = useUserLocation();

  const logoutHandler = () => {
    logout();
    setTimeout(() => {
      navigate('/');
    }, 200);
  };

  return (
    <Box className={classes.wrapper}>
      <Burger
        opened={state.isOpened}
        onClick={() => api.toggle()}
        size="sm"
        className={classes.burger}
      />

      <div
        className={classes.logo}
        onClick={() => {
          navigate('/');
        }}
      >
        <Logo />

        {(country || city) && (
          <Text color="dimmed" size="xs">
            {country}, {city}
          </Text>
        )}
      </div>

      <Group className={classes.wrapper} noWrap>
        {!isLogged && (
          <>
            <ThemeSwitcher size="md" className={classes.hiddenMobile} />
            <Button
              size="xs"
              onClick={() => {
                navigate(AUTH_BASE_PATH);
              }}
            >
              Войти
            </Button>
          </>
        )}
        {isLogged && <UserInfo user={user} logout={logoutHandler} />}
      </Group>
    </Box>
  );
};
