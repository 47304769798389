import { useMutation } from '@tanstack/react-query';
import {
  uploadGroupFile,
  removeGroupFile,
  downloadGroupFile,
  downloadGroupCommonFile,
} from '../api';
import { useOptions } from './use-query-options';

const mutationOptions = ({ queryClient, spinner }) => ({
  onMutate: () => {
    spinner.setActive(true);
  },
  onSettled: () => {
    spinner.setActive(false);
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['docs'] });
  },
});

export const useUploadFile = () => {
  const options = useOptions();

  return useMutation({
    mutationFn: (data) => uploadGroupFile({ groupId: options.groupId, data }),
    ...mutationOptions(options),
  });
};

export const useRemoveFile = () => {
  const options = useOptions();

  return useMutation({
    mutationFn: (fileName) =>
      removeGroupFile({ groupId: options.groupId, fileName }),
    ...mutationOptions(options),
  });
};

export const useDownloadFile = () => {
  const { spinner, groupId } = useOptions();

  return useMutation({
    mutationFn: (fileName) => downloadGroupFile({ groupId, fileName }),
    onMutate: () => {
      spinner.setActive(true);
    },
    onSettled: () => {
      spinner.setActive(false);
    },
  });
};

export const useDownloadCommonFile = () => {
  const { spinner, groupId } = useOptions();

  return useMutation({
    mutationFn: (fileName) => downloadGroupCommonFile({ groupId, fileName }),
    onMutate: () => {
      spinner.setActive(true);
    },
    onSettled: () => {
      spinner.setActive(false);
    },
  });
};
