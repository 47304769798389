import { Group, useMantineTheme, Text } from '@mantine/core';
import { STORAGE_ITEM } from '../../const';
// import { AttentionTooltip } from 'components/ui';

export const TitleCell = ({ row, isMobile = false }) => {
  const theme = useMantineTheme();

  const Icon = STORAGE_ITEM[row.type]?.Icon;
  // const color = STORAGE_ITEM[row.type]?.color;

  return (
    <Group
      spacing={5}
      align="center"
      position={isMobile ? 'start' : 'apart'}
      w="100%"
    >
      {!isMobile && (
        <Group align="center" spacing={5}>
          {/* <Icon size={16} color={theme.colors[color][9]} /> */}
          {row.title}
        </Group>
      )}

      {isMobile && (
        <Group align="center" spacing={5}>
          <Icon size={24} color={theme.colors['red'][9]} />
          <Text tt="uppercase" fw={700} color="blue">
            {row.title}
          </Text>
        </Group>
      )}

      {/* {row.backgroundStatus === 'danger' && (
        <AttentionTooltip
          label="Нет на складе"
          width="auto"
          iconProps={{ color: 'red', solid: true }}
        />
      )}
      {row.backgroundStatus === 'warning' && (
        <AttentionTooltip
          label={`Осталось мало, менее заданного лимита (${group.storeEndingLimit} шт.)`}
          width="auto"
          iconProps={{ color: 'orange', solid: true }}
        />
      )} */}
    </Group>
  );
};
