import { CurrencyCell } from 'components/Table/cells';
import { TitleCell, QuantityCell } from './cells';
import { STORAGE_ITEM } from '../const';

export const columnsConfig = [
  {
    title: 'Тип',
    name: 'type',
    maxWidth: 60,
    width: 60,
    render: (row) => STORAGE_ITEM[row.type].label,
  },
  {
    title: 'Название',
    name: 'title',
    width: 300,
    render: (row) => <TitleCell row={row} />,
  },
  {
    title: 'Покупка',
    name: 'priceIn',
    maxWidth: 100,
    render: (row) => <CurrencyCell value={row.priceIn} />,
  },
  {
    title: 'Продажа',
    name: 'priceOut',
    maxWidth: 100,
    render: (row) => <CurrencyCell value={row.priceOut} />,
  },
  {
    title: 'Наличие',
    name: 'qty',
    maxWidth: 50,
    render: (row) => <QuantityCell row={row} />,
  },
  {
    title: 'Описание',
    name: 'description',
    render: (row) => <div>{row.description}</div>,
  },
];
