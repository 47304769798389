import { FormProvider, useForm } from 'components/ui/form';
import { initialFormState } from './schema';
import { BaseForm } from './BaseForm';
import { useEditOrder } from 'modules/group/hooks/use-order-mutation';
import { DrawerFactory } from 'core/providers/DrawerProvider';
import { showError } from 'core/notifications';
import { useMemo } from 'react';
import { formatDateToISOString } from 'utils/date';

export const EditForm = ({ store = [], order = {} }) => {
  const { items: orderItems, comment, date, createdAt } = order;

  const items = store.map((item) => {
    const orderItem = orderItems?.find((orderItem) => orderItem.id === item.id);

    return { ...item, qty: orderItem?.qty || 0 };
  });

  const form = useForm({
    initialValues: {
      ...initialFormState.initialValues,
      items,
      comment,
      // fix for old records without date field
      date: date || formatDateToISOString(new Date(createdAt)),
    },
  });

  const initialStateString = useMemo(() => {
    return JSON.stringify({ items, comment });
  }, []);

  const isDirty = initialStateString !== JSON.stringify(form.values);

  const updateOrderMutation = useEditOrder();

  const submitHandler = (values) => {
    updateOrderMutation.mutate(
      { ...order, ...values },
      {
        onSuccess: () => {
          form.reset();
          DrawerFactory.close();
        },
        onError: ({ response }) => {
          if (response.status === 422) {
            showError({
              title: 'Ошибка!',
              message: response.data?.error?.message,
              autoClose: 20000,
            });
          }
        },
      },
    );
  };

  return (
    <FormProvider form={form}>
      <BaseForm onSubmit={submitHandler} isDirty={isDirty} />
    </FormProvider>
  );
};
