import { columnsConfig } from './columns-config';
import { useMembersQuery } from 'modules/group/hooks/use-group-query';
import { TableRender } from 'components/Table/TableRender';
import { useGroup } from 'modules/group/context';
import { useState } from 'react';
import { MobileRender } from './MobileRender';
import { EditForm } from './form';
import { DrawerFactory } from 'core/providers/DrawerProvider';
import { sortIncludingWeekDay } from 'utils/string';
import { Filter, filterInitialState } from './Filter';
import { Spinner } from 'components/ui';

export const ActiveList = () => {
  const [filter, setFilter] = useState(filterInitialState);
  let { jobs, jobEndingLimit } = useGroup();
  const { data: members, isLoading, isFetching } = useMembersQuery();

  if (isLoading) {
    return <Spinner fullscreen />;
  }

  const editHandler = (row) => {
    DrawerFactory.open({
      header: row.title || 'Редактирование служения',
      component: <EditForm job={row} members={members} />,
    });
  };

  const sortedJobs = sortIncludingWeekDay({ array: jobs, name: 'title' });

  const preparedJobs = sortedJobs.map((job) => {
    const user = {};
    let status;
    let backgroundStatus = '';

    if (job.userInfo?.email) {
      const member = members.find(
        (m) => m.email.toLowerCase() === job.userInfo.email.toLowerCase(),
      );

      if (member) {
        user.firstName = member.firstName;
        user.lastName = member.lastName;
        user.userId = member.id;
      }
    }

    if (job.userInfo?.endDate) {
      const endDate = new Date(job.userInfo.endDate);
      const diffMs = endDate.getTime() - Date.now();
      const diffDays = Math.round(diffMs / 1000 / 24 / 3600);

      const isEnding = diffDays < (jobEndingLimit || 7);
      const expired = diffDays <= 0;

      if (expired) {
        backgroundStatus = 'danger';
        status = 'danger';
      } else {
        backgroundStatus = isEnding ? 'warning' : '';
        status = isEnding ? 'ending' : 'active';
      }
    }

    return {
      ...job,
      status,
      backgroundStatus,
      userInfo: { ...job.userInfo, ...user },
    };
  });

  const filteredJobs = preparedJobs.filter((job) => {
    const { email, unregisteredUserName } = job.userInfo || {};

    const isDayMatch = filter.day ? job.day === filter.day : true;
    const isJobMatch = filter.job ? job.title === filter.job : true;
    const isAvailable = filter.available
      ? !email && !unregisteredUserName
      : true;
    const isExpired = filter.expired ? job.status === 'danger' : true;
    const isEnding = filter.ending ? job.status === 'ending' : true;

    return isDayMatch && isJobMatch && isAvailable && isExpired && isEnding;
  });

  return (
    <div>
      {isFetching && <Spinner fullscreen />}

      {!!jobs?.length && (
        <Filter
          filter={filter}
          setFilter={setFilter}
          total={filteredJobs.length}
        />
      )}

      <TableRender
        resource="job"
        columns={columnsConfig}
        noPadding={true}
        onView={false}
        onEdit={editHandler}
        data={filteredJobs}
        mobileRender={(row) => <MobileRender row={row} />}
      />
    </div>
  );
};
