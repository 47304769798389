import { createStyles, rem } from '@mantine/core';
import {
  getBorderColor,
  tableMobilePadding,
  tablePadding,
  whiteDark,
  isDark,
} from 'core/theme/utils';

const getBackgroundColor = (theme, status) => {
  // currently excluded background color setup for dark theme because it looks bad and need to be reworked
  if (isDark(theme)) {
    return theme.colors.dark[8];
  }

  switch (status) {
    case 'danger':
      return isDark(theme) ? theme.colors.red[9] : theme.colors.red[0];
    case 'warning':
      return isDark(theme) ? theme.colors.yellow[9] : theme.colors.yellow[0];
    case 'success':
      return isDark(theme) ? theme.colors.green[9] : theme.colors.green[0];
    default:
      return theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white;
  }
};

export const useStyles = createStyles((theme, options) => {
  return {
    infoWrapper: {
      backgroundColor: whiteDark(theme),
      borderBottom: `${rem(1)} solid ${getBorderColor(theme)}`,
      padding: tablePadding(theme),

      [theme.fn.smallerThan('md')]: { padding: tableMobilePadding(theme) },
    },
    info: {
      display: 'flex',
      alignItems: 'center',
      gap: rem(10),
      justifyContent: 'space-between',

      [theme.fn.smallerThan('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },

    header: {
      fontWeight: 700,
      marginBottom: rem(20),
      color: isDark(theme) ? theme.white : theme.colors.dark[9],

      [theme.fn.smallerThan('md')]: {
        // display: 'none',
      },
    },
    body: {
      padding: options?.noPadding ? 0 : tablePadding(theme),
      paddingBottom: 0,
      marginBottom: 30,

      [theme.fn.smallerThan('md')]: {
        padding: options?.noPadding ? 0 : tableMobilePadding(theme),
        paddingBottom: 0,
      },
    },
    row: {
      'color': isDark(theme) ? theme.white : theme.colors.dark[9],
      'fontSize': rem(13),
      'backgroundColor': getBackgroundColor(theme, options?.backgroundStatus),

      'boxShadow': theme.other.shadow,
      'width': '100%',

      'position': 'relative',
      'borderRadius': rem(8),
      'marginBottom': rem(10),

      '& > .status-indicator': {
        borderRadius: `${rem(8)} 0 0 ${rem(8)}`,
      },

      [theme.fn.smallerThan('md')]: {
        border: `${rem(1)} solid ${theme.colors.gray[3]}`,
        borderColor: isDark(theme)
          ? theme.colors.gray[7]
          : theme.colors.gray[3],
        // backgroundColor: isDark(theme) ? theme.colors.dark[8] : theme.white,
      },
    },
    rowColumns: {
      display: 'flex',
      // flexWrap: 'wrap',
      justifyContent: 'flex-start',
      // alignItems: 'center',
      alignItems: 'stretch',
      padding: `${rem(10)} ${rem(15)}`,
      paddingRight: rem(25), // we need space for actions icon
    },
    rowExpandable: {
      // padding: `0 ${rem(15)}`,
    },
    rowExpandableContent: {
      padding: `${rem(20)} ${rem(30)}`,
      borderTop: `${rem(1)} solid ${theme.colors.gray[3]}`,
      borderTopColor: isDark(theme)
        ? theme.colors.gray[8]
        : theme.colors.gray[3],
      // backgroundColor: theme.colors.blue[0],
      // borderRadius: rem(8),
      // color: theme.white,
    },
    rowExpandableToggle: {
      borderTop: `${rem(1)} solid ${theme.colors.gray[3]}`,
      borderTopColor: isDark(theme)
        ? theme.colors.gray[8]
        : theme.colors.gray[3],
      padding: `${rem(10)} ${rem(15)}`,
      color: theme.colors.gray[6],
      fontSize: rem(12),
      cursor: 'pointer',
    },
    rowExpandableToggleMobile: {
      color: theme.colors.gray[6],
      fontSize: rem(12),
      cursor: 'pointer',
    },

    cellHeader: {
      fontWeight: 700,
      color: isDark(theme) ? theme.white : theme.colors.dark[9],
      fontSize: rem(12),
    },

    cell: {
      'display': 'flex',
      'alignItems': 'center',
      'padding': `${rem(0)} ${rem(10)}`,
      'borderLeft': `${rem(1)} solid #e6e6e6`,
      'borderLeftColor': isDark(theme)
        ? theme.colors.gray[8]
        : theme.colors.gray[3],

      'flex': '150 0 auto',
      'minWidth': rem(100),
      'width': rem(100),

      '&:first-of-type': { borderLeft: 'none', paddingLeft: 0 },
      '&:last-of-type': { paddingRight: 0 },
    },

    rowActions: {
      position: 'absolute',
      right: rem(5),
      top: `50%`,
      transform: `translateY(-40%)`,
      zIndex: 1,
      cursor: 'pointer',
    },
    mobileActions: {
      position: 'absolute',
      right: rem(5),
      top: rem(15),
      zIndex: 1,
      cursor: 'pointer',
    },
  };
});
