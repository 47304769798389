import { FormProvider, useForm } from 'components/ui/form';
import { UserForm } from './UserForm';
import { initialFormState } from './schema';
import { useAddUser } from 'modules/user/hooks/use-user-mutation';
import { showSuccess } from 'core/notifications';
import { DrawerFactory } from 'core/providers/DrawerProvider';

export const AddUserForm = () => {
  const form = useForm(initialFormState);

  const addUserMutation = useAddUser();

  const submitHandler = (values) => {
    addUserMutation.mutate(values, {
      onSuccess: () => {
        showSuccess({ title: 'Пользователь создан' });
        form.reset();
        DrawerFactory.close();
      },
    });
  };

  return (
    <FormProvider form={form}>
      <UserForm onSubmit={submitHandler} />
    </FormProvider>
  );
};
