import { Button, Group, Stack } from '@mantine/core';
import { NEWS_EVENT, eventOptions, groupEvents } from '../const';
import { TimeInput } from '@mantine/dates';
import {
  useFormContext,
  SelectField,
  DatePickerField,
  TextField,
  TextAreaField,
  CheckboxField,
} from 'components/ui/form';
import { useGroupId } from 'modules/group/hooks/use-group-query';
import { useEffect } from 'react';
import { FileUpload } from 'components/ui';
import { countryOptions } from 'core/const';
import { createCityOptions } from 'utils/create-city-options';
import { useGroup } from 'modules/group/context';

export const BaseForm = ({ groups, onSubmit, onDelete }) => {
  const form = useFormContext();
  const canSubmit = form.isValid() && form.isDirty();

  const groupId = useGroupId();
  const isGroupPage = !!groupId; // need to verify if it is a group route (creation of a new inside group module)

  const group = useGroup();

  const cityOptions = createCityOptions(form.values.country);
  const groupOptions = groups.map((group) => {
    return { value: String(group.id), label: group.title };
  });

  // set default values for group news module
  useEffect(() => {
    if (groupId) {
      form.setFieldValue('groupId', groupId);
      form.setFieldValue('onlyForGroup', true);
      form.setFieldValue('country', group.country);
      form.setFieldValue('city', group.city);
    }
  }, [groupId]);

  const renderDeleteButton = typeof onDelete === 'function';

  return (
    <div>
      <Stack spacing={10} mb={30}>
        {isGroupPage && (
          <CheckboxField
            name="onlyForGroup"
            label="Создать для группы (событие будет отображаться только в новостях группы)"
          />
        )}

        {!isGroupPage && (
          <>
            <SelectField
              name="groupId"
              label="Группа"
              data={groupOptions}
              clearable
              onChange={(value) => {
                const group = groups.find(
                  (group) => group.id === Number(value),
                );

                if (group) {
                  form.setFieldValue('country', group.country);
                  form.setFieldValue('city', group.city);
                } else {
                  form.setFieldValue('country', '');
                  form.setFieldValue('city', '');
                }
              }}
              description="Выберите группу, если событие проводится группой"
            />

            <SelectField
              disabled={form.values.groupId}
              name="country"
              label="Страна"
              data={countryOptions}
              required
              onChange={() => {
                form.setFieldValue('city', '');
              }}
              description="Используется для отображения актуальных новостей и событий в регионе"
            />

            <SelectField
              disabled={form.values.groupId}
              name="city"
              label="Город"
              required
              data={cityOptions}
              description="Используется для отображения актуальных новостей и событий в городе"
            />
          </>
        )}

        <SelectField
          withAsterisk
          name="eventType"
          label="Событие"
          data={eventOptions}
          onChange={(value) => {
            if (groupEvents.includes(value)) {
              form.setFieldValue('title', NEWS_EVENT[value]?.title);
            } else {
              form.setFieldValue('title', '');
            }
          }}
        />

        <Group>
          <DatePickerField
            name="date"
            minDate={new Date()}
            label="Дата проведения"
            valueFormat="DD.MM.YYYY"
            style={{ flexGrow: 1 }}
            clearable
          />
          <TimeInput label="Время" {...form.getInputProps('time')} />
        </Group>

        <TextField
          name="title"
          label="Заголовок (по умолчанию название события)"
          disabled={groupEvents.includes(form.values.eventType)}
        />

        <TextAreaField name="text" label="Текст" minRows={5} required />

        <FileUpload
          sizeLimit={5}
          mt={10}
          label="Изображение (если не загружено, используется картинка по умолчанию)"
          fileUrl={form.values.image?.fileUrl}
          onUpload={(file) => {
            form.setFieldValue('newsImage', file);
          }}
          onReset={() => {
            form.setFieldValue('image.fileUrl', '');
            form.setFieldValue('newsImage', null);
          }}
        />
      </Stack>

      <Button
        fullWidth
        size="md"
        onClick={() => {
          const { hasErrors } = form.validate();

          if (!hasErrors) {
            onSubmit(form.values);
          }
        }}
        disabled={!canSubmit}
      >
        Сохранить
      </Button>

      {renderDeleteButton && (
        <Button mt={10} color="red" fullWidth size="md" onClick={onDelete}>
          Удалить
        </Button>
      )}
    </div>
  );
};
