import { SectionContainer } from '../SectionContainer';
import { Flex, HoverCard, Text, Box, Button } from '@mantine/core';
import { useStyles } from './Schedule.styles';
import { IconSettings } from '@tabler/icons-react';
import { DrawerFactory } from 'core/providers/DrawerProvider';
import { EditScheduleForm } from './form';
import { WEEK_DAYS } from 'core/const';
import { useGroup } from 'modules/group/context';
import { useGrantAccess } from 'hooks';
import { sortByWeekDay } from 'utils/string';

const Description = ({ text, day }) => {
  if (!text) {
    return null;
  }

  const sentences = text.split('.');

  return (
    <Flex direction="column" gap={15}>
      <Text tt="uppercase" fw={700}>
        {WEEK_DAYS[day]}
      </Text>
      {sentences?.map((item, index) => {
        let [title, text] = item.split(/: | -/);

        title = title?.trim();
        text = text?.trim();

        return (
          <Box key={index}>
            {title && <Text>{title}</Text>}
            {text && <Text color="dimmed">{text}</Text>}
          </Box>
        );
      })}
    </Flex>
  );
};

const EditButton = ({ onClick }) => {
  return (
    <Button
      size="xs"
      onClick={onClick}
      ml="auto"
      mb={20}
      display="block"
      color="teal"
      leftIcon={<IconSettings size={16} />}
    >
      Настройки
    </Button>
  );
};

export const Schedule = () => {
  const group = useGroup();
  const { classes } = useStyles();
  const { canGroup } = useGrantAccess();

  const schedule = sortByWeekDay(group.schedule);

  const editHandler = () => {
    DrawerFactory.open({
      header: 'Настройка расписания',
      component: (
        <EditScheduleForm data={schedule} className={classes.formCard} />
      ),
    });
  };

  return (
    <SectionContainer>
      {canGroup('update', 'schedule') && <EditButton onClick={editHandler} />}

      {!schedule?.length && (
        <Text color="grey" align="center">
          Нет данных
        </Text>
      )}

      <Flex gap={15} wrap="wrap">
        {schedule?.map((scheduleItem, index) => {
          return (
            <div key={index} className={classes.card}>
              <div className={classes.time}>{scheduleItem.time}</div>
              <div className={classes.day}>{WEEK_DAYS[scheduleItem.day]}</div>

              <div className={classes.divider}></div>

              <div className={classes.subject}>{scheduleItem.subject}</div>
              <div className={classes.description}>
                <HoverCard width={340} shadow="md">
                  <HoverCard.Target>
                    <Text className={classes.truncate} color="dimmed">
                      {scheduleItem.description}
                    </Text>
                  </HoverCard.Target>

                  <HoverCard.Dropdown>
                    <Box p={16}>
                      <Description
                        text={scheduleItem.description}
                        day={scheduleItem.day}
                      />
                    </Box>
                  </HoverCard.Dropdown>
                </HoverCard>
              </div>
            </div>
          );
        })}
      </Flex>
    </SectionContainer>
  );
};
