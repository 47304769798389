import { useFormContext } from './context';
import { DatePickerInput } from '@mantine/dates';

export const DatePickerField = ({
  name,
  onChange: onChangeFromProps,
  ...rest
}) => {
  const form = useFormContext();
  const inputProps = form.getInputProps(name);
  const value = inputProps.value ? new Date(inputProps.value) : null;

  return (
    <DatePickerInput
      {...inputProps}
      onChange={(value) => {
        inputProps.onChange(value);

        if (typeof onChangeFromProps === 'function') {
          onChangeFromProps(value);
        }
      }}
      {...rest}
      value={value} // only Date format
    />
  );
};
