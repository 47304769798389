import { Group, Flex, Text, Box, rem } from '@mantine/core';
import { IconFilterFilled } from '@tabler/icons-react';
import { FilterBadge } from './FilterBadge';
import { useMediaQuery } from 'hooks';
import { DrawerFactory } from 'core/providers/DrawerProvider';
import { FormWrapper } from './FormWrapper';
import { Total, TotalWithBg } from '../ui';

const Wrapper = ({ children, ...rest }) => (
  <Box
    bg="pink"
    pl={15}
    pr={15}
    pt={5}
    pb={5}
    c="white"
    fz={12}
    fw={700}
    style={{ borderRadius: rem(8), border: '1px solid' }}
    {...rest}
  >
    {children}
  </Box>
);

const FilterButton = ({ onClick }) => (
  <Group spacing={5} onClick={onClick} style={{ cursor: 'pointer' }}>
    <IconFilterFilled size={14} />
    <Text>Фильтр</Text>
  </Group>
);

export const Filter = ({
  filter,
  setFilter,
  labels = {},
  Form,
  initialState,
  total = 0,
  renderTotal = true,
  ...rest
}) => {
  const { isMobile } = useMediaQuery();

  const activeFilters = {};

  Object.entries(filter).forEach(([key, value]) => {
    if (!!value && key in initialState) {
      activeFilters[key] = value;
    }
  });

  const filterHandler = () => {
    DrawerFactory.open({
      header: 'Фильтр',
      component: (
        <FormWrapper
          setFilter={setFilter}
          filter={activeFilters}
          initialState={initialState}
        >
          <Form />
        </FormWrapper>
      ),
    });
  };

  const MobileRender = () => (
    <Wrapper pt={8} pb={8} onClick={filterHandler}>
      <Group spacing={0} position="apart">
        <FilterButton />
        {renderTotal && <Total total={total} />}
      </Group>
    </Wrapper>
  );

  const DesktopRender = () => (
    <Group spacing={10}>
      {renderTotal && <TotalWithBg total={total} />}
      <Wrapper>
        <FilterButton onClick={filterHandler} />
      </Wrapper>
    </Group>
  );

  return (
    <Flex gap={20} mb={20} direction={isMobile ? 'column' : 'row'} {...rest}>
      {isMobile ? <MobileRender /> : <DesktopRender />}

      {!!Object.values(activeFilters).length && (
        <Group spacing={10}>
          {Object.entries(activeFilters).map(([filterName, filterValue]) => {
            if (!filterValue) {
              return null;
            }

            let label;

            if (typeof labels[filterName] === 'function') {
              label = labels[filterName](filterValue);
            } else {
              label = labels[filterName] ?? filterValue;
            }

            if (!label) {
              return null;
            }

            return (
              <FilterBadge
                key={filterName}
                name={filterName}
                label={label}
                setFilter={setFilter}
              />
            );
          })}
        </Group>
      )}
    </Flex>
  );
};
