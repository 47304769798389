import { SectionContainer } from '../SectionContainer';
import { News } from 'pages/Home/News';
import { useGroupNewsQuery } from 'pages/Home/hooks/use-news-query';
import { Spinner } from 'components/ui/Spinner';

export const GroupNews = () => {
  const { data, isFetching, isLoading } = useGroupNewsQuery();

  return (
    <SectionContainer>
      {isFetching && <Spinner fullscreen />}
      {!isLoading && <News data={data} isGroupMode />}
    </SectionContainer>
  );
};
