import { GLOBAL_ROLE, GROUP_ROLE } from '../roles';
import { createFullAccess } from '../utils';

export const docsPermissions = [
  ...createFullAccess(GLOBAL_ROLE.admin, 'docs'),
  ...createFullAccess(GROUP_ROLE.group_admin, 'docs'),

  { role: GROUP_ROLE.group_member, resource: 'docs', action: 'read' },
  { role: GROUP_ROLE.group_leader, resource: 'docs', action: 'read' },
  { role: GROUP_ROLE.group_storekeeper, resource: 'docs', action: 'read' },
  { role: GROUP_ROLE.group_paymaster, resource: 'docs', action: 'read' },
];
