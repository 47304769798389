import { Group, Box, Text, ThemeIcon, ActionIcon } from '@mantine/core';
import { FILE_TYPES } from '../const';
import {
  IconFileText,
  IconCloudDownload,
  IconSearch,
} from '@tabler/icons-react';
import { Card } from 'components/ui';
import fileDownload from 'js-file-download';
import { useDownloadCommonFile } from 'modules/group/hooks/use-docs-mutation';

export const Common = ({ data }) => {
  const downloadMutation = useDownloadCommonFile();

  return (
    <div>
      {data?.map((file) => {
        const Icon = FILE_TYPES[file.type]?.icon || IconFileText;

        return (
          <Card key={file.name} mb={10}>
            <Box p="10px 15px">
              <Group noWrap spacing={8} align="center" mb={5}>
                <ThemeIcon
                  color={FILE_TYPES[file.type]?.color || 'gray'}
                  size={25}
                  variant="light"
                >
                  <Icon size={20} />
                </ThemeIcon>

                <Text style={{ wordBreak: 'break-word', lineHeight: '16px' }}>
                  {file.name}
                </Text>

                <Group spacing={5} ml="auto" noWrap>
                  <ActionIcon
                    size={25}
                    color="teal"
                    variant="light"
                    radius="50%"
                  >
                    <IconSearch
                      size={18}
                      onClick={() => {
                        downloadMutation.mutate(file.name, {
                          onSuccess: (blob) => {
                            const link = document.createElement('a');

                            link.href = URL.createObjectURL(blob);
                            link.click();
                            URL.revokeObjectURL(link.href);
                          },
                        });
                      }}
                    />
                  </ActionIcon>

                  <ActionIcon
                    size={25}
                    color="blue"
                    variant="light"
                    radius="50%"
                    ml="auto"
                  >
                    <IconCloudDownload
                      size={18}
                      onClick={() => {
                        downloadMutation.mutate(file.name, {
                          onSuccess: (blob) => {
                            fileDownload(blob, file.name);
                          },
                        });
                      }}
                    />
                  </ActionIcon>
                </Group>
              </Group>
            </Box>
          </Card>
        );
      })}
    </div>
  );
};
