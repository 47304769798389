import { MobileCard, MobileCell, MobileDivider } from 'components/Table/mobile';
import { Group, Text, ThemeIcon, Box } from '@mantine/core';
import { CurrencyCell, DateTimeCell } from 'components/Table/cells';
import { StatusCell } from './cells';
import { ORDER_TYPE } from '../const';
import { IconShoppingCart, IconReplace } from '@tabler/icons-react';

export const MobileRender = ({ row }) => {
  const isCorrection = row.orderType === ORDER_TYPE.correction.name;

  return (
    <MobileCard row={row}>
      <Group position="apart" align="center">
        <Group spacing={5} noWrap>
          <ThemeIcon
            color={isCorrection ? 'pink' : 'teal'}
            size={30}
            variant="light"
          >
            {isCorrection ? (
              <IconReplace size={20} />
            ) : (
              <IconShoppingCart size={20} />
            )}
          </ThemeIcon>

          <Text fw={700} tt="uppercase" c="blue">
            {ORDER_TYPE[row.orderType].label}
          </Text>
        </Group>

        <Box pr={10}>
          <StatusCell value={row.status} />
        </Box>
      </Group>

      <MobileDivider />

      <Group position="apart" align="flex-start">
        <MobileCell name="createdAt">
          <DateTimeCell dateValue={row.createdAt} color="dimmed" />
        </MobileCell>

        <MobileCell name="amount">
          {isCorrection ? (
            '-'
          ) : (
            <CurrencyCell value={row.amount} color="dimmed" />
          )}
        </MobileCell>
      </Group>

      <MobileDivider />
    </MobileCard>
  );
};
