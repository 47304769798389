import { UpdatedCell, CurrencyCell } from 'components/Table/cells';
import { StatusCell } from './cells';
import { ORDER_TYPE } from '../const';

export const columnsConfig = [
  {
    title: 'Дата',
    name: 'createdAt',
    maxWidth: 200,
    truncated: true,
    render: (row) => <UpdatedCell row={row} accessor="createdAt" />,
  },
  {
    title: 'Тип',
    name: 'orderType',
    maxWidth: 100,
    render: (row) => ORDER_TYPE[row.orderType].label,
  },
  {
    title: 'Стоимость',
    name: 'amount',
    maxWidth: 100,
    render: (row) => {
      if (row.orderType === ORDER_TYPE.correction.name) {
        return '-';
      }

      return <CurrencyCell value={row.amount} />;
    },
  },
  {
    title: 'Статус',
    name: 'status',
    width: 150,
    maxWidth: 200,
    render: (row) => <StatusCell value={row.status} />,
  },
];
