import { useQuery } from '@tanstack/react-query';
import { getSettings } from '../api';

const queryOptions = {
  retry: false,
  refetchOnWindowFocus: false,
  refetchOnMount: true,
  keepPreviousData: true,
  staleTime: 5000,
};

export const useSettingsQuery = () => {
  return useQuery({
    queryKey: ['settings'],
    queryFn: getSettings,
    ...queryOptions,
  });
};
