import { AppProvider } from 'core/providers/AppProvider';
import { RouterProvider } from 'core/providers/RouterProvider';
import { Maintenance } from 'pages/Maintenance';

export const App = () => {
  return (
    <AppProvider>
      {!window.isMaintenanceMode && <RouterProvider />}
      {window.isMaintenanceMode && <Maintenance />}
    </AppProvider>
  );
};
