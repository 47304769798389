import { DateTimeCell, EmailCell } from 'components/Table/cells';
import { ROLE_LABELS } from 'core/rbac';

export const columnsConfig = [
  {
    title: 'Участник',
    name: 'email',
    width: 300,
    maxWidth: 350,
    render: (row) => <EmailCell row={row} />,
  },
  {
    title: 'Роль',
    name: 'role',
    render: (row) => <div>{ROLE_LABELS[row.role]}</div>,
    width: 120,
    maxWidth: 120,
  },
  {
    title: 'Создан',
    name: 'createdAt',
    maxWidth: 120,
    render: (row) => <DateTimeCell dateValue={row.createdAt} />,
  },
  {
    title: 'Обновлен',
    name: 'updatedAt',
    maxWidth: 120,
    render: (row) => <DateTimeCell dateValue={row.updatedAt} />,
  },
  {
    title: 'Последний визит',
    name: 'loggedAt',
    maxWidth: 150,
    render: (row) => <DateTimeCell dateValue={row.loggedAt} withTime />,
  },
];
