import { createStyles, rem } from '@mantine/core';
import {
  isDark,
  tableMobilePadding,
  tablePadding,
  whiteBlack,
} from 'core/theme/utils';

export const PX = [16, 40];
export const PY = [16, 30];
export const PADDINGS = `${rem(PY[1])} ${rem(PX[1])}`;
export const MOBILE_PADDINGS = `${rem(PY[0])} ${rem(PX[0])}`;

export const useStyles = createStyles((theme) => ({
  header: {
    backgroundColor: whiteBlack(theme),
    padding: PADDINGS,

    [theme.fn.smallerThan('md')]: { padding: MOBILE_PADDINGS },
  },

  main: {
    position: 'relative',
    // backgroundColor: isDark(theme) ? theme.colors.dark[6] : theme.colors.white,
  },

  section: {
    padding: PADDINGS,
    paddingBottom: rem(50),
    // backgroundColor: isDark(theme) ? theme.colors.dark[6] : theme.colors.white,

    [theme.fn.smallerThan('md')]: { padding: MOBILE_PADDINGS },
  },

  user: {
    'color': isDark(theme) ? theme.colors.dark[0] : theme.black,
    'padding': `${theme.spacing.xs} ${theme.spacing.sm}`,
    'borderRadius': theme.radius.sm,
    'transition': 'background-color 100ms ease',

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
    },

    [theme.fn.smallerThan('xs')]: { display: 'none' },
  },

  links: {
    padding: `0 ${rem(tablePadding(theme))}`,

    background: theme.colors.blue[6],
    // background: 'linear-gradient(90deg, #22b8cf, #1c7ed6)',
    flexWrap: 'nowrap',
    overflowX: 'auto',

    [theme.fn.smallerThan('sm')]: {
      padding: `0 ${rem(tableMobilePadding(theme))}`,
      paddingTop: 0,
      paddingBottom: 0,
    },
  },

  link: {
    'cursor': 'pointer',
    'lineHeight': 1,
    'padding': `${rem(16)} ${rem(12)}`,
    'color': theme.white,
    'fontSize': theme.fontSizes.sm,
    'whiteSpace': 'nowrap',
    'borderBottom': `2px solid ${theme.colors.blue[6]}`,

    '&:hover': { backgroundColor: theme.fn.lighten(theme.colors.blue[6], 0.1) },

    [theme.fn.smallerThan('sm')]: { padding: `${rem(14)} ${rem(12)}` },
  },

  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.lighten(theme.colors.blue[6], 0.1),
      // backgroundColor: 'rgba(0, 0, 0, 0.1)',
      // fontWeight: 700,
      borderBottom: `2px solid ${theme.white}`,
    },
  },
}));
