import { FilterForm } from './FilterForm';
import { formatDateLabel } from 'components/Filter';
import {
  FINANCE_OPERATION_TYPE,
  FINANCE_SOURCE_TYPE,
} from 'modules/group/const';

export const filter = {
  initialState: {
    dateFrom: '',
    dateTo: '',
    operationType: '',
    sourceType: '',
  },
  labels: {
    dateFrom: formatDateLabel('с: '),
    dateTo: formatDateLabel('до: '),
    operationType: (value) => FINANCE_OPERATION_TYPE[value].label,
    sourceType: (value) => FINANCE_SOURCE_TYPE[value].label,
  },
  Form: FilterForm,
};
