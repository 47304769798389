import { object, string, array } from 'yup';
import { yupResolver } from '@mantine/form';
import { formatDateToISOString } from 'utils/date';

export const orderSchema = object({
  items: array().of(object()).nullable(),
  comment: string(),
  date: string(),
});

export const initialFormState = {
  initialValues: {
    items: [],
    comment: '',
    date: formatDateToISOString(new Date()),
  },
  validate: yupResolver(orderSchema),
  validateInputOnBlur: true,
};
