import { httpClient } from 'core/providers/HttpClientProvider';

export const getSettings = async () => {
  const { data } = await httpClient.get('/settings');

  return data;
};

export const updateSettings = async (settings) => {
  const { data } = await httpClient.post('/settings', settings);

  return data;
};
