export const WEEK_DAYS = {
  monday: 'Понедельник',
  tuesday: 'Вторник',
  wednesday: 'Среда',
  thursday: 'Четверг',
  friday: 'Пятница',
  saturday: 'Суббота',
  sunday: 'Воскресенье',
};

export const weekDays = Object.keys(WEEK_DAYS);
export const weekDaysLabels = Object.values(WEEK_DAYS).map((day) =>
  day.toLowerCase(),
);

export const weekDaysOptions = Object.keys(WEEK_DAYS).map((key) => ({
  value: key,
  label: WEEK_DAYS[key],
}));
