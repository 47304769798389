import { FormProvider, useForm } from 'components/ui/form';
import { BaseForm } from './BaseForm';
import { useUpdateGroup } from 'modules/group/hooks/use-group-mutation';
import { showSuccess } from 'core/notifications';
import { DrawerFactory } from 'core/providers/DrawerProvider';
import { initialFormState } from './schema';

export const EditForm = ({ group }) => {
  const form = useForm({
    ...initialFormState,
    initialValues: { ...initialFormState.initialValues, ...group },
  });
  const updateMutation = useUpdateGroup();

  const submitHandler = (values) => {
    updateMutation.mutate(
      { id: values.id, group: values },
      {
        onSuccess: () => {
          showSuccess({ title: 'Данные сохранены' });
          form.reset();
          DrawerFactory.close();
        },
      },
    );
  };

  return (
    <FormProvider form={form}>
      <BaseForm onSubmit={submitHandler} />
    </FormProvider>
  );
};
