import { EmailCell } from 'components/Table/cells';
import { Text, Group, Box } from '@mantine/core';
import { AttentionTooltip } from 'components/ui';

export const MemberCell = ({ userInfo }) => {
  if (userInfo.email && !userInfo.isUnregistered) {
    // user not found - possible was deleted or removed from group
    if (!userInfo.userId) {
      return (
        <Box>
          <Text color="red" fw={700}>
            Пользователь не найден!
          </Text>

          <Group align="center" spacing={5}>
            <Text fz={12} color="dimmed">
              {userInfo.email}
            </Text>
            <AttentionTooltip
              label="Проверьте участников группы. Возможно пользователь был удален."
              iconProps={{ solid: true }}
            />
          </Group>
        </Box>
      );
    }

    return <EmailCell row={userInfo} withAvatar={false} />;
  }

  if (userInfo.unregisteredUserName) {
    return (
      <div>
        <Text fz="sm">{userInfo.unregisteredUserName}</Text>
        <Text fz="xs" c="dimmed">
          Не зарегистрирован
        </Text>
      </div>
    );
  }

  return null;
};
