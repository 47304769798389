import { useMediaQuery as useMediaQueryLib } from '@mantine/hooks';
import { useMantineTheme } from '@mantine/core';

export const useMediaQuery = () => {
  const {
    breakpoints: { xs, md },
  } = useMantineTheme();

  const isMobile = useMediaQueryLib(`(max-width: ${xs})`);
  const isSmallDevice = useMediaQueryLib(`(max-width: ${md})`);
  const isLargeDevice = useMediaQueryLib(`(min-width: ${md})`);

  return {
    isMobile,
    isSmallDevice,
    isLargeDevice,
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
  };
};
