const TOKEN_AUTH_KEY = '__authToken__';
const USER_KEY = '__user__';

export const tokenStorageApi = {
  get: () => {
    return localStorage.getItem(TOKEN_AUTH_KEY);
  },
  remove: () => localStorage.removeItem(TOKEN_AUTH_KEY),
  set: (token) => localStorage.setItem(TOKEN_AUTH_KEY, token),
};

export const userStorageApi = {
  get: () => {
    try {
      return JSON.parse(localStorage.getItem(USER_KEY));
    } catch {
      return null;
    }
  },
  remove: () => localStorage.removeItem(USER_KEY),
  set: (user) => localStorage.setItem(USER_KEY, JSON.stringify(user)),
};
