import { TimeInput } from '@mantine/dates';
import { initialFormState } from './schema';
import { useForm } from '@mantine/form';
import {
  Select,
  TextInput,
  Flex,
  Group,
  Textarea,
  Box,
  Button,
} from '@mantine/core';
import { weekDaysOptions } from './const';
import { AddButton, DeleteButton } from './buttons';
import { useUpdateGroupSchedule } from 'modules/group/hooks/use-group-mutation';
import { DrawerFactory } from 'core/providers/DrawerProvider';

export const EditScheduleForm = ({ data = [], className }) => {
  const form = useForm({
    ...initialFormState,
    initialValues: { schedule: data },
  });

  const canAdd = form.isValid();
  const canSubmit = form.isValid() && form.isDirty();

  const updateMutation = useUpdateGroupSchedule();

  const submitHandler = (values) => {
    updateMutation.mutate(values, {
      onSuccess: () => {
        form.reset();
        DrawerFactory.close();
      },
    });
  };

  return (
    <div>
      <AddButton form={form} isActive={canAdd} />

      {form.values.schedule.map((scheduleItem, index) => {
        return (
          <Box key={index} className={className} mb={30}>
            <DeleteButton form={form} index={index} />
            <Flex gap={5} direction="column">
              <Group spacing={5}>
                <Select
                  withAsterisk
                  label="День недели"
                  variant="filled"
                  {...form.getInputProps(`schedule.${index}.day`)}
                  data={weekDaysOptions}
                  style={{ flexGrow: 1 }}
                />
                <TimeInput
                  withAsterisk
                  label="Время"
                  variant="filled"
                  {...form.getInputProps(`schedule.${index}.time`)}
                />
              </Group>

              <TextInput
                label="Тема"
                variant="filled"
                {...form.getInputProps(`schedule.${index}.subject`)}
              />
              <Textarea
                label="Дополнительное описание"
                placeholder="Пример: 'Заголовок: текст.'"
                variant="filled"
                minRows={3}
                {...form.getInputProps(`schedule.${index}.description`)}
              />
            </Flex>
          </Box>
        );
      })}

      <Box
        style={{
          position: 'sticky',
          bottom: '16px',
          zIndex: 2,
        }}
      >
        <Button
          fullWidth
          size="md"
          mt={40}
          onClick={() => {
            const { hasErrors } = form.validate();

            if (!hasErrors) {
              submitHandler(form.values.schedule);
            }
          }}
          disabled={!canSubmit}
        >
          Сохранить
        </Button>
      </Box>
    </div>
  );
};
