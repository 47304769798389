import { Header } from './Header/Header';
import { SidebarNavigation } from 'core/navigation/SidebarNavigation';
import { PageLayout } from 'components/layouts';
// should place it here to be inside router
import { DrawerProvider } from 'core/providers/DrawerProvider';

export const Main = ({ children }) => {
  return (
    <PageLayout header={<Header />} aside={<SidebarNavigation />}>
      {children}
      <DrawerProvider />
    </PageLayout>
  );
};
