import { Button, Stack } from '@mantine/core';
import {
  useFormContext,
  TextField,
  NumberField,
  SelectField,
} from 'components/ui/form';
import { storageItemOptions } from '../../const';

export const BaseForm = ({ onSubmit }) => {
  const form = useFormContext();
  const canSubmit = form.isValid() && form.isDirty();

  return (
    <>
      <Stack spacing={5} mb={20}>
        <TextField required label="Название" name="title" />
        <SelectField
          required
          label="Категория"
          name="type"
          data={storageItemOptions}
        />
        <NumberField label="Цена покупка" name="priceIn" step={1000} />
        <NumberField label="Цена продажа" name="priceOut" step={1000} />
        <TextField label="Описание" name="description" />
        {/*<NumberField label="Количество" name="qty" />*/}
      </Stack>

      <Button
        fullWidth
        size="md"
        // className={classes.submit}
        mt={10}
        onClick={() => {
          const { hasErrors } = form.validate();

          if (!hasErrors) {
            onSubmit(form.values);
          }
        }}
        disabled={!canSubmit}
      >
        Сохранить
      </Button>
    </>
  );
};
