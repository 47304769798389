// import 'styles/global.css';
import { ThemeProvider } from './ThemeProvider';
import { QueryProvider } from './QueryProvider';
import { SpinnerProvider } from './SpinnerProvider';
import { Notifications } from 'core/notifications/Notifications';
import { ModalProvider } from './ModalProvider';
import { GeoLocationProvider } from './GeoLocationProvider';

import 'dayjs/locale/ru';
import { DatesProvider } from '@mantine/dates';

export const AppProvider = ({ children }) => {
  return (
    <QueryProvider>
      <ThemeProvider>
        <ModalProvider>
          <GeoLocationProvider>
            <DatesProvider
              settings={{
                locale: 'ru',
                firstDayOfWeek: 1,
                weekendDays: [0, 6],
              }}
            >
              {children}
            </DatesProvider>
          </GeoLocationProvider>
        </ModalProvider>
        <Notifications />
        <SpinnerProvider />
      </ThemeProvider>
    </QueryProvider>
  );
};
