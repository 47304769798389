import {
  useFormContext,
  IncrementNumberInput,
  TextAreaField,
} from 'components/ui/form';
import { Button, Group, Text } from '@mantine/core';

export const BaseForm = ({ onSubmit, isDirty }) => {
  const form = useFormContext();
  const items = form.getInputProps('items').value;
  const canSubmit = isDirty && items.filter((item) => item.qty > 0).length;

  return (
    <div>
      {items.map((item, index) => {
        return (
          <Group
            key={item.id}
            spacing={10}
            mb={10}
            align="center"
            position="apart"
            noWrap
          >
            <Text fz={12} lh={1.2}>
              {item.title}
            </Text>
            <IncrementNumberInput
              value={item.qty}
              onChange={(value) => {
                const currentItems = [...items];

                currentItems[index].qty = value;

                form.setFieldValue(`items`, currentItems);
              }}
            />
          </Group>
        );
      })}

      <TextAreaField name="comment" placeholder="Комментарий..." minRows={3} />

      <Button
        fullWidth
        size="md"
        mt={20}
        onClick={() => {
          const { hasErrors } = form.validate();
          const items = form.values.items.filter((item) => item.qty > 0);
          const amount = items.reduce(
            (acc, item) => acc + item.qty * item.priceIn,
            0,
          );

          if (!hasErrors) {
            onSubmit({
              ...form.values,
              items,
              amount,
            });
          }
        }}
        disabled={!canSubmit}
      >
        Отправить
      </Button>
    </div>
  );
};
