import { getBorderColor } from 'core/theme/utils';
import { useMantineTheme, Divider, Text, Box } from '@mantine/core';

export const Section = ({ title, children }) => {
  const theme = useMantineTheme();

  return (
    <Box>
      <Divider mt={20} mb={10} color={getBorderColor(theme)} />

      <Text fz={14} color="dimmed" mb={10}>
        {title}
      </Text>

      {children}
    </Box>
  );
};
