import { WEEK_DAYS } from 'core/const';
import { Box, Text, Group } from '@mantine/core';
// import { isDark } from '../../core/theme/utils';

export const JobWithDay = ({
  job,
  inline = false,
  color = 'dimmed',
  ...rest
}) => {
  // const theme = useMantineTheme();

  // color = isDark(theme) ? 'white' : color;

  if (inline) {
    return (
      <Group spacing={5} align="center">
        <Text {...rest}>{job.title}</Text>
        {job.day && (
          <Text c={color}>({WEEK_DAYS[job.day]?.toLowerCase()})</Text>
          // <Text c={color}>[{WEEK_DAYS[job.day]?.toLowerCase()}]</Text>
        )}
      </Group>
    );
  }

  return (
    <Box>
      <Text {...rest}>{job.title}</Text>
      {job.day && <Text c={color}>{WEEK_DAYS[job.day]?.toLowerCase()}</Text>}
    </Box>
  );
};
