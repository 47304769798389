import { Button, Stack, Text, Group } from '@mantine/core';
import { yupResolver } from '@mantine/form';
import { object, string, array } from 'yup';
import {
  FormProvider,
  useForm,
  TextAreaField,
  IncrementNumberInput,
} from 'components/ui/form';
import { useMemo } from 'react';
import { useFixStore } from 'modules/group/hooks/use-store-mutation';
import { openConfirmModal } from 'components/ui/Modal';
import { showSuccess } from 'core/notifications';
import { DrawerFactory } from 'core/providers/DrawerProvider';
import { formatDateToISOString } from 'utils/date';

export const fixSchema = object({
  store: array().of(object()).nullable(),
  comment: string(),
  date: string(),
});

export const getInitialState = (group) => ({
  initialValues: {
    store: group?.store || [],
    comment: '',
    date: formatDateToISOString(new Date()),
  },
  validate: yupResolver(fixSchema),
  validateInputOnBlur: true,
});

export const FixForm = ({ group }) => {
  const initialStateString = useMemo(() => {
    return JSON.stringify(getInitialState(group).initialValues);
  }, [group]);

  const form = useForm(getInitialState(group));
  const store = form.getInputProps('store').value;

  const canSubmit = initialStateString !== JSON.stringify(form.values);

  const fixStoreMutation = useFixStore();

  const fixStoreHandler = (data) => {
    openConfirmModal({
      title: 'Сохранить изменения?',
      children: (
        <>
          <Text mb={10}>
            После корретировки все отчеты по собраниям, соданные до этого
            момента, будут более не доступны для редактирования.
          </Text>
          {/*<Text>*/}
          {/*  Все заказы, которые находятся в статусе &quot;ожидается&quot; будут*/}
          {/*  отменены.*/}
          {/*</Text>*/}
        </>
      ),
      onConfirm: () => {
        fixStoreMutation.mutate(data, {
          onSuccess: () => {
            showSuccess({ title: 'Склад обновлен.' });
            form.reset();
            DrawerFactory.close();
          },
        });
      },
    });
  };

  return (
    <FormProvider form={form}>
      <Stack spacing={10} mb={20}>
        {store.map((item, index) => {
          return (
            <Group
              key={item.id}
              spacing={10}
              mb={10}
              align="center"
              position="apart"
              noWrap
            >
              <Text fz={12} lh={1.2}>
                {item.title}
              </Text>
              <IncrementNumberInput
                value={item.qty}
                onChange={(value) => {
                  const currentItems = [...store];

                  currentItems[index].qty = value;

                  form.setFieldValue('store', currentItems);
                }}
              />
            </Group>
          );
        })}

        <TextAreaField
          name="comment"
          placeholder="Комментарий..."
          minRows={3}
        />
      </Stack>

      <Button
        fullWidth
        size="md"
        mt={10}
        onClick={() => {
          const { hasErrors } = form.validate();

          if (!hasErrors) {
            fixStoreHandler(form.values);
          }
        }}
        disabled={!canSubmit}
      >
        Отправить
      </Button>
    </FormProvider>
  );
};
