import { Table } from 'components/Table';
import { columnsConfig } from './columns-config';

export const History = () => {
  return (
    <Table
      resource="user"
      columns={columnsConfig}
      resourceEndpoint="/log"
      tableKey="log"
      onView={false}
      title="История"
    />
  );
};
