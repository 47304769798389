import { Divider, Drawer, Title, Box, Button } from '@mantine/core';
import { useStyles } from './Drawer.styles';

export const DrawerComponent = ({
  opened,
  onClose,
  children,
  header,
  position,
  bgColor,
  padding,
  onSubmit,
  canSubmit,
  submitLabel = 'Сохранить',
  size = 'md',
}) => {
  const { classes, theme } = useStyles({ bgColor, padding });

  header =
    typeof header === 'string' ? <Title size={28}>{header}</Title> : header;

  return (
    <Drawer.Root
      opened={opened}
      onClose={onClose}
      padding={0}
      position={position}
      size={size}
      transitionProps={{
        duration: 300,
        // transition: position === 'right' ? 'slide-left' : 'slide-right',
        transition: 'slide-right',
      }}
      trapFocus={false}
    >
      <Drawer.Overlay />

      <Drawer.Content className={classes.content}>
        <Drawer.Header className={classes.header}>
          {header}
          <Drawer.CloseButton />
        </Drawer.Header>

        <Divider color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'} />

        <Drawer.Body className={classes.body}>{children}</Drawer.Body>

        {typeof onSubmit === 'function' && (
          <Box className={classes.saveButton}>
            <Button
              fullWidth
              size="md"
              onClick={onSubmit}
              disabled={!canSubmit}
            >
              {submitLabel}
            </Button>
          </Box>
        )}
      </Drawer.Content>
    </Drawer.Root>
  );
};
