import { modals } from '@mantine/modals';
// import { useMediaQuery } from 'hooks';
import { DesktopPDFRender } from './DesktopPDFRender';
// import { MobilePDFRender } from './MobilePDFRender';
// import { MobilePDFRenderExternalLib } from './MobilePDFRenderExternalLib';
import { Text } from '@mantine/core';

const PDFDocument = ({ data }) => {
  // const { isMobile } = useMediaQuery();

  return <DesktopPDFRender data={data} />;

  // return isMobile ? (
  //   <MobilePDFRenderExternalLib data={data} />
  // ) : (
  //   <DesktopPDFRender data={data} />
  // );
};

export const openPDFModal = (data, title) => {
  return modals.open({
    title: <Text weight={700}>{title}</Text>,
    children: <PDFDocument data={data} />,
    trapFocus: false,
    // withCloseButton: false,
    radius: '0',
    fullScreen: true,
    centered: true,
    padding: '0px',
    styles: {
      title: {
        textAlign: 'center',
        display: 'block',
        width: '100%',
      },
      header: {
        padding: '10px',
        boxShadow: '0 0 15px 0 rgba(0,0,0,0.3)',
      },
    },
  });
};
