import { useEffect, useRef } from 'react';
import { formatDateToISOString } from 'utils/date';
import { useFormContext } from 'components/ui/form';

export const MEETING_STORAGE_KEY = '__meeting__';

export const useBeforeUnload = () => {
  const form = useFormContext();
  const formRef = useRef(form);

  useEffect(() => {
    formRef.current = form;
  });

  const beforeUnloadHandler = () => {
    if (formRef.current.isDirty()) {
      const date = formatDateToISOString(formRef.current.values?.date);

      localStorage.setItem(
        MEETING_STORAGE_KEY,
        JSON.stringify({ ...formRef.current.values, date }),
      );
    }
  };

  // use pagehide event as beforeunload doesn't work in mobile browsers
  useEffect(() => {
    window.addEventListener('pagehide', beforeUnloadHandler);

    return () => {
      window.removeEventListener('pagehide', beforeUnloadHandler);
    };
  }, []);
};
