import { NumberInput } from '@mantine/core';
import { useFormContext } from './context';
import { formatNumber, reformatNumber } from 'utils/string';

export const NumberField = ({ name, ...rest }) => {
  const form = useFormContext();

  return (
    <NumberInput
      stepHoldDelay={500}
      stepHoldInterval={100}
      parser={(value) => reformatNumber(value)}
      formatter={(value) => formatNumber(value)}
      {...form.getInputProps(name)}
      {...rest}
    />
  );
};
