import { useGeoLocation } from 'core/providers/GeoLocationProvider/use-geo-location';
import { useAuth } from 'modules/auth/use-auth';

export const useUserLocation = () => {
  const { address, isLoading } = useGeoLocation();
  const { user } = useAuth();

  return {
    country: address?.country || user?.country,
    city: address?.city || user?.city,
    isLoading,
  };
};
