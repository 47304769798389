import { Filter as FilterComponent } from 'components/Filter';
import { FilterForm } from './FilterForm';
import { STORAGE_ITEM } from '../../const';

export const filterInitialState = {
  type: '',
  ended: '',
  ending: '',
};

const filterLabels = {
  type: (filterValue) => STORAGE_ITEM[filterValue]?.label,
  ended: 'Нет в наличии',
  ending: 'Заканчиваются',
};

export const Filter = (props) => {
  return (
    <FilterComponent
      initialState={filterInitialState}
      labels={filterLabels}
      Form={FilterForm}
      {...props}
    />
  );
};
