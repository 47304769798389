import { useEffect, useState } from 'react';

export const useYandexMap = ({ id, center, zoom, controls }) => {
  const [map, setMap] = useState(null);

  // console.log('useYandexMap', { id, center, zoom, controls });

  useEffect(() => {
    if (window.ymaps) {
      window.ymaps.ready(function () {
        const yandexMap = new window.ymaps.Map(id, {
          center,
          zoom,
          controls,
        });

        const placemark = new window.ymaps.Placemark(center);

        yandexMap.geoObjects.add(placemark);

        setMap(yandexMap);
      });
    }
  }, [window.ymaps]);

  return map;
};
