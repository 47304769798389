import { Group, Text } from '@mantine/core';
import { AttentionTooltip } from 'components/ui';
import { useGroup } from 'modules/group/context';

export const QuantityCell = ({ row }) => {
  const group = useGroup();

  return (
    <Group spacing={5}>
      <Text>{row.qty}</Text>

      {row.backgroundStatus === 'danger' && (
        <AttentionTooltip
          label="Нет на складе"
          width="auto"
          iconProps={{ color: 'red', solid: true }}
        />
      )}
      {row.backgroundStatus === 'warning' && (
        <AttentionTooltip
          label={`Осталось менее заданного лимита (${group.storeEndingLimit} шт.)`}
          width="auto"
          iconProps={{ color: 'orange', solid: true }}
        />
      )}
    </Group>
  );
};
