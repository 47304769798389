import { modals } from '@mantine/modals';
import { Text } from '@mantine/core';

export const openDeleteModal = ({ title, children, onConfirm }) => {
  return modals.openConfirmModal({
    title: <Text weight={700}>{title || 'Удаление'}</Text>,
    children: (
      <Text size="sm" mb={30}>
        {children || 'Вы уверены, что хотите удалить данные?'}
      </Text>
    ),
    labels: { confirm: 'Удалить', cancel: 'Отмена' },
    confirmProps: { color: 'red' },
    onConfirm: onConfirm,
  });
};
