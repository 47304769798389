import { useGroupRequestsQuery } from 'modules/group/hooks/use-group-query';
import { EmailCell } from 'components/Table/cells';
import { showSuccess } from 'core/notifications';
import { useMediaQuery } from 'hooks';
import { Card, Spinner } from 'components/ui';
import { IconCircleXFilled, IconCircleCheckFilled } from '@tabler/icons-react';
import { Group, ActionIcon, Flex, Text } from '@mantine/core';
import {
  useAcceptMemberRequest,
  useDeclineMemberRequest,
} from 'modules/group/hooks/use-request-mutation';

export const Requests = () => {
  let { data, isFetching } = useGroupRequestsQuery();
  const { isMobile } = useMediaQuery();
  const acceptMutation = useAcceptMemberRequest();
  const declineMutation = useDeclineMemberRequest();

  if (isFetching) {
    return <Spinner fullscreen />;
  }

  if (!data?.length) {
    return (
      <Text align="center" color="dimmed">
        Нет запросов
      </Text>
    );
  }

  return data.map((request) => {
    return (
      <Card key={request.id} mb={10}>
        <Flex
          align={isMobile ? 'flex-start' : 'center'}
          justify="space-between"
          direction={isMobile ? 'column' : 'row'}
          gap={10}
          p={16}
        >
          <EmailCell row={request.user} />

          <Group align="center" spacing={5} ml="auto">
            <ActionIcon size={40} color="blue" variant="light">
              <IconCircleCheckFilled
                size="2rem"
                onClick={() => {
                  acceptMutation.mutate(request.id, {
                    onSuccess: () => {
                      showSuccess({
                        title: 'Запрос подтвержден',
                        message: 'Пользователь добавлен в группу',
                      });
                    },
                  });
                }}
              />
            </ActionIcon>

            <ActionIcon size={40} color="red" variant="light">
              <IconCircleXFilled
                size={30}
                onClick={() => {
                  declineMutation.mutate(request.id, {
                    onSuccess: () => {
                      showSuccess({ title: 'Запрос отклонен' });
                    },
                  });
                }}
              />
            </ActionIcon>
          </Group>
        </Flex>
      </Card>
    );
  });
};
