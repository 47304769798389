import { SectionContainer } from '../SectionContainer';
import { useState } from 'react';
import { Group, SegmentedControl } from '@mantine/core';
import { ActiveList } from './ActiveList';
import { Settings } from './Settings';

export const Jobs = () => {
  const [tab, setTab] = useState('activeList');

  return (
    <SectionContainer>
      <Group align="center" position="center" mb={20}>
        <SegmentedControl
          value={tab}
          onChange={setTab}
          color="dark"
          radius={6}
          data={[
            { label: 'Список', value: 'activeList' },
            { label: 'Настройки', value: 'settings' },
          ]}
        />
      </Group>

      {tab === 'settings' && <Settings />}
      {tab === 'activeList' && <ActiveList />}
    </SectionContainer>
  );
};
