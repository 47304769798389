import { Button, Stack, MultiSelect } from '@mantine/core';
import {
  useFormContext,
  TextField,
  NumberField,
  TextAreaField,
  SelectField,
} from 'components/ui/form';
import { groupRoleOptions } from 'core/rbac';
import { weekDaysOptions } from '../../../Schedule/form/const';

export const BaseForm = ({ onSubmit }) => {
  const form = useFormContext();
  const canSubmit = form.isValid() && form.isDirty();

  return (
    <>
      <Stack spacing={5} mb={30}>
        <TextField required label="Название" name="title" />
        <SelectField
          name="day"
          label="День недели"
          data={weekDaysOptions}
          clearable
        />
        <MultiSelect
          {...form.getInputProps('roles')}
          label="Роль"
          data={groupRoleOptions}
        />
        <TextField label="Ценз" name="condition" />
        <NumberField label="Срок (месяцы)" name="period" step={1} min={1} />
        <TextAreaField minRows={5} label="Описание" name="description" />
      </Stack>

      <Button
        fullWidth
        size="md"
        mt={10}
        onClick={() => {
          const { hasErrors } = form.validate();

          if (!hasErrors) {
            onSubmit(form.values);
          }
        }}
        disabled={!canSubmit}
      >
        Сохранить
      </Button>
    </>
  );
};
