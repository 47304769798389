import { Home, Settings, History } from 'pages';
import { Users } from 'modules/user/pages';
import { Groups, Group } from 'modules/group/pages';
import {
  IconHome,
  // IconSearch,
  IconSettings,
  IconFlag3,
  IconUser,
  IconHistory,
} from '@tabler/icons-react';
import { Outlet } from 'react-router-dom';

const USERS_LABEL = 'Пользователи';

export const config = [
  // {
  //   title: 'Поиск',
  //   icon: IconSearch,
  // },
  {
    path: '/',
    element: <Home />,
    title: 'Главная',
    icon: IconHome,
  },
  {
    resource: 'group', // need to validate access
    path: '/groups',
    element: <Outlet />,
    title: 'Группы',
    icon: IconFlag3,
    children: [
      {
        index: true,
        element: <Groups />,
        title: 'Группы',
      },
      {
        path: ':groupId/*',
        element: <Group />,
        title: 'Группа',
        children: [{ path: ':sectionId/:subSectionId?', element: <Outlet /> }],
      },
    ],
  },

  {
    resource: 'user',
    path: '/users',
    element: <Outlet />,
    title: USERS_LABEL,
    icon: IconUser,
    children: [
      {
        index: true,
        element: <Users />,
        title: USERS_LABEL,
      },
    ],
  },

  {
    resource: 'settings',
    path: '/settings',
    // onlyNavigation: true,
    element: <Settings />,
    title: 'Настройки',
    icon: IconSettings,
  },
  {
    resource: 'log',
    path: '/history',
    element: <History />,
    title: 'История запросов',
    icon: IconHistory,
  },
];
