import { BooksCell, UpdatedCell } from 'components/Table/cells';
import { Comment } from 'components/Comment';
import { Text, Box } from '@mantine/core';
import { useStyles } from 'components/Table/Table.styles';
import { ORDER_TYPE } from '../const';
import { MobileDivider } from 'components/Table/mobile';

export const ExpandableRowRender = ({ row }) => {
  const { classes } = useStyles();
  const isCorrection = row.orderType === ORDER_TYPE.correction.name;

  return (
    <>
      <Box mb={20}>
        <Text className={classes.cellHeader} mb={5}>
          Пользователь
        </Text>
        <Text color="dimmed">{row.updatedBy}</Text>
      </Box>

      <MobileDivider />

      <Box mb={20}>
        <Text className={classes.cellHeader} mb={5}>
          {isCorrection ? 'Данные по коррекции' : 'Описание заказа'}
        </Text>

        {!isCorrection && <BooksCell data={row.items} accessor="priceIn" />}
        {isCorrection && <BooksCell data={row.items} inline />}
      </Box>

      <MobileDivider />

      <Box mb={20}>
        <Text className={classes.cellHeader} mb={5}>
          Комментарий
        </Text>
        <Comment color="dimmed">{row.comment}</Comment>
      </Box>

      <MobileDivider />

      <Box>
        <Text className={classes.cellHeader} mb={5}>
          Изменено
        </Text>

        <UpdatedCell row={row} withTime />
      </Box>
    </>
  );
};
