import { useMutation } from '@tanstack/react-query';
import { addOrder, editOrder, deleteOrder, completeOrder } from '../api';
import { useOptions } from './use-query-options';

const mutationOptions = ({ queryClient, spinner, groupId }) => ({
  onMutate: () => {
    spinner.setActive(true);
  },
  onSettled: () => {
    spinner.setActive(false);
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['group', groupId] });
    queryClient.invalidateQueries({ queryKey: [`orders-${groupId}`] });
  },
});

export const useAddOrder = () => {
  const options = useOptions();

  return useMutation({
    mutationFn: (order) => addOrder({ groupId: options.groupId, order }),
    ...mutationOptions(options),
  });
};

export const useEditOrder = () => {
  const options = useOptions();

  return useMutation({
    mutationFn: (order) => editOrder({ groupId: options.groupId, order }),
    ...mutationOptions(options),
  });
};

export const useCompleteOrder = () => {
  const options = useOptions();

  return useMutation({
    mutationFn: (order) => completeOrder({ groupId: options.groupId, order }),
    ...mutationOptions(options),
  });
};

export const useDeleteOrder = () => {
  const options = useOptions();

  return useMutation({
    mutationFn: (orderId) =>
      deleteOrder({ groupId: options.groupId, id: orderId }),
    ...mutationOptions(options),
  });
};
