import { object, string } from 'yup';
import { yupResolver } from '@mantine/form';
import { messages } from 'utils/messages';
import { formatDateToISOString } from 'utils/date';

export const financeSchema = object({
  operationType: string().required(messages.required),
  sourceType: string().required(messages.required),
  amount: string().required(messages.required),
  comment: string(),
  date: string(),
});

export const initialFormState = {
  initialValues: {
    operationType: '',
    sourceType: '',
    amount: '',
    comment: '',
    date: formatDateToISOString(new Date()),
  },
  validate: yupResolver(financeSchema),
  validateInputOnBlur: true,
};
