import { AttentionTooltip } from 'components/ui';
import { Group, Text, useMantineTheme } from '@mantine/core';
import { JobWithDay } from 'components/JobWithDay';
import { WEEK_DAYS } from '../../../Schedule/form/const';
import { isDark } from 'core/theme/utils';

export const JobNameCell = ({ row, isMobile }) => {
  const theme = useMantineTheme();
  const color = isDark(theme) ? 'white' : 'dimmed';

  return (
    <Group
      spacing={5}
      align="center"
      position={isMobile ? 'start' : 'apart'}
      w="100%"
    >
      {!isMobile && <JobWithDay job={row} />}
      {isMobile && (
        <Group spacing={5} align="center">
          <Text fw={700} tt="uppercase" c="blue">
            {row.title}
          </Text>
          {row.day && (
            <Text tt="uppercase" c={color}>
              ({WEEK_DAYS[row.day]?.toLowerCase()})
            </Text>
          )}
        </Group>
      )}

      {row.backgroundStatus === 'danger' && (
        <AttentionTooltip
          label="Закончился срок служения"
          width="auto"
          iconProps={{ color: 'red', solid: true }}
        />
      )}
      {row.backgroundStatus === 'warning' && (
        <AttentionTooltip
          label="Заканчивается срок служения"
          width="auto"
          iconProps={{ color: 'orange', solid: true }}
        />
      )}
    </Group>
  );
};
