import { Text } from '@mantine/core';

const DELIMITER = '::';

export const Comment = ({ children, ...rest }) => {
  if (!children) {
    return <Text {...rest}>нет</Text>;
  }

  const comments = children.split(DELIMITER);

  return comments.map((comment, index) => (
    <Text key={index} mb={5} {...rest}>
      {comment}
    </Text>
  ));
};
