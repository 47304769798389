import { EmailCell } from 'components/Table/cells';
import { ROLE_LABELS } from 'core/rbac';
import { Badge, Group } from '@mantine/core';

export const UserCell = ({ row }) => {
  return (
    <div>
      <EmailCell row={row} />
      <Group spacing={5} mt={10}>
        {row.groupRoles.map((role) => (
          <Badge key={role} color="blue" size="xs" variant="light">
            {ROLE_LABELS[role]}
          </Badge>
        ))}
      </Group>
    </div>
  );
};
