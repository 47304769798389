import { useStyles } from '../Table.styles';
import { StatusIndicator } from '../../StatusIndicator';
import { ActionsCell } from '../cells';
import { Box, Group, Text } from '@mantine/core';
import { createContext, useContext } from 'react';
import { useTable } from '../context';
import { useDisclosure } from '@mantine/hooks';
import { IconChevronDown } from '@tabler/icons-react';
import { MobileDivider } from './MobileDivider';

const MobileRowContext = createContext();

export const useRow = () => useContext(MobileRowContext);

const ExpandableCell = ({ row, render }) => {
  const [opened, { toggle }] = useDisclosure(false);
  const { classes } = useStyles();

  return (
    <Box>
      {opened && (
        <>
          <Box p={5}>{render(row)}</Box>
          <MobileDivider />
        </>
      )}

      <Group
        spacing={5}
        align="center"
        position="center"
        className={classes?.rowExpandableToggleMobile}
        onClick={toggle}
      >
        <Text>{opened ? 'Закрыть' : 'Подробнее'}</Text>
        <IconChevronDown
          size={16}
          style={{ transform: opened ? 'rotate(180deg)' : '' }}
        />
      </Group>
    </Box>
  );
};

export const MobileCard = ({ row, children }) => {
  const { classes } = useStyles({ backgroundStatus: row.backgroundStatus });
  const { expandableRowRender } = useTable();

  return (
    <MobileRowContext.Provider value={row}>
      <Box className={classes.row} p={16}>
        <StatusIndicator status={row.status} />

        <ActionsCell row={row} className={classes.mobileActions} />

        {children}

        {expandableRowRender && (
          <ExpandableCell render={expandableRowRender} row={row} />
        )}
      </Box>
    </MobileRowContext.Provider>
  );
};
