import { useQuery } from '@tanstack/react-query';
import { httpClient } from 'core/providers/HttpClientProvider';

export const useTableQuery = ({
  resourceEndpoint,
  tableKey,
  ...searchParams
}) => {
  const { page, size, sort, ...rest } = searchParams;

  return useQuery({
    queryKey: [tableKey, page, size, sort, JSON.stringify(rest)],
    queryFn: () => {
      return httpClient.get(resourceEndpoint, {
        params: {
          page,
          size,
          sort,
          ...rest,
        },
      });
    },
    select: (response) => {
      const {
        data: { data = [], ...pagination },
      } = response;

      const isPaginationAwareResponse = Boolean(pagination?.currentPage);

      // it means that response is not paginated and just returns an array of data
      if (!isPaginationAwareResponse) {
        return { data: Object.values(pagination) };
      }

      return { data, ...pagination };
    },
    refetchOnMount: true,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 5000,
    retry: false,
  });
};
