import {
  useRouteError,
  isRouteErrorResponse,
  // useNavigate,
} from 'react-router-dom';
import { Button, Container, Title, Text, Group } from '@mantine/core';
import { useSpinner } from '../../core/providers/SpinnerProvider';
import { useEffect } from 'react';
import { useStyles } from './Error.styles';

const ErrorMessage = ({ status, statusText, data, className }) => {
  return (
    <Text color="dimmed" size="lg" align="center" className={className}>
      {status && <div>Status: {status}</div>}
      {statusText && <div>StatusText: {statusText}</div>}
      {data?.message && <div>{data.message}</div>}
    </Text>
  );
};

export const Error = ({ resetError }) => {
  const error = useRouteError();
  const renderMessage = isRouteErrorResponse(error);

  const spinner = useSpinner();

  // const navigate = useNavigate();

  useEffect(() => {
    spinner.setActive(false);
  }, []);

  const { classes } = useStyles();

  return (
    <Container className={classes.root}>
      <div className={classes.label}>OOPS!</div>
      <Title className={classes.title}>Что то пошло не так...</Title>
      {renderMessage && (
        <ErrorMessage className={classes.description} {...error} />
      )}

      <Group position="center">
        <Button
          mt={20}
          size="md"
          onClick={() => {
            // if (resetError) {
            //   navigate('/');
            //   resetError();
            // }
            // window.location.reload();
            window.location.href = '/';
          }}
        >
          Обратно на главную страницу
        </Button>
      </Group>
    </Container>
  );
};
