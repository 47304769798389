import { Box, Text } from '@mantine/core';
import { useStyles } from './Indicator.styles';

export const Indicator = ({ value = 0, size, color }) => {
  const { classes } = useStyles({ size, color });

  return (
    <Box className={classes.parent}>
      <Text className={classes.child}>{value}</Text>
    </Box>
  );
};
