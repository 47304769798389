import { MONTH_NAMES } from 'core/const';

export const addZero = (value) => (value < 10 ? `0${value}` : value);

const getTimeFromJsDate = (date, format = 'hh:mm') => {
  if (!(date instanceof Date)) {
    return;
  }

  const pattern = format.split(':');

  const hh = addZero(date.getHours());
  const mm = addZero(date.getMinutes());
  const ss = addZero(date.getSeconds());

  return pattern.length === 2 ? `${hh}:${mm}` : `${hh}:${mm}:${ss}`;
};

export const getDateFromJsDate = (date) => {
  if (!(date instanceof Date)) {
    return;
  }

  const year = date.getFullYear();
  const month = addZero(date.getMonth() + 1);
  const day = addZero(date.getDate());

  return {
    year,
    month,
    day,
  };
};

const formatDate = ({
  value,
  dateSeparator = '/',
  timeSeparator,
  withTime = true,
  monthFormat = 'short',
  yearFormat = 'long', // long, short, none
}) => {
  if (!value) {
    return;
  }

  const date = new Date(value);
  const { year, month, day } = getDateFromJsDate(date);

  const yearString =
    yearFormat === 'none'
      ? ''
      : yearFormat === 'long'
      ? year
      : year.toString().slice(-2);

  let dateString = `${day}${dateSeparator}${month}${dateSeparator}${year}`;

  if (yearString) {
    dateString = `${day}${dateSeparator}${month}${dateSeparator}${yearString}`;
  }

  if (monthFormat === 'long') {
    const monthName = MONTH_NAMES[month - 1];

    dateString = `${day} ${monthName} ${yearString}`.trim();
  }

  const timeString = getTimeFromJsDate(date, timeSeparator);

  if (withTime) {
    return `${dateString} в ${timeString}`;
  }

  return dateString;
};

const formatDateToISOString = (date) => {
  if (!date || !(date instanceof Date)) {
    return date;
  }

  const { year, month, day } = getDateFromJsDate(date);

  return `${year}-${month}-${day}`;
};

function datesDiff(startDate, endDate) {
  if (!startDate) {
    return;
  }

  const dateFrom = new Date(startDate);
  const dateTo = endDate ? new Date(endDate) : new Date();

  const yearTo = dateTo.getFullYear();
  const monthTo = dateTo.getMonth() + 1;
  const dayTo = dateTo.getDate();

  const yearFrom = dateFrom.getFullYear();
  const monthFrom = dateFrom.getMonth() + 1;
  const dayFrom = dateFrom.getDate();

  // const dateFrom = startDate.split('-');
  // const yearFrom = parseInt(dateFrom[0]);
  // const monthFrom = parseInt(dateFrom[1]);
  // const dayFrom = parseInt(dateFrom[2]);

  // months
  let months = monthTo - monthFrom;

  if (dayTo < dayFrom) {
    months = months - 1;
  }
  // years
  let years = yearTo - yearFrom;

  if (monthTo < monthFrom) {
    years = years - 1;
    months = months + 12;
  }
  // days
  const days = Math.floor(
    (dateTo.getTime() -
      new Date(yearFrom + years, monthFrom + months - 1, dayFrom).getTime()) /
      (24 * 60 * 60 * 1000),
  );

  const totalDays = Math.floor(
    (dateTo.getTime() - dateFrom.getTime()) / (24 * 60 * 60 * 1000),
  );

  return {
    years,
    months,
    days,
    totalDays,
  };
}

const getToday = () => {
  const day = new Date().toLocaleString('en-us', { weekday: 'long' });

  return day?.toLowerCase();
};

export { formatDate, formatDateToISOString, datesDiff, getToday };
