import { Card, CardTitle, Element } from '../components';
import { IconBooks } from '@tabler/icons-react';
import { Text, Group } from '@mantine/core';
import { useStyles } from '../Reports.styles';

export const Literature = ({ data }) => {
  const { classes } = useStyles();

  const { meetings, orders } = data || {};

  return (
    <Card title="Литература" Icon={IconBooks}>
      <CardTitle value={orders.qty} label="приход" />
      {orders.items?.map(({ title, qty }, index) => {
        return (
          <div key={index} className={classes.parameterWrapper}>
            <Text tt="uppercase" mb={5} className={classes.themeColor}>
              {title}
            </Text>

            <Group align="center" position="apart">
              <Element name="количество" value={qty} />
            </Group>
          </div>
        );
      })}

      <CardTitle value={meetings?.qty} label="расход" />
      {meetings.items?.map(({ title, qty }, index) => {
        return (
          <div key={index} className={classes.parameterWrapper}>
            <Text tt="uppercase" mb={5} className={classes.themeColor}>
              {title}
            </Text>

            <Group align="center" position="apart">
              <Element name="количество" value={qty} />
            </Group>
          </div>
        );
      })}
    </Card>
  );
};
