import {
  IconBook,
  IconMedal2,
  IconShoppingCart,
  IconNotes,
} from '@tabler/icons-react';

export const STATUS = { awaiting: 'awaiting', completed: 'completed' };

export const STATUS_LABELS = {
  [STATUS.awaiting]: 'Ожидается',
  [STATUS.completed]: 'Завершен',
};

export const ORDER_TYPE = {
  order: { name: 'order', label: 'Заказ' },
  correction: { name: 'correction', label: 'Коррекция' },
};

export const STORAGE_ITEM = {
  book: {
    name: 'book',
    label: 'Книга',
    Icon: IconBook,
    color: 'blue',
  },
  booklet: {
    name: 'booklet',
    label: 'Буклет',
    Icon: IconNotes,
    color: 'blue',
  },
  medal: {
    name: 'medal',
    label: 'Брелок',
    Icon: IconMedal2,
    color: 'red',
  },
  other: {
    name: 'other',
    label: 'Другое',
    Icon: IconShoppingCart,
    color: 'violet',
  },
};

export const storageItemOptions = Object.values(STORAGE_ITEM).map((item) => ({
  value: item.name,
  label: item.label,
}));
