import { Group, Text, Title, Badge, ActionIcon } from '@mantine/core';
import {
  IconPhone,
  IconMap,
  IconMapPinFilled,
  IconHome,
  IconCoins,
  IconCheck,
  IconSettings,
} from '@tabler/icons-react';
import { MapLink } from 'components/MapLink';
import { useStyles } from './MainInfo.styles';
import { CurrencyCell } from 'components/Table/cells';
import { useAuth } from 'modules/auth/use-auth';
import { useGrantAccess } from 'hooks';
import { MemberRequest } from './MemberRequest';
import { DrawerFactory } from 'core/providers/DrawerProvider';
import { EditForm } from 'modules/group/pages/Groups/form';

const Details = ({ label, Icon, children }) => {
  const { classes } = useStyles();
  const isEmpty = [null, undefined, ''].includes(label);

  return (
    <Group spacing={10} align="center" className={classes.details} noWrap>
      <Icon size={18} />
      {children || (
        <Text fz={14} className={classes.detailsText}>
          {isEmpty ? 'Нет данных' : label}
        </Text>
      )}
    </Group>
  );
};

export const MainInfo = ({ group = {} }) => {
  const { classes } = useStyles();
  const { user } = useAuth();
  const { can, canGroup } = useGrantAccess();

  const isMember = user?.groups?.find((g) => +g.groupId === +group?.id);

  const editGroupHandler = () => {
    DrawerFactory.open({
      header: 'Редактирование группы',
      component: <EditForm group={group} />,
      padding: 0,
    });
  };

  return (
    <div>
      <Group align="center" mb={15} className={classes.titleWrapper}>
        <Title className={classes.title} size="xxl">
          {group.title}
          {canGroup('update', 'group') && (
            <ActionIcon
              variant="transparent"
              color="red"
              ml={5}
              mr={10}
              className={classes.settingsIcon}
              onClick={editGroupHandler}
            >
              <IconSettings size={100} />
            </ActionIcon>
          )}
        </Title>

        <Group align="center" spacing={4} className={classes.cityIconWrapper}>
          <IconMapPinFilled />
          <Text className={classes.cityLabel}>
            {group.city || 'Нет данных'}
          </Text>
        </Group>
      </Group>

      <div className={classes.wrapper}>
        <div>
          <Details label={group.address} Icon={IconHome} />
          <Details label={group.phone} Icon={IconPhone} />
          {group.money !== undefined && (
            <Details Icon={IconCoins}>
              <CurrencyCell
                value={group.money}
                currency={group.currency}
                className={classes.detailsText}
              />
            </Details>
          )}
          {group.geoLocation && (
            <Details Icon={IconMap}>
              <MapLink geoLocation={group.geoLocation} />
            </Details>
          )}
        </div>

        <div className={classes.member}>
          {isMember && (
            <Badge ml="auto" size="lg">
              <Group align="center" spacing={5} noWrap>
                <IconCheck size={10} />
                <Text fz={10}>Мои группы</Text>
              </Group>
            </Badge>
          )}

          {!isMember && can('create', 'request') && (
            <MemberRequest user={user} />
          )}
        </div>
      </div>
    </div>
  );
};
