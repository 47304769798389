import { GLOBAL_ROLE, GROUP_ROLE } from '../roles';
import { createFullAccess } from '../utils';

export const userPermissions = [
  ...createFullAccess(GLOBAL_ROLE.admin, 'user'),

  {
    role: GROUP_ROLE.group_admin,
    resource: 'user',
    action: 'read',
  },
  {
    role: GROUP_ROLE.group_admin,
    resource: 'user',
    action: 'update',
  },
  {
    role: GROUP_ROLE.group_admin,
    resource: 'user',
    action: 'delete',
  },

  {
    role: GROUP_ROLE.group_member,
    resource: 'user',
    action: 'read',
  },
  {
    role: GROUP_ROLE.group_leader,
    resource: 'user',
    action: 'read',
  },
  {
    role: GROUP_ROLE.group_paymaster,
    resource: 'user',
    action: 'read',
  },
  {
    role: GROUP_ROLE.group_storekeeper,
    resource: 'user',
    action: 'read',
  },

  {
    role: GLOBAL_ROLE.user,
    resource: 'profile',
    action: 'update',
  },
  {
    role: GLOBAL_ROLE.admin,
    resource: 'profile',
    action: 'update',
  },
];
