import { useLocation } from 'react-router-dom';

export const useSearch = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  return Object.fromEntries(params.entries());
};

export const getQueryString = (searchParams) =>
  Object.entries(searchParams).reduce((acc, [key, value]) => {
    if (value) {
      acc = acc ? acc + `&${key}=${value}` : `${key}=${value}`;
    }

    return acc;
  }, '');
