import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { authRoutes } from 'modules/auth/routing/routes';
import { useProtectedRoutes } from './use-protected-routes';

export const ProtectedRoutes = () => {
  const availableRoutes = useProtectedRoutes();

  const router = createBrowserRouter([...availableRoutes, ...authRoutes]);

  return <RouterProvider router={router} />;
};
