import {
  DateFilterForm,
  Filter as FilterComponent,
  formatDateLabel,
} from 'components/Filter';
import { getQueryString, useSearch } from 'hooks';
import { useNavigate } from 'react-router-dom';

export const Filter = () => {
  const searchParams = useSearch();
  const navigate = useNavigate();

  const setFilter = (filter) => {
    const filterParams =
      typeof filter === 'function' ? filter(searchParams) : filter;

    const queryParams = { ...searchParams, ...filterParams };
    const queryParamsString = getQueryString(queryParams);

    navigate({ search: queryParamsString });
  };

  return (
    <FilterComponent
      initialState={{ dateFrom: '', dateTo: '' }}
      labels={{
        dateFrom: formatDateLabel('с: '),
        dateTo: formatDateLabel('до: '),
      }}
      Form={DateFilterForm}
      filter={searchParams}
      setFilter={setFilter}
      renderTotal={false}
    />
  );
};
