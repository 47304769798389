import { Text, Skeleton, Title, Box } from '@mantine/core';
import { useStyles } from './Home.styles';
import { News } from './News';
import { useNewsQuery } from './hooks/use-news-query';
import supportImg from 'assets/news/support.png';
import { JFT } from './JFT';
import { TodayGroups } from './TodayGroups';
import { useUserLocation } from 'hooks';

const message = (
  <Text color="dimmed">
    Если вы заметили ошибки или у вас есть предложение по улучшению работы
    сайта, напишите нам об этом{' '}
    <a href="mailto:support@na-portal.ru">support@na-portal.ru</a>
  </Text>
);

const testingNews = {
  eventType: 'other',
  title: 'Работа сайта. Обратная связь',
  text: message,
  date: '',
  time: '',
  group: '',
  image: { fileUrl: supportImg },
  updateDisabled: true,
};

export const Home = () => {
  const { classes } = useStyles();
  const { country, city, isLoading } = useUserLocation();

  const { data = [], isFetching } = useNewsQuery({ country, city, isLoading });

  const news = [...(data || []), testingNews];

  const shouldRenderJFT = process.env.APP_JFT_ENABLED == '1';
  const shouldRenderNews = !(isLoading || isFetching);

  return (
    <div>
      <div className={classes.container} id="main-page-container">
        <TodayGroups />

        {!shouldRenderNews && (
          <Box mb={40}>
            <Title mb={20}>Новости</Title>
            <Skeleton height={300} />
          </Box>
        )}

        {shouldRenderNews && <News data={news} mb={20} />}

        {shouldRenderJFT && <JFT />}
      </div>
    </div>
  );
};
