import { Text, Title, Image, Group, Badge, Box } from '@mantine/core';
import { useStyles } from './News.styles';
import { formatDate } from 'utils/date';
import { useGrantAccess, useMediaQuery } from 'hooks';
import { NEWS_EVENT } from './const';
import { AddButton } from 'components/ui';
import { DrawerFactory } from 'core/providers/DrawerProvider';
import { AddNewsForm, EditNewsForm } from './form';
import { IconSettingsFilled } from '@tabler/icons-react';
import { modals } from '@mantine/modals';

export const News = ({ data = [], isGroupMode = false, ...rest }) => {
  const { classes, cx } = useStyles();
  const { isMobile } = useMediaQuery();
  const { can, canGroup } = useGrantAccess();

  const canCreate = isGroupMode
    ? canGroup('create', 'news')
    : can('create', 'news');

  const addHandler = () => {
    DrawerFactory.open({
      header: 'Добавить событие',
      component: <AddNewsForm isGroupMode />,
    });
  };

  const editHandler = (item) => {
    DrawerFactory.open({
      header: 'Редактировать событие',
      component: <EditNewsForm data={item} isGroupMode />,
    });
  };

  return (
    <Box {...rest}>
      <Group position="left">
        {!isGroupMode && <Title>Новости</Title>}
        {canCreate && (
          <AddButton
            label="Добавить"
            onClick={addHandler}
            ml={isMobile ? 'auto' : 0}
          />
        )}
      </Group>

      {!data.length && (
        <Box className={classes.noDataWrapper}>
          <Text align="center" color="dimmed" fz={14}>
            Пока нет новостей...
          </Text>
        </Box>
      )}

      <div id="news-wrapper" className={classes.wrapper}>
        {data?.map((item, index) => {
          const shouldRenderBadges = isGroupMode
            ? item.date
            : item.groupTitle || item.date;

          const canUpdate = canGroup('update', 'news', item.groupId);

          let date = item.date;

          if (date) {
            date = `${date} ${item.time || ''}`.trim();
          }

          return (
            <div
              id={item.id}
              className={classes.card}
              key={index}
              onClick={() => {
                modals.open({
                  title: (
                    <Text className={classes.title} mb={0} p={15}>
                      {item.title || NEWS_EVENT[item.eventType]?.title}
                    </Text>
                  ),
                  children: (
                    <>
                      <Text
                        color="dimmed"
                        className={classes.text}
                        pl={15}
                        pr={15}
                        mb={15}
                      >
                        {item.text}
                      </Text>

                      <Image
                        src={
                          item.image?.fileUrl ||
                          NEWS_EVENT[item.eventType]?.image
                        }
                        alt="изображение"
                        fit="cover"
                        withPlaceholder
                      />
                    </>
                  ),
                  trapFocus: false,
                  withCloseButton: false,
                  radius: 'lg',
                  padding: 0,
                });
              }}
            >
              {canUpdate && !item.updateDisabled && (
                <div
                  className={classes.editIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    editHandler(item);
                  }}
                >
                  <IconSettingsFilled size={24} />
                </div>
              )}

              <Image
                src={item.image?.fileUrl || NEWS_EVENT[item.eventType]?.image}
                alt="изображение"
                height={isMobile ? 'auto' : 200}
                mb={5}
                fit="cover"
                withPlaceholder
              />

              <div className={classes.content} data-content="card-content">
                <Text className={cx(classes.title, classes.titleTruncate)}>
                  {item.title || NEWS_EVENT[item.eventType]?.title}
                </Text>

                <Text
                  color="dimmed"
                  className={cx(classes.text, classes.textTruncate)}
                  style={{ WebkitLineClamp: shouldRenderBadges ? 3 : 5 }}
                >
                  {item.text}
                </Text>

                {shouldRenderBadges && (
                  <Group
                    position={
                      item.groupTitle && !isGroupMode ? 'apart' : 'right'
                    }
                    className={classes.badgesWrapper}
                  >
                    {item.groupTitle && !isGroupMode && (
                      <Badge
                        size={isMobile ? 'md' : 'lg'}
                        className={classes.badge}
                      >
                        {item.groupTitle}
                      </Badge>
                    )}
                    {item.date && (
                      <Badge
                        size={isMobile ? 'md' : 'lg'}
                        className={classes.badge}
                        color="pink"
                      >
                        {formatDate({
                          value: date,
                          withTime: !!item.time,
                          monthFormat: 'long',
                          yearFormat: 'none',
                        })}
                      </Badge>
                    )}
                  </Group>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </Box>
  );
};
