import {
  IconFileTypeJpg,
  IconFileTypeDoc,
  IconFileTypePng,
  IconFileTypePdf,
  IconFileTypeXls,
} from '@tabler/icons-react';

export const FILE_TYPES = {
  jpg: {
    icon: IconFileTypeJpg,
    color: 'orange',
  },
  jpeg: {
    icon: IconFileTypeJpg,
    color: 'orange',
  },
  png: {
    icon: IconFileTypePng,
    color: 'orange',
  },
  doc: {
    icon: IconFileTypeDoc,
    color: 'blue',
  },
  docx: {
    icon: IconFileTypeDoc,
    color: 'blue',
  },
  xls: {
    icon: IconFileTypeXls,
    color: 'green',
  },
  xlsx: {
    icon: IconFileTypeXls,
    color: 'green',
  },
  pdf: {
    icon: IconFileTypePdf,
    color: 'red',
  },
};
