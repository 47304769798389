import { TextInput, ActionIcon } from '@mantine/core';
import { IconSearch, IconX } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { getQueryString, useSearch } from 'hooks';
import { getHotkeyHandler } from '@mantine/hooks';

export const SearchInput = (props) => {
  const navigate = useNavigate();
  const searchParams = useSearch();
  const [value, setValue] = useState(searchParams.searchPhrase || '');

  const searchHandler = () => {
    const queryParams = { ...searchParams, searchPhrase: value };
    const queryParamsString = getQueryString(queryParams);

    navigate({ search: queryParamsString });
  };

  const resetHandler = () => {
    setValue('');

    navigate({ search: '' });
  };

  return (
    <TextInput
      placeholder="Поиск..."
      value={value}
      onChange={(e) => setValue(e.currentTarget.value)}
      onKeyDown={getHotkeyHandler([['Enter', searchHandler]])}
      onBlur={searchHandler}
      styles={{ input: { minHeight: 20 } }}
      icon={<IconSearch size={18} />}
      rightSection={
        <ActionIcon
          variant="transparent"
          size={18}
          onClick={resetHandler}
          disabled={!value}
        >
          <IconX />
        </ActionIcon>
      }
      {...props}
    />
  );
};
