import { columnsConfig } from './columns-config';
import { Table } from 'components/Table';
import { DrawerFactory } from 'core/providers/DrawerProvider';
import { AddForm, EditForm } from './form';
import { useGroup } from 'modules/group/context';
import { openConfirmModal } from 'components/ui/Modal';
import { showSuccess } from 'core/notifications';
import {
  useDeleteOrder,
  useCompleteOrder,
} from 'modules/group/hooks/use-order-mutation';
import { STATUS } from '../const';
import { MobileRender } from './MobileRender';
import { ExpandableRowRender } from './ExpandableRowRender';
import { filter } from './filter';
import { formatDate } from 'utils/date';
import { TitleRender } from './TitleRender';

export const Orders = () => {
  const group = useGroup();

  const deleteOrderMutation = useDeleteOrder();
  const completeOrderMutation = useCompleteOrder();

  const addHandler = () => {
    DrawerFactory.open({
      header: 'Новый заказ',
      component: <AddForm store={group?.store} groupId={group.id} />,
    });
  };

  const editHandler = (order) => {
    DrawerFactory.open({
      header: `Заказ от ${formatDate({ value: order.date, withTime: false })}`,
      component: <EditForm store={group?.store} order={order} />,
    });
  };

  const deleteHandler = (id) => {
    openConfirmModal({
      title: 'Удалить заказ?',
      children: 'Сумма заказа будет возвращена обртано в бюджет',
      onConfirm: () => {
        deleteOrderMutation.mutate(id, {
          onSuccess: () => {
            showSuccess({ title: 'Заказ удален' });
          },
        });
      },
    });
  };

  const completeHandler = (order) => {
    openConfirmModal({
      title: 'Завершить заказ?',
      children:
        'Позиции заказа будут добавлены на склад. Заказ будет завершен без дальнейшей возможности редактирования',
      onConfirm: () => {
        completeOrderMutation.mutate(
          { ...order, status: STATUS.completed },
          {
            onSuccess: () => {
              showSuccess({ title: 'Заказ завершен' });
            },
          },
        );
      },
    });
  };

  return (
    <>
      <Table
        resource="order"
        columns={columnsConfig}
        resourceEndpoint={`/order/${group?.id}`}
        tableKey={`orders-${group?.id}`}
        onAdd={addHandler}
        onView={false}
        onEdit={editHandler}
        onDelete={({ id }) => deleteHandler(id)}
        onComplete={completeHandler}
        noPadding={true}
        titleRender={() => (
          <TitleRender group={group} addHandler={addHandler} />
        )}
        expandableRowRender={(row) => <ExpandableRowRender row={row} />}
        mobileRender={(row) => <MobileRender row={row} />}
        disableActions={(row) => {
          return row.status === 'completed'
            ? ['edit', 'delete', 'complete']
            : [];
        }}
        filter={filter}
      />
    </>
  );
};
