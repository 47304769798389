import { useEffect } from 'react';
import { Group, SegmentedControl, Box } from '@mantine/core';
import { MeetingForm } from './MeetingForm';
import { Summary } from './Summary';
import { useGrantAccess, useMediaQuery } from 'hooks';
import { useParams, useNavigate, Route, Routes } from 'react-router-dom';

export const Meetings = () => {
  const { canGroup } = useGrantAccess();
  const canUpdate = canGroup('update', 'meeting');
  const { isSmallDevice } = useMediaQuery();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!params.subSectionId) {
      if (canUpdate) {
        navigate(`/groups/${params.groupId}/meetings/online`);
      } else {
        navigate(`/groups/${params.groupId}/meetings/summary`);
      }
    }
  }, []);

  useEffect(() => {
    if (!canUpdate && params.subSectionId === 'online') {
      navigate(`/groups/${params.groupId}/meetings/summary`);
    }
  }, [params.subSectionId]);

  return (
    <Box mt={isSmallDevice ? 16 : 30}>
      <Group align="center" position="center" mb={20}>
        <SegmentedControl
          value={params.subSectionId === 'online' ? 'online' : 'summary'}
          onChange={() => {
            if (params.subSectionId === 'online') {
              navigate(`/groups/${params.groupId}/meetings/summary`);
            } else {
              navigate(`/groups/${params.groupId}/meetings/online`);
            }
          }}
          color="dark"
          radius={6}
          data={[
            {
              label: 'Тетрадь ведущего',
              value: 'online',
              disabled: !canUpdate,
            },
            { label: 'Отчеты', value: 'summary' },
          ]}
        />
      </Group>

      <Routes>
        {canUpdate && <Route path="online" element={<MeetingForm />} />}
        <Route path="summary" element={<Summary />} />
      </Routes>
    </Box>
  );
};
