import { httpClient } from 'core/providers/HttpClientProvider';

const multipartHeaders = { 'Content-Type': 'multipart/form-data' };

export const getNews = async ({ country, city }) => {
  const { data } = await httpClient.get('/news', {
    params: {
      country,
      city,
    },
  });

  return data;
};

export const getGroupNews = async (groupId) => {
  const { data } = await httpClient.get(`/news/${groupId}`);

  return data;
};

export const addNews = async (news) => {
  const { data } = await httpClient.post('/news', news, {
    headers: multipartHeaders,
  });

  return data;
};

export const addGroupNews = async (news) => {
  console.log('addGroupNews');

  const { data } = await httpClient.post(`/news/${news.groupId}`, news, {
    headers: multipartHeaders,
  });

  return data;
};

export const deleteNews = async (news) => {
  const { data } = await httpClient.delete(`/news/${news.id}`);

  return data;
};

export const deleteGroupNews = async (news) => {
  const { data } = await httpClient.delete(`/news/${news.groupId}/${news.id}`);

  return data;
};

export const updateNews = async ({ id, news }) => {
  const { data } = await httpClient.put(`/news/${id}`, news, {
    headers: multipartHeaders,
  });

  return data;
};

export const updateGroupNews = async ({ id, news }) => {
  const { data } = await httpClient.put(`/news/${news.groupId}/${id}`, news, {
    headers: multipartHeaders,
  });

  return data;
};

export const getJFT = async (date) => {
  const { data } = await httpClient.get(`/jft/${date}`);

  return data;
};

export const getGroupsByDay = async ({ country, city, day }) => {
  const { data } = await httpClient.get('/group/day', {
    params: {
      country,
      city,
      day,
    },
  });

  return data;
};
