import { createStyles, rem } from '@mantine/core';
import { isDark, whiteBlack } from 'core/theme/utils';

export const useStyles = createStyles((theme) => ({
  card: {
    textAlign: 'center',
    width: rem(300),
    padding: rem(24),
    backgroundColor: whiteBlack(theme),
    boxShadow: theme.other.shadow,

    color: theme.black,
    borderRadius: rem(8),

    [theme.fn.smallerThan('xs')]: { width: '100%' },
  },
  time: {
    color: theme.colors.pink[6],

    fontSize: rem(28),
    lineHeight: 1,
    fontWeight: 700,
    fontFamily: 'Greycliff CF',
    marginBottom: rem(16),
  },
  day: {
    color: isDark(theme) ? theme.white : theme.colors.gray[9],
    textTransform: 'uppercase',
    fontSize: rem(14),
    fontWeight: 700,
    marginBottom: rem(5),
  },
  subject: {
    color: isDark(theme) ? theme.colors.blue[4] : theme.colors.blue[7],
    marginBottom: rem(5),
  },
  description: {
    color: isDark(theme) ? theme.white : theme.colors.gray[9],
    fontSize: rem(14),
    cursor: 'pointer',
  },
  truncate: {
    // 'display': '-webkit-box',
    // '-webkitLineClamp': '1',
    // '-webkitBoxOrient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  divider: {
    width: '100%',
    height: rem(1),
    // backgroundColor: theme.colors.gray[2],
    backgroundColor: isDark(theme)
      ? theme.colors.dark[4]
      : theme.colors.gray[2],
    margin: `${rem(10)} ${rem(0)}`,
  },
  formCard: {
    position: 'relative',
    padding: rem(24),
    backgroundColor: whiteBlack(theme),
    // boxShadow: theme.other.shadow,
    borderRadius: rem(8),
    border: `1px solid ${theme.colors.dark[0]}`,
  },
}));
