import { Text } from '@mantine/core';

export const FileSize = ({ size, ...rest }) => {
  const units = ['Б', 'Кб', 'Мб', 'ГБ'];
  let i = 0;

  while (size > 1024) {
    size = size / 1024;
    i++;
  }

  const label = i === 0 ? 0 : `${size.toFixed(1)} ${units[i]}`;

  return <Text {...rest}>{label}</Text>;
};
