import { Box, rem } from '@mantine/core';

const getColor = (theme, status) => {
  switch (status) {
    case 'active':
    case 'completed':
      return theme.colors.teal[6];
    case 'disabled':
    case 'danger':
      return theme.colors.red[6];
    case 'inactive':
      return theme.colors.gray[4];
    default:
      return theme.colors.yellow[6];
  }
};

export const StatusIndicator = ({ status }) => {
  if (!status) {
    return null;
  }

  return (
    <Box
      className="status-indicator"
      sx={(theme) => ({
        position: 'absolute',
        height: '100%',
        top: 0,
        left: 0,
        backgroundColor: getColor(theme, status),
        width: rem(6),
      })}
    ></Box>
  );
};
