import { ORDER_TYPE, STATUS_LABELS } from '../../const';
import { FilterForm } from './FilterForm';
import { formatDateLabel } from 'components/Filter';

export const filter = {
  initialState: {
    dateFrom: '',
    dateTo: '',
    orderType: '',
    status: '',
  },
  labels: {
    dateFrom: formatDateLabel('с: '),
    dateTo: formatDateLabel('до: '),
    orderType: (value) => ORDER_TYPE[value].label,
    status: (value) => STATUS_LABELS[value],
  },
  Form: FilterForm,
};
