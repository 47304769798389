import { isDark } from './utils';
import { rem } from '@mantine/core';

export const baseTheme = {
  fontFamily: 'Verdana, sans-serif',
  fontFamilyMonospace: 'Monaco, Courier, monospace',
  headings: { fontFamily: 'Greycliff CF, sans-serif' },
  cursorType: 'pointer',
  defaultRadius: 6,
  components: {
    Input: { styles: () => ({ input: { minHeight: rem(45) } }) },
    PasswordInput: { styles: () => ({ innerInput: { height: rem(45) } }) },
    MultiSelect: { styles: () => ({ values: { minHeight: rem(45) } }) },
    Drawer: {
      styles: (theme) => ({
        overlay: { zIndex: theme.other.zIndex.drawer },
        inner: { zIndex: theme.other.zIndex.drawer },
      }),
    },
    Title: {
      styles: (theme) => ({
        root: {
          color: isDark(theme) ? theme.white : theme.black,
          [theme.fn.smallerThan('md')]: { fontSize: '1.5rem' },
        },
      }),
      sizes: { xxl: () => ({ root: { fontSize: '2.5rem' } }) },
    },
    SegmentedControl: {
      styles: (theme) => ({
        root: {
          backgroundColor: isDark(theme) ? theme.black : theme.white,
        },
      }),
    },
  },
  other: {
    container: { maxWidth: 1200 },
    aside: { width: 250 },
    header: { height: 60 },
    table: {
      px: [16, 30],
      py: [16, 30],
      padding: 30,
      mobilePadding: 16,
    },
    zIndex: {
      header: 999,
      drawer: 1000,
      modal: 1001,
      notification: 1002,
    },
    shadow: '0 4px 10px rgba(0, 0, 0, 0.05)',
  },
  globalStyles: (theme) => {
    return {
      '*, *::before, *::after': { boxSizing: 'border-box' },

      'body': {
        ...theme.fn.fontStyles(),
        minWidth: rem(350),
        margin: 0,
        backgroundColor:
          theme.colorScheme === 'dark'
            ? theme.colors.dark[6]
            : theme.colors.gray[0],
        color:
          theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
        lineHeight: theme.lineHeight,
      },

      '.full-screen-fixed': {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1100,
      },
    };
  },
};
