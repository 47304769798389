import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { setActive } from './SpinnerProvider';

const client = new QueryClient({
  // defaultOptions: {
  //   mutations: {
  //     onMutate: () => {
  //       setActive(true);
  //     },
  //     onSettled: (data, error, variables, context) => {
  //       setActive(false);
  //     },
  //   },
  // },
});

export const QueryProvider = ({ children }) => {
  return <QueryClientProvider client={client}>{children}</QueryClientProvider>;
};
