import { BaseForm } from './BaseForm';
import { useEditStoreItem } from 'modules/group/hooks/use-store-mutation';
import { initialFormState } from './schema';
import { useForm, FormProvider } from 'components/ui/form';
import { DrawerFactory } from 'core/providers/DrawerProvider';

export const EditForm = ({ data }) => {
  const formState = { ...initialFormState, initialValues: data };

  const form = useForm(formState);

  const editMutation = useEditStoreItem();

  const submitHandler = (values) => {
    editMutation.mutate(values, {
      onSuccess: () => {
        form.reset();
        DrawerFactory.close();
      },
    });
  };

  return (
    <FormProvider form={form}>
      <BaseForm onSubmit={submitHandler} />
    </FormProvider>
  );
};
