import { Checkbox } from '@mantine/core';
import { useFormContext } from './context';

export const CheckboxField = ({
  name,
  label,
  onChange: onChangeFromProps,
  ...rest
}) => {
  const form = useFormContext();
  const checkboxProps = form.getInputProps(name);

  let value = checkboxProps.value;

  if (value === 'true' || value === '1' || value === true) {
    value = true;
  } else {
    value = false;
  }

  return (
    <Checkbox
      size="xs"
      {...checkboxProps}
      checked={checkboxProps.value}
      label={label}
      mt={15}
      mb={5}
      onChange={(value) => {
        checkboxProps.onChange(value);

        if (typeof onChangeFromProps === 'function') {
          onChangeFromProps(value);
        }
      }}
      {...rest}
    />
  );
};
