import { useNavigate } from 'react-router-dom';
import { Button } from '@mantine/core';

export const NavButton = ({ to = '/', children, ...props }) => {
  const navigate = useNavigate();

  return (
    <Button
      {...props}
      onClick={() => {
        navigate(to);
      }}
    >
      {children}
    </Button>
  );
};
