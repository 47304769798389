import { useTableQuery } from './use-table-query';
import { useSearch } from 'hooks';
// import { NoSearchResults } from './NoSearchResults';
import { TableError } from './Table.Error';
import { TableRender } from './TableRender';
import { Spinner } from 'components/ui/Spinner';

export const Table = ({ resourceEndpoint, tableKey, ...tableProps }) => {
  const searchParams = useSearch();

  const {
    data: response,
    isFetching,
    isLoading,
    isError,
  } = useTableQuery({
    resourceEndpoint,
    tableKey,
    ...searchParams,
  });

  if (isLoading) {
    return <Spinner fullscreen />;
  }

  if (isError) {
    return <TableError />;
  }

  // if (!response?.data?.length && Object.keys(searchParams).length) {
  //   return <NoSearchResults />;
  // }

  const { currentPage, size, lastPage, total } = response;

  return (
    <>
      {isFetching && <Spinner fullscreen />}
      <TableRender
        pagination={{
          currentPage,
          size,
          lastPage,
          total,
        }}
        data={response?.data}
        {...tableProps}
      />
    </>
  );
};
