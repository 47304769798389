import { UpdatedCell, CurrencyCell } from 'components/Table/cells';
import {
  FINANCE_OPERATION_TYPE,
  FINANCE_SOURCE_TYPE,
} from 'modules/group/const';

export const columnsConfig = [
  {
    title: 'Дата',
    name: 'updatedAt',
    maxWidth: 200,
    width: 200,
    truncated: true,
    render: (row) => <UpdatedCell row={row} />,
  },
  {
    title: 'Тип',
    name: 'operationType',
    maxWidth: 120,
    width: 120,
    render: (row) => FINANCE_OPERATION_TYPE[row.operationType].label,
  },
  {
    title: 'Категория',
    name: 'sourceType',
    maxWidth: 150,
    width: 200,
    render: (row) => FINANCE_SOURCE_TYPE[row.sourceType].label,
  },
  {
    title: 'Сумма',
    name: 'amount',
    maxWidth: 100,
    render: (row) => <CurrencyCell value={row.amount} />,
  },
];
