import { createStyles, rem } from '@mantine/core';
import { isDark, whiteBlack } from 'core/theme/utils';

// const scrollStyles = {
//   '::-webkit-scrollbar': {
//     width: '3px',
//     height: '3px',
//   },
//
//   '::-webkit-scrollbar-track': {
//     background: '#f1f1f1',
//     borderRadius: '8px',
//   },
//
//   '::-webkit-scrollbar-thumb': {
//     background: 'lightgray',
//     borderRadius: '8px',
//   },
// };

export const useStyles = createStyles((theme) => ({
  wrapper: {
    'position': 'relative',
    'display': 'flex',
    'gap': rem(25),
    'flexWrap': 'nowrap',
    'flexDirection': 'row',
    'overflow': 'auto',
    'padding': '20px 5px',
    'alignItems': 'stretch',

    '::-webkit-scrollbar': { display: 'none' },

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
      maxWidth: '100%',
      alignItems: 'center',
    },
  },
  editIcon: {
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'position': 'absolute',
    'right': '10px',
    'top': '10px',
    'zIndex': 1,
    'filter': 'drop-shadow( 0px 0px 4px rgba(0, 0, 0, .5))',
    'color': 'white',

    'transition': 'all 0.1s',
    'cursor': 'pointer',
    ':hover': { transform: 'rotate(90deg) scale(1.1)' },
  },
  card: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    fontSize: rem(13),
    backgroundColor: whiteBlack(theme),
    // border: `1px solid ${
    //   isDark(theme) ? theme.colors.gray[9] : theme.colors.gray[2]
    // }`,

    boxShadow: theme.other.shadow,
    position: 'relative',
    borderRadius: rem(8),
    overflow: 'hidden',
    width: rem(400),
    flexShrink: 0,

    [theme.fn.smallerThan('xs')]: {
      // maxWidth: '100%',
      // flexBasis: '100%',
      width: '100%',
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: rem(15),
    flexGrow: 1,
  },
  title: {
    fontSize: '16px',
    fontWeight: '700',
    textTransform: 'uppercase',
    marginBottom: rem(10),

    color: isDark(theme) ? theme.white : theme.colors.dark[9],
  },
  titleTruncate: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
    overflow: 'hidden',
  },
  text: { fontSize: '14px', whiteSpace: 'pre-line' },
  textTruncate: {
    // whiteSpace: 'normal',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '3',
    overflow: 'hidden',
  },
  badgesWrapper: {
    marginTop: rem(20),
    gap: rem(6),
    alignItems: 'flex-end',
    flexGrow: 1,
  },
  badge: { '> span': { textTransform: 'none' } },
  noDataWrapper: {
    padding: rem(16),
    backgroundColor: whiteBlack(theme),
    boxShadow: theme.other.shadow,
    borderRadius: rem(8),
    marginTop: rem(20),
  },
}));
