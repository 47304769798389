import { AddButton } from 'components/ui';
import { columnsConfig } from './columns-config';
import { DrawerFactory } from 'core/providers/DrawerProvider';
import { EditForm, AddForm } from './form';
import { useDeleteJob } from 'modules/group/hooks/use-job-mutation';
import { TableRender } from 'components/Table/TableRender';
import { useGroup } from 'modules/group/context';
import { useGrantAccess } from 'hooks';
import { MobileRender } from './MobileRender';
import { sortIncludingWeekDay } from 'utils/string';
import { Filter, filterInitialState } from './Filter';
import { useState } from 'react';

export const Settings = () => {
  const [filter, setFilter] = useState(filterInitialState);

  const deleteMutation = useDeleteJob();
  const { canGroup } = useGrantAccess();

  const group = useGroup();

  const addHandler = () => {
    DrawerFactory.open({ header: 'Добавить служение', component: <AddForm /> });
  };

  const editHandler = (job) => {
    DrawerFactory.open({
      header: job.title || 'Редактирование',
      component: <EditForm data={job} />,
    });
  };

  const jobs = sortIncludingWeekDay({ array: group?.jobs, name: 'title' });

  const filteredJobs = jobs.filter((job) => {
    const isDayMatch = filter.day ? job.day === filter.day : true;
    const isJobMatch = filter.job ? job.title === filter.job : true;

    return isDayMatch && isJobMatch;
  });

  return (
    <div>
      <TableRender
        resource="job"
        noPadding
        data={filteredJobs}
        columns={columnsConfig}
        onView={false}
        onAdd={addHandler}
        onEdit={editHandler}
        onDelete={(row) => {
          deleteMutation.mutate(row.id);
        }}
        titleRender={() => (
          <>
            {canGroup('create', 'job') && (
              <AddButton mb={20} onClick={addHandler} />
            )}

            {!!jobs && (
              <Filter
                filter={filter}
                setFilter={setFilter}
                total={filteredJobs.length}
              />
            )}
          </>
        )}
        mobileRender={(row) => <MobileRender row={row} />}
      />
    </div>
  );
};
