import { SearchInput } from 'components/ui/form';
import { AddButton, TotalWithBg } from 'components/ui';
import { useGrantAccess, useMediaQuery, useSearch } from 'hooks';
import { Group } from '@mantine/core';

export const TitleRender = ({ onAdd, totalRecords }) => {
  const { canGroup } = useGrantAccess();
  const { isMobile } = useMediaQuery();
  const { searchPhrase } = useSearch();

  if (isMobile) {
    return (
      <>
        {canGroup('update', 'user') && (
          <AddButton label="Добавить" onClick={onAdd} mb={20} />
        )}

        <Group spacing={10} align="center" mb={20} noWrap>
          <TotalWithBg total={totalRecords} />
          {(totalRecords || searchPhrase) && (
            <SearchInput
              placeholder="Введите имя или email"
              w="100%"
              height={32}
            />
          )}
        </Group>
      </>
    );
  }

  return (
    <Group spacing={10} align="center" mb={20}>
      <TotalWithBg total={totalRecords} />
      {(totalRecords || searchPhrase) && (
        <SearchInput
          placeholder="Введите имя или email"
          w={'auto'}
          height={32}
        />
      )}

      {canGroup('update', 'user') && (
        <AddButton label="Добавить" onClick={onAdd} />
      )}
    </Group>
  );
};
