import { createStyles, rem } from '@mantine/core';

export const useStyles = createStyles((theme, { size, color }) => {
  size = size || 10;
  color = color || theme.colors.blue[6];
  const fontSize = Math.max(size / 2, 10);

  return {
    parent: {
      position: 'relative',
      backgroundColor: color,
      width: rem(size),
      height: rem(size),
      borderRadius: '50%',
      padding: rem(12),
    },
    child: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: theme.white,
      fontSize: rem(fontSize),
      fontWeight: 700,
    },
  };
});
