import { Button } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useMediaQuery } from 'hooks';

export const AddButton = ({ onClick, label = 'Создать', ...rest }) => {
  const { isMobile } = useMediaQuery();

  return (
    <Button
      size="xs"
      onClick={onClick}
      ml="auto"
      display="block"
      color="teal"
      leftIcon={<IconPlus size={16} />}
      h={isMobile ? 26 : 30}
      {...rest}
    >
      {label}
    </Button>
  );
};
