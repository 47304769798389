import { object, string, number } from 'yup';
import { yupResolver } from '@mantine/form';
import { messages } from 'utils/messages';

export const storeSchema = object({
  title: string().required(messages.required),
  type: string().required(messages.required),
  priceIn: number()
    .transform((c, o) => (o === '' ? null : c))
    .required(messages.required)
    .min(0),
  priceOut: number()
    .transform((c, o) => (o === '' ? null : c))
    .required(messages.required)
    .min(0),
  description: string(),
  qty: number()
    .transform((c, o) => (o === '' ? null : c))
    .required(messages.required)
    .min(0)
    .max(1000, 'Макс. значение 1000'),
});

export const initialFormState = {
  initialValues: {
    title: '',
    type: '',
    priceIn: 0,
    priceOut: 0,
    description: '',
    qty: 0,
  },
  validate: yupResolver(storeSchema),
  validateInputOnBlur: true,
};
