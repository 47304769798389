import { useNavigate } from 'react-router-dom';
import { Pagination as PaginationComponent } from '@mantine/core';
import { useSearch, getQueryString } from 'hooks';

export const Pagination = ({ currentPage, size, lastPage }) => {
  const searchParams = useSearch();

  const navigate = useNavigate();
  const renderPagination = lastPage > 1;

  if (!renderPagination) {
    return null;
  }

  const setPage = (page) => {
    const queryParams = { ...searchParams, page, size };
    const queryParamsString = getQueryString(queryParams);

    navigate({ search: `?${queryParamsString}` });
  };

  return (
    <PaginationComponent
      // size="sm"
      spacing={5}
      noWrap
      mb={20}
      mt={30}
      position="center"
      value={currentPage}
      onChange={setPage}
      total={lastPage}
    />
  );
};
