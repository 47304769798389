export const WEEK_DAYS = {
  monday: 'Понедельник',
  tuesday: 'Вторник',
  wednesday: 'Среда',
  thursday: 'Четверг',
  friday: 'Пятница',
  saturday: 'Суббота',
  sunday: 'Воскресенье',
};

export const MONTH_NAMES = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
];

export const countries = {
  russia: {
    name: 'russia',
    title: 'Россия',
    cities: ['Москва', 'Видное', 'Волгоград'],
  },
  uzbekistan: {
    name: 'uzbekistan',
    title: 'Узбекистан',
    cities: ['Ташкент'],
  },
};

export const countryOptions = Object.values(countries).map((country) => ({
  value: country.title,
  label: country.title,
}));
