import { Component } from 'react';

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.warn('ErrorBoundary componentDidCatch', error, info);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.hasError && prevProps.hasError) {
      this.setState({ hasError: false });
    }
  }

  renderDefaultFallback() {
    return <h1>ErrorBoundary default fallback render</h1>;
  }

  resetError() {
    this.setState({ hasError: false });
  }

  render() {
    const { fallback: FallBack } = this.props;

    if (this.state.hasError) {
      if (typeof FallBack === 'function') {
        return <FallBack resetError={this.resetError.bind(this)} />;
      }

      return this.renderDefaultFallback();
    }

    return this.props.children;
  }
}
