import { useStyles } from '../Reports.styles';
import { formatNumber } from 'utils/string';
import { Text, Grid } from '@mantine/core';
import { useMediaQuery } from 'hooks';

const isEmpty = (value) => [undefined, null].includes(value);

const getLength = (...args) => {
  let string = '';

  args.forEach((arg) => {
    if (!isEmpty(arg)) {
      if (!Number.isInteger(arg)) {
        arg = arg.toFixed(1);
      }

      string += arg;
    }
  });

  return string.length;
};

export const Element = ({ name, value, align = 'left' }) => {
  const { classes } = useStyles();

  if (value && !Number.isInteger(value)) {
    value = value.toFixed(2);
  }

  return (
    <Text align={align}>
      <div className={classes.parameterName}>{name}</div>
      <div className={classes.parameterValue}>{formatNumber(value)}</div>
    </Text>
  );
};

export const Parameter = ({
  name,
  total,
  average,
  count,
  countColumnLabel = 'операции',
}) => {
  const { classes } = useStyles();
  const { screenWidth } = useMediaQuery();

  const shouldRenderColumn =
    (screenWidth < 400 || screenWidth > 768) &&
    getLength(total, average, count) > 23;
  const span = shouldRenderColumn ? 12 : 4;

  return (
    <Grid grow className={classes.parameterWrapper} gutter={0}>
      <Grid.Col span={span}>
        <Element name={name} value={total} />
      </Grid.Col>

      {!isEmpty(count) && (
        <Grid.Col span={span}>
          <Element
            name={countColumnLabel}
            value={count}
            align={
              shouldRenderColumn
                ? 'left'
                : isEmpty(average)
                ? 'right'
                : 'center'
            }
          />
        </Grid.Col>
      )}

      {!isEmpty(average) && (
        <Grid.Col span={span}>
          <Element
            name="в среднем"
            value={average}
            align={shouldRenderColumn ? 'left' : 'right'}
          />
        </Grid.Col>
      )}
    </Grid>
  );

  // return (
  //   <Group align="center" position="apart" className={classes.parameterWrapper}>
  //     <Element name={name} value={total} />
  //     {count !== undefined && (
  //       <Element name={countColumnLabel} value={count} align="center" />
  //     )}
  //     {average !== undefined && (
  //       <Element name="в среднем" value={average} align="right" />
  //     )}
  //   </Group>
  // );
};
