import { boolean, object, string } from 'yup';
import { yupResolver } from '@mantine/form';
import { messages } from 'utils/messages';
import { dateEvents, groupEvents } from '../const';

export const newsSchema = object({
  country: string().required(messages.required),
  city: string().required(messages.required),
  eventType: string().required(messages.required),
  date: string().when('eventType', ([eventType], schema) => {
    return dateEvents.includes(eventType)
      ? schema.required(messages.required)
      : schema.nullable();
  }),
  time: string(),
  title: string().required(messages.required),
  text: string().required(messages.required),
  onlyForGroup: boolean(),
  groupId: string().when(
    ['onlyForGroup', 'eventType'],
    ([onlyForGroup, eventType], schema) => {
      if (onlyForGroup) {
        return schema.required(messages.required);
      }

      if (groupEvents.includes(eventType)) {
        return schema.required(messages.required);
      }

      return schema.nullable();
    },
  ),
  image: object({ fileName: string(), fileUrl: string() }),
});

export const initialFormState = {
  initialValues: {
    country: '',
    city: '',
    date: '',
    time: '',
    title: '',
    text: '',
    eventType: '',
    groupId: '',
    onlyForGroup: false,
    image: { fileName: '', fileUrl: '' },
  },
  validate: yupResolver(newsSchema),
  validateInputOnBlur: true,
};
