import {
  Button,
  Paper,
  PasswordInput,
  Text,
  TextInput,
  Title,
  Select,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { object, string } from 'yup';
import { useSignupMutation } from '../hooks/use-auth-mutation';
import { Link } from 'components/ui';
import { authLinks } from '../routing/routes';
import { showSuccess } from 'core/notifications';
import { useNavigate } from 'react-router-dom';
import { BackToMain } from '../components/BackToMain';
import { messages } from 'utils/messages';
import { getHotkeyHandler } from '@mantine/hooks';
import { countryOptions } from 'core/const';
import { createCityOptions } from 'utils/create-city-options';

const signupSchema = object().shape({
  firstName: string().required(messages.required),
  email: string().required(messages.required).email(messages.email),
  password: string().required(messages.required).min(8, 'Минимум 8 символов'),
  passwordConfirm: string()
    .required(messages.required)
    .when('password', ([password], schema) => {
      return schema.test('passwordConfirm', 'Пароли не совпадают', (value) => {
        return password === value;
      });
    }),
  country: string().required(messages.required),
  city: string().required(messages.required),
});

const initialState = {
  initialValues: {
    firstName: '',
    email: '',
    password: '',
    passwordConfirm: '',
    country: '',
    city: '',
  },
  validate: yupResolver(signupSchema),
  validateInputOnBlur: true,
};

export const SignUp = () => {
  const { mutate, isLoading } = useSignupMutation();
  const form = useForm(initialState);
  const canSubmit = form.isValid() && form.isDirty();

  const cityOptions = createCityOptions(form.values.country);

  const countryFieldProps = form.getInputProps('country');

  const navigate = useNavigate();

  const submitHandler = () => {
    mutate(form.values, {
      onSuccess: () => {
        showSuccess({
          title: 'Подтверждение регистрации',
          message: (
            <div>
              <Text>На ваш email отправлено письмо.</Text>
              <Text>Проверьте пожалуйста вашу почту.</Text>
            </div>
          ),
          autoClose: 15000,
        });
        navigate(authLinks.login);
      },
    });
  };

  return (
    <div>
      <Title align="center" sx={() => ({ fontWeight: 900 })}>
        Регистрация
      </Title>
      <Text color="dimmed" size="sm" align="center" mt={5}>
        Вы уже с нами?{' '}
        <Link to={authLinks.login} size="sm">
          Войти
        </Link>
      </Text>

      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <TextInput label="Имя" {...form.getInputProps('firstName')} required />
        <TextInput
          mt="md"
          label="Email"
          placeholder="you@email.com"
          required
          {...form.getInputProps('email')}
        />
        <PasswordInput
          label="Пароль"
          placeholder="********"
          required
          mt="md"
          {...form.getInputProps('password')}
        />
        <PasswordInput
          label="Повторить пароль"
          placeholder="********"
          required
          mt="md"
          {...form.getInputProps('passwordConfirm')}
          onKeyDown={getHotkeyHandler([['Enter', submitHandler]])}
        />

        <Select
          {...countryFieldProps}
          mt="md"
          label="Страна"
          data={countryOptions}
          required
          onChange={(value) => {
            form.setFieldValue('city', '');
            countryFieldProps.onChange(value);
            // form.setFieldValue('useManualLocation', true);
          }}
          // description="Используется для отображения актуальных новостей и событий в вашем регионе"
        />

        <Select
          {...form.getInputProps('city')}
          mt="md"
          label="Город"
          required
          data={cityOptions}
          // description="Используется для отображения актуальных новостей и событий в вашем городе"
        />

        <Button
          fullWidth
          mt="xl"
          disabled={!canSubmit}
          loading={isLoading}
          onClick={submitHandler}
        >
          Зарегистрироваться
        </Button>

        <BackToMain />
      </Paper>
    </div>
  );
};
