export const GLOBAL_ROLE = {
  admin: 'admin',
  user: 'user',
  guest: 'guest',
  newsMaker: 'newsMaker',
};

export const GROUP_ROLE = {
  group_admin: 'group_admin',
  group_storekeeper: 'group_storekeeper',
  group_member: 'group_member',
  group_leader: 'group_leader',
  group_paymaster: 'group_paymaster',
};

export const ROLE_LABELS = {
  [GLOBAL_ROLE.admin]: 'Администратор',
  [GLOBAL_ROLE.user]: 'Пользователь',
  [GLOBAL_ROLE.newsMaker]: 'Модератор - новости',
  [GROUP_ROLE.group_admin]: 'Модератор',
  [GROUP_ROLE.group_leader]: 'Собрания',
  [GROUP_ROLE.group_storekeeper]: 'Склад',
  [GROUP_ROLE.group_paymaster]: 'Финансы',
  [GROUP_ROLE.group_member]: 'Участник',
};

export const globalRoleOptions = Object.values(GLOBAL_ROLE)
  .filter((role) => role !== 'guest')
  .map((role) => ({ value: role, label: ROLE_LABELS[role] }));

export const groupRoleOptions = Object.keys(GROUP_ROLE).map((role) => ({
  value: role,
  label: ROLE_LABELS[role],
}));
