import { useForm, FormProvider } from 'components/ui/form';
import { DrawerFactory } from 'core/providers/DrawerProvider';
import { Stack, Button, Group } from '@mantine/core';
import { formatDateToISOString } from 'utils/date';

export const FormWrapper = ({ setFilter, filter, initialState, children }) => {
  const form = useForm({ initialValues: { ...initialState, ...filter } });

  return (
    <FormProvider form={form}>
      <Stack spacing={5} mb={20}>
        {children}
      </Stack>

      <Group spacing={10} mt={40}>
        <Button
          style={{ flexGrow: 1 }}
          size="sm"
          onClick={() => {
            form.reset();
            setFilter(initialState);
            DrawerFactory.close();
          }}
        >
          Сбросить
        </Button>
        <Button
          style={{ flexGrow: 1 }}
          size="sm"
          onClick={() => {
            if (form.values.dateFrom) {
              form.values.dateFrom = formatDateToISOString(
                form.values.dateFrom,
              );
            }
            if (form.values.dateTo) {
              form.values.dateTo = formatDateToISOString(form.values.dateTo);
            }

            setFilter(form.values);
            DrawerFactory.close();
          }}
          disabled={!form.isDirty()}
        >
          Сохранить
        </Button>
      </Group>
    </FormProvider>
  );
};
