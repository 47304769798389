import { createStyles, rem } from '@mantine/core';
import { isDark } from 'core/theme/utils';

export const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    [theme.fn.largerThan('sm')]: { paddingLeft: rem(10) },
  },
  titleWrapper: {
    [theme.fn.smallerThan('sm')]: { justifyContent: 'space-between' },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: 0,

    [theme.fn.smallerThan('md')]: { fontSize: rem(30) },
  },
  settingsIcon: {
    'width': rem(36),

    'transition': 'all 0.1s',
    'cursor': 'pointer',
    ':hover': { transform: 'rotate(90deg) scale(1.1)' },

    [theme.fn.smallerThan('md')]: { width: rem(30) },
  },
  details: {
    'flexWrap': 'no-wrap',
    'marginBottom': rem(8),
    [theme.fn.smallerThan('md')]: { marginBottom: rem(4) },

    '> svg': { flexShrink: 0 },
  },
  detailsText: {
    color: isDark(theme) ? theme.colors.dark[2] : theme.colors.gray[6],
  },
  cityIconWrapper: {
    svg: { path: { fill: theme.colors.red[6] }, width: rem(18) },
  },
  cityLabel: {
    fontSize: rem(14),
    lineHeight: 1,
    textTransform: 'uppercase',
    transition: 'color 100ms ease',
    color: isDark(theme) ? theme.colors.dark[2] : theme.colors.gray[6],
    [theme.fn.smallerThan('md')]: { fontSize: rem(12) },
  },
  member: {
    display: 'flex',
    alignItems: 'center',
    gap: rem(10),
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 1,
  },
}));
