import { LoadingOverlay } from '@mantine/core';
import { createStore, createEvent } from 'effector';
import { useStore } from 'effector-react';

export const setActive = createEvent();
export const $spinner = createStore(false);
$spinner.on(setActive, (_, active) => active);

export const useSpinner = () => {
  const store = useStore($spinner);

  return { active: store, setActive };
};

export const SpinnerProvider = () => {
  const { active } = useSpinner();

  if (!active) {
    return null;
  }

  return (
    <div className="full-screen-fixed">
      <LoadingOverlay visible={active} overlayBlur={0} />
    </div>
  );
};
