import { useMutation } from '@tanstack/react-query';
import {
  addMemberRequest,
  acceptMemberRequest,
  declineMemberRequest,
} from '../api';
import { useOptions } from './use-query-options';

const mutationOptions = ({ queryClient, spinner }) => ({
  onMutate: () => {
    spinner.setActive(true);
  },
  onSettled: () => {
    spinner.setActive(false);
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['requests'] });
  },
});

export const useAddMemberRequest = () => {
  const options = useOptions();

  return useMutation({
    mutationFn: (userId) =>
      addMemberRequest({ groupId: options.groupId, userId }),
    ...mutationOptions(options),
  });
};

export const useAcceptMemberRequest = () => {
  const options = useOptions();

  return useMutation({
    mutationFn: (requestId) =>
      acceptMemberRequest({ groupId: options.groupId, requestId }),
    ...mutationOptions(options),
  });
};

export const useDeclineMemberRequest = () => {
  const options = useOptions();

  return useMutation({
    mutationFn: (requestId) =>
      declineMemberRequest({ groupId: options.groupId, requestId }),
    ...mutationOptions(options),
  });
};
