import { AddButton } from 'components/ui';
import { columnsConfig } from './columns-config';
import { useDeleteStoreItem } from 'modules/group/hooks/use-store-mutation';
import { DrawerFactory } from 'core/providers/DrawerProvider';
import { EditForm, AddForm } from './form';
import { useGroup } from 'modules/group/context';
import { useGrantAccess } from 'hooks';
import { MobileRender } from './MobileRender';
import { Filter, filterInitialState } from './Filter';
import { useState } from 'react';
import { TableRender } from 'components/Table';

export const Storage = () => {
  const [filter, setFilter] = useState(filterInitialState);

  const group = useGroup();
  const { canGroup } = useGrantAccess();

  const deleteItemMutation = useDeleteStoreItem();

  const addHandler = () => {
    DrawerFactory.open({ header: 'Новая позиция', component: <AddForm /> });
  };

  const editHandler = (item) => {
    DrawerFactory.open({
      header: item.title || 'Редактирование',
      component: <EditForm data={item} />,
    });
  };

  const store = group?.store?.sort((a, b) => {
    if (a.type === b.type) {
      return a.title.localeCompare(b.title);
    }

    return a.type.localeCompare(b.type);
  });

  const processedStore = [];

  store?.forEach((item) => {
    let backgroundStatus = '';
    let status = '';

    if (item.qty === 0) {
      status = 'danger';
      backgroundStatus = 'danger';
    } else if (item.qty < (group.storeEndingLimit || 3)) {
      status = 'warning';
      backgroundStatus = 'warning';
    }

    const isTypeMatch = filter.type ? item.type === filter.type : true;
    const isEnded = filter.ended ? backgroundStatus === 'danger' : true;
    const isEnding = filter.ending ? backgroundStatus === 'warning' : true;

    if (isTypeMatch && isEnded && isEnding) {
      processedStore.push({
        ...item,
        backgroundStatus,
        status,
      });
    }
  });

  return (
    <div>
      <TableRender
        resource="store"
        noPadding
        data={processedStore}
        columns={columnsConfig}
        onView={false}
        onEdit={editHandler}
        onDelete={(row) => {
          deleteItemMutation.mutate(row.id);
        }}
        onAdd={addHandler}
        titleRender={() => (
          <>
            {canGroup('create', 'store') && (
              <AddButton onClick={addHandler} mb={20} label="Новая позиция" />
            )}
            {!!store?.length && (
              <Filter
                filter={filter}
                setFilter={setFilter}
                total={processedStore.length}
              />
            )}
          </>
        )}
        mobileRender={(row) => <MobileRender row={row} />}
      />
    </div>
  );
};
