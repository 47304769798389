import { Paper, createStyles } from '@mantine/core';
import { greyBlack } from '../../core/theme/utils';

const useStyles = createStyles((theme) => ({
  bgColor: { backgroundColor: greyBlack(theme) },
}));

export const GrayBlackBackground = ({ children, className, ...props }) => {
  const { classes, cx } = useStyles();

  return (
    <Paper className={cx(classes.bgColor, className)} {...props}>
      {children}
    </Paper>
  );
};
