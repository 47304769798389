import { object, string, array, date, bool } from 'yup';
import { yupResolver } from '@mantine/form';
import { messages } from 'utils/messages';

export const userSchema = object({
  firstName: string(),
  lastName: string(),
  email: string().required(messages.required).email(messages.email),
  phone: string(),
  role: string().required(messages.required),
  groups: array().of(
    object({
      groupId: string().required(messages.required),
      groupTitle: string().required(messages.required),
      roles: array().min(1, messages.required).of(string()),
    }),
  ),
  avatar: object({ fileName: string(), fileUrl: string() }),
  cleanDate: date().nullable(),
  country: string().required(messages.required),
  city: string().required(messages.required),
  useManualLocation: bool(),
});

export const initialFormState = {
  initialValues: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    role: '',
    groups: [],
    avatar: { fileName: '', fileUrl: '' },
    cleanDate: null,
    country: '',
    city: '',
    useManualLocation: false,
  },
  validate: yupResolver(userSchema),
  validateInputOnChange: true,
};
