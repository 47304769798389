import { Map } from 'components/Map';
import { Box, Flex, Text } from '@mantine/core';
import { useLocationStyles } from './Location.styles';
import { SectionContainer } from '../SectionContainer';

export const Location = ({ group }) => {
  const geoLocation = group?.geoLocation?.split(',');
  const { classes } = useLocationStyles();

  if (!geoLocation || geoLocation.length < 2) {
    return (
      <Flex direction="column" align="center" justify="center" mt={50}>
        <Text color="grey" align="center">
          Геолокация не указана
        </Text>
      </Flex>
    );
  }

  return (
    <SectionContainer>
      <Box h={500} className={classes.map}>
        <Map id="yandex-map-group" center={geoLocation} zoom={15} />
      </Box>
    </SectionContainer>
  );
};
