import { useMutation } from '@tanstack/react-query';
import { addJob, deleteJob, editJob } from '../api';
import { useOptions } from './use-query-options';

const mutationOptions = ({ queryClient, spinner, groupId }) => ({
  onMutate: () => {
    spinner.setActive(true);
  },
  onSettled: () => {
    spinner.setActive(false);
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['group', groupId] });
  },
});

export const useAddJob = () => {
  const options = useOptions();

  return useMutation({
    mutationFn: (job) => addJob({ groupId: options.groupId, job }),
    ...mutationOptions(options),
  });
};

export const useEditJob = () => {
  const options = useOptions();

  return useMutation({
    mutationFn: (job) => editJob({ groupId: options.groupId, job }),
    ...mutationOptions(options),
  });
};

export const useDeleteJob = () => {
  const options = useOptions();

  return useMutation({
    mutationFn: (id) => deleteJob({ groupId: options.groupId, id }),
    ...mutationOptions(options),
  });
};
