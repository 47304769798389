import { array, object, string, date } from 'yup';
import { yupResolver } from '@mantine/form';
import { messages } from 'utils/messages';

export const meetingSchema = object({
  date: date().required(messages.required),
  // dateISO: date(),
  leader: string().required(messages.required),
  teaMan: string(),
  cleaner: string(),
  money: string().required(messages.required),
  moneyLiterature: string(),
  moneyTransfer: string(),
  moneyRent: string(),
  newcomers: string(),
  guests: string(),
  before30days: string(),
  anniversaries: string(),
  participants: string().required(messages.required),
  comment: string(),
  released: array().of(object()).nullable(),
});

export const initialFormState = {
  initialValues: {
    date: new Date(),
    // dateISO: new Date(),
    leader: '',
    teaMan: '',
    cleaner: '',
    money: '',
    moneyLiterature: '',
    moneyTransfer: '',
    moneyRent: '',
    newcomers: '',
    guests: '',
    before30days: '',
    anniversaries: '',
    participants: '',
    comment: '',
    released: [],
  },
  validate: yupResolver(meetingSchema),
  validateInputOnBlur: true,
};
