import { formatDate } from 'utils/date';
import { Text } from '@mantine/core';

export const UpdatedCell = ({
  row,
  withTime = false,
  dateSeparator,
  accessor = 'updatedAt',
}) => {
  const date = formatDate({
    value: row[accessor],
    withTime,
    dateSeparator,
  });

  return (
    <div>
      <Text>{date}</Text>
      <Text color="dimmed" truncate>
        {row.updatedBy}
      </Text>
    </div>
  );
};
