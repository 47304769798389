import { MobileCard, MobileCell, MobileDivider } from 'components/Table/mobile';
import { CurrencyCell, DateTimeCell } from 'components/Table/cells';
import { Group, Text, useMantineTheme } from '@mantine/core';
import { IconCalendarFilled, IconSpeakerphone } from '@tabler/icons-react';

export const MobileRender = ({ row }) => {
  const theme = useMantineTheme();

  return (
    <MobileCard row={row}>
      <Group align="center" spacing={5} position="apart">
        <Group spacing={2} noWrap>
          <IconCalendarFilled size={20} color="blue" />
          <DateTimeCell
            dateValue={row.date}
            withTime={false}
            fw={700}
            dateSeparator="."
            color={theme.colors['blue'][6]}
          />
        </Group>

        <Group spacing={5} pr={10} noWrap>
          <IconSpeakerphone size={20} />
          <Text tt="uppercase" fw={700} color={theme.colors['blue'][6]}>
            {row.leader}
          </Text>
        </Group>
      </Group>

      <MobileDivider />

      <Group position="apart" align="flex-start">
        <MobileCell name="money">
          <CurrencyCell value={row.money} color="dimmed" />
        </MobileCell>
        <MobileCell name="moneyLiterature" align="center">
          <CurrencyCell value={row.moneyLiterature} color="dimmed" />
        </MobileCell>
        <MobileCell name="moneyTransfer" align="right">
          <CurrencyCell value={row.moneyTransfer} color="dimmed" />
        </MobileCell>
      </Group>

      <Group position="apart" align="flex-start">
        <MobileCell name="participants" />
        <MobileCell name="newcomersCount" align="right" />
      </Group>

      <MobileDivider />
    </MobileCard>
  );
};
