import speakerMeeting from 'assets/news/speaker-meeting.jpg';
import workingMeeting from 'assets/news/working-meeting.jpg';
import groupBirthday from 'assets/news/balloons.jpg';
import cleaning from 'assets/news/cleaning.jpg';
import other from 'assets/news/news-orange-small.jpg';
import schedule from 'assets/news/schedule.jpg';
import results from 'assets/news/results.jpg';
import announcement from 'assets/news/announcement.jpg';
import attention from 'assets/news/attention.jpg';

export const dateEvents = [
  'speakerMeeting',
  'workingMeeting',
  'groupBirthday',
  'cleaning',
];

export const groupEvents = [
  'speakerMeeting',
  'workingMeeting',
  'groupBirthday',
  'cleaning',
  'schedule',
];

export const NEWS_EVENT = {
  speakerMeeting: {
    name: 'speakerMeeting',
    title: 'Спикерское собрание',
    image: speakerMeeting,
  },
  workingMeeting: {
    name: 'workingMeeting',
    title: 'Рабочее собрание',
    image: workingMeeting,
  },
  groupBirthday: {
    name: 'groupBirthday',
    title: 'Юбилей группы',
    image: groupBirthday,
  },
  schedule: {
    name: 'schedule',
    title: 'Изменение расписания группы',
    image: schedule,
  },
  results: {
    name: 'results',
    title: 'Итоги',
    image: results,
  },
  cleaning: {
    name: 'cleaning',
    title: 'Субботник',
    image: cleaning,
  },
  announcement: {
    name: 'announcement',
    title: 'Объявление',
    image: announcement,
  },
  attention: {
    name: 'attention',
    title: 'Важная информация',
    image: attention,
  },
  other: {
    name: 'other',
    title: 'Новость',
    image: other,
  },
};

export const eventOptions = Object.values(NEWS_EVENT).map((item) => ({
  value: item.name,
  label: item.title,
}));
