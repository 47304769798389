import { useSpinner } from 'core/providers/SpinnerProvider';
import { useGroupId } from './use-group-query';
import { useQueryClient } from '@tanstack/react-query';

export const useOptions = () => {
  const queryClient = useQueryClient();
  const spinner = useSpinner();
  const groupId = useGroupId();

  return {
    queryClient,
    spinner,
    groupId,
  };
};
