import { Box, Header } from '@mantine/core';
import { MenuDrawer } from './MenuDrawer';
import { useStyles } from './PageLayout.styles';
import { useNavigationMenu } from './state';

export const PageLayout = ({ children, aside, header }) => {
  const { state, api } = useNavigationMenu();
  const { classes } = useStyles();

  return (
    <div>
      <Box className={classes.background} />

      <Header className={classes.header}>{header}</Header>
      <Box className={classes.aside} p="lg">
        {aside}
      </Box>
      <Box className={classes.main}>{children}</Box>
      <MenuDrawer content={aside} opened={state.isOpened} onClose={api.close} />
    </div>
  );
};
