import { createStyles, rem } from '@mantine/core';
import { isDark } from 'core/theme/utils';

export const useStyles = createStyles((theme) => ({
  card: {
    width: '100%',
    padding: rem(24),
    paddingTop: rem(32),
    paddingBottom: rem(32),
  },
  header: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: rem(30),
  },
  title: {
    color: isDark(theme) ? theme.white : theme.colors.gray[9],
    fontSize: rem(14),
    textAlign: 'center',
    lineHeight: 1,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  icon: { position: 'absolute', left: 0 },
  cardTitleWrapper: { textAlign: 'center' },
  cardTitleLabel: {
    color: isDark(theme) ? theme.colors.gray[7] : theme.colors.gray[4],
    fontSize: rem(26),
    lineHeight: 1,
    textTransform: 'uppercase',
    fontWeight: 700,
    marginBottom: rem(10),
  },
  cardTitleValue: {
    color: theme.colors.blue[6],
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: rem(22),
    lineHeight: 1,
    fontFamily: 'Greycliff CF',
  },

  parameterWrapper: {
    'paddingTop': rem(15),
    'paddingBottom': rem(15),
    'borderBottom': '1px solid',
    'borderBottomColor': isDark(theme)
      ? theme.colors.dark[4]
      : theme.colors.gray[2],
    '&:last-child': { borderBottom: 0, paddingBottom: 0 },
  },
  parameterName: {
    color: theme.colors.gray[6],
    textTransform: 'uppercase',
    fontSize: rem(10),
    fontWeight: 500,
  },
  parameterValue: {
    color: isDark(theme) ? theme.white : theme.colors.gray[9],
    // textTransform: 'uppercase',
    fontSize: rem(14),
    fontWeight: 700,
    fontFamily: 'Greycliff CF',
  },
  divider: {
    width: '100%',
    height: rem(1),
    backgroundColor: isDark(theme)
      ? theme.colors.dark[4]
      : theme.colors.gray[2],
  },
  themeColor: { color: isDark(theme) ? theme.white : theme.colors.gray[9] },
}));
