import { createStore, createApi } from 'effector';
import { useStore } from 'effector-react';

const initialState = { isOpened: false };

export const $navigationMenu = createStore(initialState);

export const navigationMenuApi = createApi($navigationMenu, {
  open: (state) => ({ ...state, isOpened: true }),
  close: (state) => ({ ...state, isOpened: false }),
  toggle: (state) => ({ ...state, isOpened: !state.isOpened }),
});

export const useNavigationMenu = () => {
  const state = useStore($navigationMenu);

  return { state, api: navigationMenuApi };
};
