import { whiteBlack, isDark } from 'core/theme/utils';
import { createStyles, rem } from '@mantine/core';
import { normalizeCssSize } from 'utils/string';

export const useStyles = createStyles((theme, { width }) => ({
  card: {
    width: normalizeCssSize(width),
    fontSize: rem(13),
    backgroundColor: whiteBlack(theme),
    boxShadow: theme.other.shadow,
    position: 'relative',
    borderRadius: rem(8),
  },
  cardOverlay: {
    'overflow': 'hidden',

    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: isDark(theme)
        ? 'linear-gradient(to top, #000000 5%, transparent 50%)'
        : 'linear-gradient(to top, #f9f9f9 10%, transparent 50%)',
      zIndex: 1,
    },
  },
}));
