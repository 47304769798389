import { modals } from '@mantine/modals';
import { Text } from '@mantine/core';

export const openConfirmModal = ({
  title,
  children,
  onConfirm,
  onCancel,
  ...rest
}) => {
  return modals.openConfirmModal({
    title: <Text weight={700}>{title}</Text>,
    children: (
      <Text size="sm" mb={30}>
        {children}
      </Text>
    ),
    labels: { confirm: 'Ok', cancel: 'Отмена' },
    confirmProps: { color: 'red' },
    onConfirm: onConfirm,
    onCancel,
    ...rest,
  });
};
