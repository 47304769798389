import { createStyles } from '@mantine/core';

export const useStyles = createStyles(() => ({
  centered: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
}));
