import { DateTimeCell } from 'components/Table/cells';

export const columnsConfig = [
  {
    title: 'Дата',
    name: 'createdAt',
    maxWidth: 200,
    width: 150,
    render: (row) => <DateTimeCell dateValue={row.createdAt} withTime />,
  },
  {
    title: 'Email',
    name: 'updatedBy',
    maxWidth: 200,
    width: 200,
    truncated: true,
  },
  {
    title: 'Действие',
    name: 'action',
    maxWidth: 150,
    // render: (row) => <DateTimeCell dateValue={row.loggedAt} withTime />,
  },
  {
    title: 'Модуль',
    name: 'resource',
    maxWidth: 150,
    // render: (row) => <DateTimeCell dateValue={row.loggedAt} withTime />,
  },
  {
    title: 'Группа',
    name: 'groupId',
    maxWidth: 150,
    // render: (row) => <DateTimeCell dateValue={row.loggedAt} withTime />,
  },
  {
    title: 'Запрос',
    name: 'request',
    maxWidth: 150,
    // render: (row) => <DateTimeCell dateValue={row.loggedAt} withTime />,
  },
];
