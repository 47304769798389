import { Title, Text, Container, Group } from '@mantine/core';
import { useStyles } from './404.styles';
import { NavButton } from 'components/ui';

export function Page404({ withRedirect = true }) {
  const { classes } = useStyles();

  return (
    <Container className={classes.root}>
      <div className={classes.label}>404</div>
      <Title className={classes.title}>Вы нашли секретное место</Title>
      <Text
        color="dimmed"
        size="lg"
        align="center"
        className={classes.description}
      >
        К сожалению, это всего лишь страница 404. Возможно, вы неправильно
        набрали адрес, или страница была перемещена по другому URL.
      </Text>
      {withRedirect && (
        <Group position="center">
          <NavButton size="md">Обратно на главную страницу</NavButton>
        </Group>
      )}
    </Container>
  );
}
