import { notifications } from '@mantine/notifications';
import {
  getErrorStyles,
  getSuccessStyles,
  getCommonStyles,
} from './helper-styles';
import { IconCheck, IconAlertSmall, IconBell } from '@tabler/icons-react';
import { Text } from '@mantine/core';

const DEFAULT_ERROR_MESSAGE = 'Что-то пошло не так. Попробуйте еще раз.';
const DEFAULT_SUCCESS_MESSAGE = 'Все прошло успешно!';

const commonProps = { withBorder: true, radius: 'md' };

export const showError = ({ title, message, error, reset, ...rest }) => {
  if (reset) {
    notifications.clean();
  }

  notifications.show({
    title: (
      <Text fw={700} mb={5}>
        {title || 'Ошибка'}
      </Text>
    ),
    message:
      error?.response?.data?.error?.message ||
      error?.response?.statusText ||
      message ||
      DEFAULT_ERROR_MESSAGE,
    styles: getErrorStyles,
    ...rest,
  });
};

export const showSuccess = ({ title, message, reset, ...rest }) => {
  if (reset) {
    notifications.clean();
  }

  notifications.show({
    title: (
      <Text fw={700} mb={5}>
        {title || message || DEFAULT_SUCCESS_MESSAGE}
      </Text>
    ),
    message: title ? message : '',
    icon: <IconCheck size="1.1rem" />,
    color: 'teal',
    styles: getSuccessStyles,
    autoClose: 2000,
    ...commonProps,
    withBorder: false,
    ...rest,
  });
};

export const showInfo = ({ title, message, reset, ...rest }) => {
  if (reset) {
    notifications.clean();
  }

  notifications.show({
    title: (
      <Text fw={700} mb={5}>
        {title || 'Внимание'}
      </Text>
    ),
    message: message,
    styles: getCommonStyles,
    icon: <IconBell size="1.1rem" />,
    ...commonProps,
    ...rest,
  });
};

export const showAlert = ({ title, message, reset, ...rest }) => {
  if (reset) {
    notifications.clean();
  }

  notifications.show({
    title: (
      <Text fw={700} mb={5}>
        {title || 'Внимание'}
      </Text>
    ),
    message: message,
    styles: getCommonStyles,
    icon: <IconAlertSmall size="3rem" />,
    color: 'yellow',
    ...commonProps,
    ...rest,
  });
};
