import { createStore, createEvent } from 'effector';

export const STORAGE_KEY = '__disabledGeoLocationMessage__';

export const $geoLocation = createStore({
  isLoading: true,
});
export const setLocation = createEvent();

$geoLocation.on(setLocation, (_, data) => ({ ...data }));
$geoLocation.watch((state) => console.log('$geoLocation', state));
