import {
  NumberField,
  TextAreaField,
  SelectField,
  useFormContext,
} from 'components/ui/form';
import { Stack, Button } from '@mantine/core';
import {
  financeOperationOptions,
  financeIncomeSourceOptions,
  financeExpenseSourceOptions,
  FINANCE_OPERATION_TYPE,
} from 'modules/group/const';

export const BaseForm = ({ onSubmit }) => {
  const form = useFormContext();
  const canSubmit = form.isValid() && form.isDirty();

  const operationType = form.values.operationType;
  const sourceOptions =
    operationType === FINANCE_OPERATION_TYPE.income.name
      ? financeIncomeSourceOptions
      : financeExpenseSourceOptions;

  return (
    <>
      <Stack spacing={5} mb={30}>
        <SelectField
          label="Тип операции"
          name="operationType"
          data={financeOperationOptions}
          onChange={(value) => {
            if (value !== operationType) {
              form.setFieldValue('sourceType', null);
            }
          }}
        />
        <SelectField label="Категория" name="sourceType" data={sourceOptions} />
        <NumberField label="Сумма" name="amount" step={1000} min={0} />
        <TextAreaField minRows={5} label="Комментарий" name="comment" />
      </Stack>

      <Button
        fullWidth
        size="md"
        mt={10}
        onClick={() => {
          const { hasErrors } = form.validate();

          if (!hasErrors) {
            onSubmit(form.values);
          }
        }}
        disabled={!canSubmit}
      >
        Сохранить
      </Button>
    </>
  );
};
