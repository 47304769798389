import { useDeleteUser } from '../../hooks/use-user-mutation';
import { openDeleteModal } from 'components/ui/Modal';
import { showSuccess } from 'core/notifications';
import { Table as TableComponent } from 'components/Table';
import { columnsConfig } from './columns-config';
import { DrawerFactory } from 'core/providers/DrawerProvider';
import { EditUserForm } from 'modules/user/form';

export const Users = () => {
  const deleteUserMutation = useDeleteUser();

  const deleteUserHandler = (row) => {
    return openDeleteModal({
      onConfirm: () => {
        deleteUserMutation.mutate(row.id, {
          onSuccess: () => {
            showSuccess({ title: 'Пользователь удален' });
          },
        });
      },
    });
  };

  const editUserHandler = (row) => {
    DrawerFactory.open({
      header: 'Редактирование пользователя',
      component: <EditUserForm user={row} />,
    });
  };

  return (
    <TableComponent
      resource="user"
      columns={columnsConfig}
      resourceEndpoint="/user"
      tableKey="users"
      onView={false}
      onDelete={deleteUserHandler}
      onEdit={editUserHandler}
      title="Пользователи"
    />
  );
};
