import { Group as GroupUI } from '@mantine/core';
import { useSpinner } from 'core/providers/SpinnerProvider';
import { useGrantAccess, useSearch } from 'hooks';
import { setGroup } from 'modules/group/context';
import { useGroupQuery } from 'modules/group/hooks/use-group-query';
import { useEffect } from 'react';
import {
  Route,
  Routes,
  useNavigate,
  useParams,
  Navigate,
} from 'react-router-dom';
import { useStyles } from './Group.styles';
import { MainInfo } from './MainInfo';
import {
  Finance,
  Jobs,
  Location,
  Meetings,
  Members,
  Reports,
  Schedule,
  Store,
  GroupNews,
  Documents,
} from './sections';

export const Group = () => {
  const { canGroup } = useGrantAccess();
  const { data, isLoading, isFetching, isError, error } = useGroupQuery();
  const spinner = useSpinner();
  const navigate = useNavigate();
  const { groupId, sectionId } = useParams();
  const { classes, cx } = useStyles();
  const search = useSearch();

  const hasNewsAccess = canGroup('read', 'news');

  const Link = ({ to, children }) => {
    return (
      <div
        className={cx(classes.link, { [classes.linkActive]: sectionId === to })}
        onClick={() => {
          navigate(`/groups/${groupId}/${to}`);
        }}
      >
        {children}
      </div>
    );
  };

  useEffect(() => {
    spinner.setActive(isFetching);
  }, [isFetching]);

  useEffect(() => {
    if (!sectionId) {
      const route = hasNewsAccess ? 'news' : 'schedule';

      navigate(`/groups/${groupId}/${route}`);
    }
  }, [sectionId]);

  useEffect(() => {
    if (data) {
      setGroup(data);
    }
  }, [data]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [groupId, sectionId, JSON.stringify(search)]);

  if (isLoading) {
    return null;
  }

  if (isError) {
    return error.response?.status === 401 ? null : <Navigate to="/error" />;
  }

  return (
    <div>
      <div className={classes.header}>
        <MainInfo group={data} />
      </div>

      <GroupUI spacing={0} className={classes.links}>
        {hasNewsAccess && <Link to="news">Новости</Link>}
        {canGroup('read', 'meeting') && <Link to="meetings">Собрания</Link>}
        {canGroup('read', 'job') && <Link to="jobs">Служения</Link>}
        {canGroup('read', 'user') && <Link to="members">Участники</Link>}
        {canGroup('read', 'store') && <Link to="store">ЛитКом</Link>}
        {canGroup('read', 'finance') && <Link to="finance">Финансы</Link>}
        {canGroup('read', 'report') && <Link to="reports">Статистика</Link>}
        {canGroup('read', 'docs') && <Link to="documents">Документы</Link>}

        <Link to="schedule">Расписание</Link>
        <Link to="location">Локация</Link>
      </GroupUI>

      <div className={classes.main}>
        <Routes>
          {hasNewsAccess && <Route path="news" element={<GroupNews />} />}

          {canGroup('read', 'meeting') && (
            <Route path="meetings/*" element={<Meetings group={data} />} />
          )}
          {canGroup('read', 'job') && (
            <Route path="jobs" element={<Jobs group={data} />} />
          )}
          {canGroup('read', 'job') && (
            <Route path="members" element={<Members group={data} />} />
          )}
          {canGroup('read', 'store') && (
            <Route path="store" element={<Store />} />
          )}
          {canGroup('read', 'finance') && (
            <Route path="finance" element={<Finance />} />
          )}
          {canGroup('read', 'report') && (
            <Route path="reports" element={<Reports group={data} />} />
          )}
          {canGroup('read', 'docs') && (
            <Route path="documents" element={<Documents group={data} />} />
          )}
          <Route path="schedule" element={<Schedule />} />
          <Route path="location" element={<Location group={data} />} />
        </Routes>
      </div>
    </div>
  );
};
