import { useStyles } from '../Reports.styles';
import { ThemeIcon, rem } from '@mantine/core';
import { formatNumber } from 'utils/string';
import { Card as CardComponent } from 'components/ui';
import { useMediaQuery } from 'hooks';

const CardIcon = ({ Icon, className }) => {
  return (
    <ThemeIcon
      size="xl"
      radius="md"
      // variant="gradient"
      // gradient={{ deg: 0, from: 'pink', to: 'orange' }}
      // gradient={{ deg: 0, from: 'blue', to: 'teal' }}
      color="pink"
      className={className}
    >
      <Icon style={{ width: rem(28), height: rem(28) }} stroke={1.5} />
    </ThemeIcon>
  );
};

export const CardTitle = ({ label, value = 0 }) => {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.parameterWrapper, classes.cardTitleWrapper)}>
      <div className={classes.cardTitleLabel}>{label}</div>
      <div className={classes.cardTitleValue}>{formatNumber(value)}</div>
    </div>
  );
};

export const Card = ({ Icon, title, children }) => {
  const { classes } = useStyles();
  const { isMobile } = useMediaQuery();

  return (
    <CardComponent height={523} width={isMobile ? '100%' : '350px'}>
      <div className={classes.card}>
        <div className={classes.header}>
          <CardIcon Icon={Icon} className={classes.icon} />
          <div className={classes.title}>{title}</div>
        </div>

        <div className={classes.divider}></div>

        {children}
      </div>
    </CardComponent>
  );
};
