import { Text, Group, ScrollArea, Box } from '@mantine/core';
import { useEffect, useState } from 'react';
import {
  IconChevronRight,
  IconChevronLeft,
  IconArrowsExchange,
} from '@tabler/icons-react';
import { useStyles } from './TransferList.styles';
import { produce } from 'immer';
import { CurrencyCell } from 'components/Table/cells';
import { useGroup } from '../../modules/group/context';

const sortStock = (data = []) => {
  const items = [...data];

  return items.sort((a, b) => {
    if (a.type === b.type) {
      return a.title.localeCompare(b.title);
    }

    return a.type.localeCompare(b.type);
  });
};

const ItemComponent = ({ data, type, onAction, accessor }) => {
  const { classes } = useStyles();
  const group = useGroup();

  return (
    <Group noWrap className={classes.item} onClick={() => onAction(data, type)}>
      {type === 'out' && <IconChevronLeft size={14} />}

      <div style={{ flex: 1 }}>
        <Text size={12} weight={500} className={classes.itemTitle}>
          {data.title}
        </Text>
        <Text size={10} color="dimmed" weight={400}>
          <CurrencyCell value={data[accessor]} currency={group.currency} />
        </Text>
      </div>

      <Box p={10} pos="relative">
        <Text
          size={11}
          fw={700}
          align="center"
          className={classes.itemIndicator}
        >
          {data.qty}
        </Text>
      </Box>

      {type === 'in' && <IconChevronRight size={14} />}
    </Group>
  );
};

const Stock = ({ items, label, height, useSort, ...rest }) => {
  const { classes } = useStyles();

  if (useSort) {
    items = sortStock(items);
  }

  return (
    <div className={classes.column}>
      <Text className={classes.title}>{label}</Text>

      <ScrollArea h={height}>
        {items.map((item) => (
          <ItemComponent key={item.id} data={item} {...rest} />
        ))}
        {items.length === 0 && (
          <Text align="center" fz={14} color="dimmed">
            Ничего не добавлено
          </Text>
        )}
      </ScrollArea>
    </div>
  );
};

export const TransferList = ({
  data = [],
  dataOut = [],
  height = 150,
  labels = { in: 'In', out: 'Out' },
  onChange,
  needReset,
  accessor = 'priceOut',
  useSort = true,
}) => {
  data = data.filter(({ qty }) => qty > 0);

  const initialValues = { in: data, out: dataOut };
  const [stocks, updateStocks] = useState(initialValues);

  const { classes } = useStyles();

  useEffect(() => {
    updateStocks({ in: data, out: dataOut });
  }, [JSON.stringify(data)]);

  useEffect(() => {
    if (!needReset) {
      updateStocks({ in: data, out: [] });
    }
  }, [needReset]);

  const onActionHandler = (item, stockType) => {
    const getStockIndex = (stockType) =>
      stocks[stockType].findIndex((i) => i.id === item.id);

    const newStocks = produce(stocks, (draft) => {
      const newActiveStockItemValue = item.qty - 1;

      const increaseStock = (stockType) => {
        const index = getStockIndex(stockType);

        if (index === -1) {
          draft[stockType].push({ ...item, qty: 1 });
        } else {
          draft[stockType][index].qty += 1;
        }
      };

      const decreaseStock = (stockType) => {
        const index = getStockIndex(stockType);

        if (index < 0) {
          return;
        }

        if (newActiveStockItemValue === 0) {
          draft[stockType] = draft[stockType].filter((i) => i.id !== item.id);
        } else {
          draft[stockType][index].qty -= 1;
        }
      };

      if (stockType === 'in') {
        decreaseStock('in');
        increaseStock('out');
      } else {
        decreaseStock('out');
        increaseStock('in');
      }
    });

    updateStocks(newStocks);

    if (typeof onChange === 'function') {
      onChange(newStocks);
    }
  };

  return (
    <div className={classes.wrapper}>
      <Stock
        height={height}
        type="in"
        items={stocks.in}
        label={labels.in}
        onAction={onActionHandler}
        accessor={accessor}
        useSort={useSort}
      />
      <div className={classes.exchangeIcon}>
        <IconArrowsExchange size={18} />
      </div>
      <Stock
        height={height}
        type="out"
        items={stocks.out}
        label={labels.out}
        onAction={onActionHandler}
        accessor={accessor}
        useSort={useSort}
      />
    </div>
  );
};
