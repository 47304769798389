import { useStore } from 'effector-react';
import { $auth, applyAuthTokenFX, authApi, loadUser } from './state';

export const useAuth = () => {
  const { token, isLoading, user, isLogged } = useStore($auth);

  return {
    isLoading,
    isLogged,
    user,
    login: ({ user, token }) => {
      authApi.setToken(token);
      authApi.setUser(user);
    },
    loadUser: () => {
      applyAuthTokenFX(token);
      loadUser();
    },
    logout: () => {
      authApi.logout();
    },
  };
};
