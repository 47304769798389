import { Radio, Flex } from '@mantine/core';
import { useFormContext } from './context';

export const RadioGroupField = ({
  name,
  label,
  items,
  inline = false,
  ...rest
}) => {
  const form = useFormContext();

  if (!items?.length) {
    return null;
  }

  return (
    <Radio.Group label={label} {...form.getInputProps(name)} {...rest}>
      <Flex direction={inline ? 'row' : 'column'} gap={10} mt={15}>
        {items.map((item) => {
          return (
            <Radio key={item.value} value={item.value} label={item.label} />
          );
        })}
      </Flex>
    </Radio.Group>
  );
};
