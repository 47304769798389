export const FINANCE_OPERATION_TYPE = {
  income: { name: 'income', label: 'Поступление' },
  expense: { name: 'expense', label: 'Расход' },
};

export const financeOperationOptions = Object.values(
  FINANCE_OPERATION_TYPE,
).map((item) => ({ value: item.name, label: item.label }));

export const FINANCE_INCOME_SOURCE_TYPE = {
  cash: { name: 'cash', label: 'Наличные' },
  creditCard: { name: 'creditCard', label: 'Перевод' },
  correction: { name: 'correction', label: 'Коррекция' },
  other: { name: 'other', label: 'Другое' },
};

export const FINANCE_EXPENSE_SOURCE_TYPE = {
  rent: { name: 'rent', label: 'Аренда' },
  products: { name: 'products', label: 'Продукты' },
  goods: { name: 'goods', label: 'Хозтовары' },
  correction: { name: 'correction', label: 'Коррекция' },
  literature: { name: 'literature', label: 'Литература' },
  other: { name: 'other', label: 'Другое' },
};

export const FINANCE_SOURCE_TYPE = {
  ...FINANCE_INCOME_SOURCE_TYPE,
  ...FINANCE_EXPENSE_SOURCE_TYPE,
};

export const financeIncomeSourceOptions = Object.values(
  FINANCE_INCOME_SOURCE_TYPE,
).map((item) => ({ value: item.name, label: item.label }));

export const financeExpenseSourceOptions = Object.values(
  FINANCE_EXPENSE_SOURCE_TYPE,
).map((item) => ({ value: item.name, label: item.label }));

export const financeSourceOptions = Object.values(FINANCE_SOURCE_TYPE).map(
  (item) => ({ value: item.name, label: item.label }),
);
