import { BaseForm } from './BaseForm';
import { useForm, FormProvider } from 'components/ui/form';
import { initialFormState } from './schema';
import { useAddOrder } from 'modules/group/hooks/use-order-mutation';
import { DrawerFactory } from 'core/providers/DrawerProvider';
import { showError } from 'core/notifications';
import { useMemo } from 'react';

export const AddForm = ({ store, groupId }) => {
  const items = store?.map((item) => {
    return { ...item, qty: 0 };
  });

  const form = useForm({
    initialValues: { ...initialFormState.initialValues, items },
  });

  const initialStateString = useMemo(() => {
    return JSON.stringify({ ...initialFormState.initialValues, items });
  }, []);

  const isDirty = initialStateString !== JSON.stringify(form.values);

  const addOrderMutation = useAddOrder();

  const submitHandler = (values) => {
    addOrderMutation.mutate(
      { ...values, groupId },
      {
        onSuccess: () => {
          form.reset();
          DrawerFactory.close();
        },
        onError: ({ response }) => {
          if (response.status === 422) {
            showError({
              title: 'Ошибка',
              message: response.data?.error?.message,
              autoClose: 20000,
            });
          }
        },
      },
    );
  };

  return (
    <FormProvider form={form}>
      <BaseForm onSubmit={submitHandler} isDirty={isDirty} />
    </FormProvider>
  );
};
