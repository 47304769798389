import { PaperBackground } from 'components/theme-backgrounds';
import { Center, Text } from '@mantine/core';

export const TableError = () => {
  return (
    <PaperBackground>
      <Center h={150} mx="auto">
        <Text align="center" color="grey">
          Возникла ошибка при получении данных
        </Text>
      </Center>
    </PaperBackground>
  );
};
