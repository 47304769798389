import { createStyles, rem } from '@mantine/core';
import { whiteDark } from '../../core/theme/utils';

export const useStyles = createStyles((theme) => ({
  container: {
    padding: rem(30),

    [theme.fn.smallerThan('sm')]: { padding: rem(16) },
  },
  wrapper: {
    backgroundColor: whiteDark(theme),
    borderRadius: rem(16),
    padding: rem(20),
  },

  title: {
    fontSize: rem(60),

    [theme.fn.smallerThan('sm')]: { fontSize: rem(40) },
  },

  subTitle: {
    fontSize: rem(50),

    [theme.fn.smallerThan('sm')]: { fontSize: rem(30) },
  },
}));
