import { countries } from 'core/const';

export const createCityOptions = (countryTitle) => {
  const country = Object.values(countries).find(
    (country) => country.title === countryTitle,
  );

  if (!country?.cities?.length) {
    return [];
  }

  return country.cities.map((city) => ({ value: city, label: city }));
};
