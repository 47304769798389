import { useMutation } from '@tanstack/react-query';
import { addMeeting, updateMeeting, deleteMeeting } from '../api';
import { useOptions } from './use-query-options';

const mutationOptions = ({ queryClient, spinner, groupId }) => ({
  onMutate: () => {
    spinner.setActive(true);
  },
  onSettled: () => {
    spinner.setActive(false);
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['group', groupId] });
    queryClient.invalidateQueries({ queryKey: [`summary-${groupId}`] });
  },
});

export const useAddMeeting = () => {
  const options = useOptions();

  return useMutation({
    mutationFn: (meeting) => addMeeting({ groupId: options.groupId, meeting }),
    ...mutationOptions(options),
  });
};

export const useUpdateMeeting = () => {
  const options = useOptions();

  return useMutation({
    mutationFn: (meeting) =>
      updateMeeting({ groupId: options.groupId, meeting }),
    ...mutationOptions(options),
  });
};

export const useDeleteMeeting = () => {
  const options = useOptions();

  return useMutation({
    mutationFn: (id) => deleteMeeting({ groupId: options.groupId, id }),
    ...mutationOptions(options),
  });
};
