export const isDark = (theme) => theme.colorScheme === 'dark';
export const isLight = (theme) => !isDark(theme);
export const getBorderColor = (theme) =>
  isDark(theme) ? theme.colors.dark[5] : theme.colors.gray[2];

export const greyDark = (theme) =>
  isDark(theme) ? theme.colors.dark[7] : theme.colors.gray[0];

export const greyBlack = (theme) =>
  isDark(theme) ? theme.black : theme.colors.gray[0];

export const whiteDark = (theme) =>
  isDark(theme) ? theme.colors.dark[7] : theme.white;

export const whiteBlack = (theme) =>
  isDark(theme) ? theme.black : theme.white;

export const tablePadding = (theme) => theme.other.table.padding;
export const tablePaddingX = (theme) => theme.other.table.px;
export const tablePaddingY = (theme) => theme.other.table.py;
export const tableMobilePadding = (theme) => theme.other.table.mobilePadding;
