import axios from 'axios';
import { showError } from '../notifications';
import { tokenStorageApi } from 'core/local-storage';
import { openReAuthModal } from 'components/ui/Modal';
import { authApi } from 'modules/auth/state';

class HttpClientProvider {
  constructor() {
    this.isNetworkError = false;
    this.errorRoute = '';

    const baseURL = window.NA_PORTAL?.apiUri || process.env.API_URI;

    this.client = axios.create({ baseURL });

    this.setHeaders();
    this.setInterceptors();
  }

  setHeaders(headers) {
    const token = tokenStorageApi.get();

    if (token) {
      this.client.defaults.headers['Authorization'] = `Bearer ${token}`;
    }

    this.client.defaults.headers = {
      ...this.client.defaults.headers,
      ...headers,
    };
  }

  setInterceptors() {
    this.client.interceptors.request.use((config) => {
      // config.headers['Content-Type'] = 'application/json;charset=utf-8';

      return config;
    });

    this.client.interceptors.response.use(
      (response) => {
        this.resetErrors();

        return response;
      },
      (error) => {
        return this.processError(error);
      },
    );
  }

  resetErrors() {
    this.isNetworkError = false;
    this.errorRoute = '';
  }

  processError(error) {
    const excludedErrorCodes = [422, 401, 413]; // 422 - validation error, 401 - unauthorized

    console.error('HttpClientProvider processError', error);

    if (error?.response?.status === 413) {
      showError({
        title: 'Слишком большой размер файла',
        message: 'Максимальный размер файла 10 МБ',
      });
    }

    if (
      error?.response?.status === 401 &&
      error.response.data?.error?.message.includes('expired')
    ) {
      authApi.logout();

      openReAuthModal();

      return Promise.reject(error);
    }

    if (!error?.response?.status || error?.code === 'ERR_NETWORK') {
      if (this.isNetworkError && this.errorRoute === window.location.pathname) {
        return Promise.reject(error);
      }

      this.isNetworkError = true;
      this.errorRoute = window.location.pathname;

      showError({
        title: 'Ошибка сети',
        message: 'Проверьте подключение к интернету',
      });
    } else if (!excludedErrorCodes.includes(error?.response?.status)) {
      showError({ error });
    }

    return Promise.reject(error);
  }
}

export const { client: httpClient } = new HttpClientProvider();

window.httpClient = httpClient;
