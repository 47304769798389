import { FormProvider, useForm } from 'components/ui/form';
import { UserForm } from './UserForm';
import { initialFormState } from './schema';
import { useUpdateUser } from 'modules/user/hooks/use-user-mutation';
import { showSuccess } from 'core/notifications';
import { DrawerFactory } from 'core/providers/DrawerProvider';
import { authApi } from 'modules/auth/state';
import { useAuth } from 'modules/auth/use-auth';

export const EditUserForm = ({ user, isOwnProfile }) => {
  const initialValues = {
    ...initialFormState.initialValues,
    ...user,
    cleanDate: user?.cleanDate || null,
  };
  const form = useForm({ ...initialFormState, initialValues });

  const updateUserMutation = useUpdateUser(isOwnProfile);
  const { user: loggedUser } = useAuth();

  const submitHandler = (values) => {
    updateUserMutation.mutate(values, {
      onSuccess: (data) => {
        if (loggedUser.id === data.id) {
          // update logged user in authState and localStorage
          authApi.setUser(data);
        }

        showSuccess({ title: 'Данные сохранены' });
        form.reset();
        DrawerFactory.close();
      },
    });
  };

  return (
    <FormProvider form={form}>
      <UserForm onSubmit={submitHandler} isOwnProfile={isOwnProfile} />
    </FormProvider>
  );
};
