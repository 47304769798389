import { UpdatedCell } from 'components/Table/cells';
import { Comment } from 'components/Comment';
import { Text, Box } from '@mantine/core';
import { useStyles } from 'components/Table/Table.styles';
import { MobileDivider } from 'components/Table/mobile';

export const ExpandableRowRender = ({ row }) => {
  const { classes } = useStyles();

  return (
    <>
      <Box mb={20}>
        <Text className={classes.cellHeader} mb={5}>
          Комментарий
        </Text>
        <Comment color="dimmed">{row.comment}</Comment>
      </Box>

      <MobileDivider />

      <Box>
        <Text className={classes.cellHeader} mb={5}>
          Создан
        </Text>

        <UpdatedCell row={row} withTime />
      </Box>
    </>
  );
};
