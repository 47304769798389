import { MobileCard, MobileCell, MobileDivider } from 'components/Table/mobile';
import { JobsCell, UserCell } from './cells';

export const MobileRender = ({ row }) => {
  return (
    <MobileCard key={row.id} row={row}>
      <MobileCell>
        <UserCell row={row} />
      </MobileCell>

      <JobsCell row={row} withDivider p={5} />

      {row.groupComment && (
        <>
          <MobileDivider />
          <MobileCell name="groupComment" withHeader={true} />
        </>
      )}
    </MobileCard>
  );
};
