import { PaperBackground } from 'components/theme-backgrounds';
import { Button, Flex, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

export const NoSearchResults = () => {
  const navigate = useNavigate();

  return (
    <PaperBackground p={50}>
      <Flex direction="column" align="center" justify="center" gap={20}>
        <Text color="grey" align="center">
          Данные не получены по этому запросу. Попробуйте обновить параметры
          запроса.
        </Text>
        <Button
          onClick={() => {
            navigate({ search: '' });
          }}
        >
          Сбросить
        </Button>
      </Flex>
    </PaperBackground>
  );
};
