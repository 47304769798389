import { MantineProvider, ColorSchemeProvider } from '@mantine/core';
import { useState } from 'react';
import { baseTheme } from 'core/theme/base-theme';

const THEME_COLOR_KEY = '__themeColor__';
const savedThemeColor = localStorage.getItem(THEME_COLOR_KEY);

export const ThemeProvider = ({ children }) => {
  const [colorScheme, setColorScheme] = useState(savedThemeColor || 'light');

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={(value) => {
        const color = value || colorScheme === 'dark' ? 'light' : 'dark';

        setColorScheme(color);
        localStorage.setItem(THEME_COLOR_KEY, color);
      }}
    >
      <MantineProvider
        theme={{ colorScheme, ...baseTheme }}
        withGlobalStyles
        withNormalizeCSS
      >
        {children}
      </MantineProvider>
    </ColorSchemeProvider>
  );
};
