import { useMutation } from '@tanstack/react-query';
import { loginUser, signupUser, forgotPassword, resetPassword } from '../api';

export const useLoginMutation = () => {
  return useMutation({ mutationFn: loginUser });
};

export const useSignupMutation = () => {
  return useMutation({ mutationFn: signupUser });
};

export const useForgotPasswordMutation = () => {
  return useMutation({ mutationFn: forgotPassword });
};

export const useResetPasswordMutation = () => {
  return useMutation({ mutationFn: resetPassword });
};
