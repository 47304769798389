import { createStyles, rem } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  },

  hiddenMobile: { [theme.fn.smallerThan('xs')]: { display: 'none' } },

  hiddenDesktop: { [theme.fn.largerThan('sm')]: { display: 'none' } },

  burger: { [theme.fn.largerThan('md')]: { display: 'none' } },
  logo: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    cursor: 'pointer',

    [theme.fn.smallerThan('md')]: {
      alignItems: 'center',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  userInfo: {
    // 'padding': '10px',
    'height': '100%',
    'transition': 'all 100ms ease',
    'cursor': 'pointer',

    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  userInfoBadges: {
    [theme.fn.smallerThan('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  userInfoSectionItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: rem(16),
    cursor: 'pointer',
    paddingTop: rem(5),
    paddingBottom: rem(5),
  },
  cityIconWrapper: {
    svg: { path: { fill: theme.colors.red[6] } },
  },
}));
