import { initialFormState } from './schema';
import { useForm, FormProvider, TextAreaField } from 'components/ui/form';
import { DrawerFactory } from 'core/providers/DrawerProvider';
import { useUpdateUserGroupRole } from 'modules/user/hooks/use-user-mutation';
import { useAuth } from 'modules/auth/use-auth';
import { authApi } from 'modules/auth/state';
import { EmailCell } from 'components/Table/cells';
import { getBorderColor } from 'core/theme/utils';
import { groupRoleOptions } from 'core/rbac';
import {
  Button,
  Divider,
  MultiSelect,
  Text,
  useMantineTheme,
} from '@mantine/core';

export const EditForm = ({ data }) => {
  const theme = useMantineTheme();

  const form = useForm({
    ...initialFormState,
    initialValues: { roles: data.groupRoles, comment: data.groupComment },
  });

  const updateMutation = useUpdateUserGroupRole();
  const { user: loggedUser } = useAuth();

  const submitHandler = (values) => {
    updateMutation.mutate(
      { ...data, groupRoles: values.roles, groupComment: values.comment },
      {
        onSuccess: (user) => {
          if (loggedUser.id === data.id) {
            // update logged user in authState and localStorage

            authApi.setUser(user);
          }

          form.reset();
          DrawerFactory.close();
        },
      },
    );
  };

  return (
    <FormProvider form={form}>
      <Text fz={14} color="dimmed" mb={20}>
        Пользователь
      </Text>

      <EmailCell row={data} size={60} />

      <Divider mt={20} mb={20} color={getBorderColor(theme)} />

      <MultiSelect
        placeholder="Роль"
        {...form.getInputProps(`roles`)}
        data={groupRoleOptions}
        label="Роли"
        mb={10}
      />

      <TextAreaField name="comment" label="Комментарий" minRows={5} />

      <Button
        fullWidth
        size="md"
        mt={40}
        onClick={() => {
          const { hasErrors } = form.validate();

          if (!hasErrors) {
            submitHandler(form.values);
          }
        }}
        disabled={!(form.isValid() && form.isDirty())}
      >
        Сохранить
      </Button>
    </FormProvider>
  );
};
