import { useStyles } from './Table.styles';
import { ScrollArea } from '@mantine/core';

export const TableBody = ({ children, className, noPadding = false }) => {
  const { classes, cx } = useStyles({ noPadding });

  return (
    <ScrollArea className={cx(classes.body, className)}>{children}</ScrollArea>
  );
};
