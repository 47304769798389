import { formatDate } from 'utils/date';

export const formatDateLabel = (prefix) => (value) => {
  return (
    prefix +
    formatDate({
      value,
      withTime: false,
      dateSeparator: '.',
    })
  );
};
