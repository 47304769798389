import { Group, Text, ThemeIcon, Box, ActionIcon } from '@mantine/core';
import { FileSize, TotalWithBg, Card, FileServerUpload } from 'components/ui';
import { useGrantAccess } from 'hooks';
import { DateTimeCell } from 'components/Table/cells/DateTimeCell';
import {
  IconFileText,
  IconCloudDownload,
  IconTrashX,
  IconSearch,
} from '@tabler/icons-react';
import { FILE_TYPES } from '../const';
import fileDownload from 'js-file-download';
import {
  useUploadFile,
  useRemoveFile,
  useDownloadFile,
} from 'modules/group/hooks/use-docs-mutation';

const FolderCapacity = ({ folderSize, maxSize = 50 }) => {
  return (
    <Group spacing={5}>
      <FileSize size={folderSize || 0} />
      <Text>/ {maxSize} Мб</Text>
    </Group>
  );
};

export const GroupFiles = ({ data }) => {
  const { canGroup } = useGrantAccess();
  const uploadMutation = useUploadFile();
  const removeMutation = useRemoveFile();
  const downloadMutation = useDownloadFile();

  return (
    <div>
      <Group position="apart" align="flex-start" mb={20}>
        <TotalWithBg total={<FolderCapacity folderSize={data?.size} />} />

        {canGroup('create', 'docs') && (
          <FileServerUpload
            onUpload={({ file, fileName }) => {
              uploadMutation.mutate({
                document: file,
                fileName,
              });
            }}
          />
        )}
      </Group>

      {data?.files?.map((file) => {
        const Icon = FILE_TYPES[file.type]?.icon || IconFileText;

        return (
          <Card key={file.name} mb={10}>
            <Group p="10px 15px" spacing={10}>
              <Box w="100%">
                <Group noWrap spacing={8} align="center" mb={5}>
                  <ThemeIcon
                    color={FILE_TYPES[file.type]?.color || 'gray'}
                    size={25}
                    variant="light"
                  >
                    <Icon size={20} />
                  </ThemeIcon>

                  <Text style={{ wordBreak: 'break-word', lineHeight: '16px' }}>
                    {file.name}
                  </Text>
                </Group>

                <Group spacing={5} position="apart">
                  <FileSize size={file.size} color="dimmed" fz={12} />
                  <Text fz={12} color="dimmed">
                    &#183;
                  </Text>
                  <DateTimeCell
                    dateValue={file.updatedAt}
                    withTime
                    color="dimmed"
                    fz={12}
                  />

                  <Group align="center" spacing={5} ml="auto">
                    <ActionIcon
                      size={25}
                      color="teal"
                      variant="light"
                      radius="50%"
                    >
                      <IconSearch
                        size={18}
                        onClick={() => {
                          downloadMutation.mutate(file.name, {
                            onSuccess: (blob) => {
                              const link = document.createElement('a');

                              link.href = URL.createObjectURL(blob);
                              link.click();
                              URL.revokeObjectURL(link.href);
                            },
                          });
                        }}
                      />
                    </ActionIcon>

                    <ActionIcon
                      size={25}
                      color="blue"
                      variant="light"
                      radius="50%"
                    >
                      <IconCloudDownload
                        size={18}
                        onClick={() => {
                          downloadMutation.mutate(file.name, {
                            onSuccess: (blob) => {
                              fileDownload(blob, file.name);
                            },
                          });
                        }}
                      />
                    </ActionIcon>

                    {canGroup('delete', 'docs') && (
                      <ActionIcon
                        size={25}
                        color="red"
                        variant="light"
                        radius="50%"
                      >
                        <IconTrashX
                          size={18}
                          onClick={() => {
                            removeMutation.mutate(file.name);
                          }}
                        />
                      </ActionIcon>
                    )}
                  </Group>
                </Group>
              </Box>
            </Group>
          </Card>
        );
      })}
    </div>
  );
};
