import { Title, createStyles, rem, Group } from '@mantine/core';
import { isDark } from '../../core/theme/utils';
import { IconChartPieFilled } from '@tabler/icons-react';

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    borderRadius: '50%',
    padding: rem(10),
    background: isDark(theme) ? theme.white : theme.colors.blue[6],
    width: rem(30),
    height: rem(30),
  },
  logo: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: rem(14),
    color: isDark(theme) ? theme.colors.blue[6] : theme.white,

    [theme.fn.smallerThan('md')]: { fontSize: rem(14) },
  },
  label: {
    position: 'relative',
    // top: rem(1),
    fontSize: rem(22),
    lineHeight: '100%',
  },
  icon: { '> svg': { path: { color: theme.colors.blue[6] } } },
}));

export const Logo = (props) => {
  const { classes } = useStyles();

  return (
    <Group spacing={5} noWrap {...props} className={classes.icon}>
      {/*<Image maw={25} src={logo} alt="Logo" />*/}
      <IconChartPieFilled size={25} />

      <Title pt={4} className={classes.label}>
        Portal
      </Title>
    </Group>
  );
};

// export const Logo = (props) => {
//   const { classes } = useStyles();
//
//   return (
//     <Group spacing={3} noWrap {...props}>
//       <div className={classes.wrapper}>
//         <Title className={classes.logo}>NA</Title>
//       </div>
//
//       <Title className={classes.label}>Portal</Title>
//     </Group>
//   );
// };
