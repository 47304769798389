import { useDeleteGroup } from '../../hooks/use-group-mutation';
import { useSpinner } from 'core/providers/SpinnerProvider';
import { openDeleteModal } from 'components/ui/Modal';
import { Table as TableComponent } from 'components/Table';
import { columnsConfig } from './columns-config';
import { DrawerFactory } from 'core/providers/DrawerProvider';
import { AddForm, EditForm } from './form';
import { MobileRender } from './MobileRender';
import { filter } from './filter';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserLocation } from 'hooks';

export const Groups = () => {
  const deleteGroupMutation = useDeleteGroup();
  const spinner = useSpinner();
  const navigate = useNavigate();
  const { country, city } = useUserLocation();

  const addGroupHandler = () => {
    DrawerFactory.open({
      header: 'Новая группа',
      component: <AddForm />,
      padding: 0,
    });
  };

  const editGroupHandler = (group) => {
    DrawerFactory.open({
      header: 'Редактирование группы',
      component: <EditForm group={group} />,
      padding: 0,
    });
  };

  const deleteGroupHandler = (row) => {
    return openDeleteModal({
      onConfirm: () => {
        spinner.setActive(true);

        deleteGroupMutation.mutate(row.id, {
          // onSuccess: () => {
          //   showSuccess({ title: 'Группа удалена' });
          // },
          onSettled: () => spinner.setActive(false),
        });
      },
    });
  };

  useEffect(() => {
    if (country || city) {
      navigate({ search: `?city=${city || ''}&country=${country || ''}` });
    }
  }, [country, city]);

  return (
    <TableComponent
      resource="group"
      title="Группы"
      columns={columnsConfig}
      resourceEndpoint="/group"
      tableKey="groups"
      onAdd={addGroupHandler}
      onDelete={deleteGroupHandler}
      onEdit={editGroupHandler}
      useGroupIdFromRow={true}
      mobileRender={(row) => <MobileRender row={row} />}
      filter={filter}
      placeFilterInHeader={true}
    />
  );
};
