import { MobileCard, MobileCell, MobileDivider } from 'components/Table/mobile';
import { CurrencyCell, DateTimeCell } from 'components/Table/cells';
import { Group, Text, useMantineTheme, ThemeIcon } from '@mantine/core';
import {
  IconCalendarFilled,
  IconSquareRoundedPlusFilled,
  IconSquareRoundedMinusFilled,
} from '@tabler/icons-react';
import {
  FINANCE_OPERATION_TYPE,
  FINANCE_SOURCE_TYPE,
} from 'modules/group/const';

export const MobileRender = ({ row }) => {
  const theme = useMantineTheme();
  const isIncome = row.operationType === FINANCE_OPERATION_TYPE.income.name;

  return (
    <MobileCard row={row}>
      <Group align="center" spacing={5} position="apart">
        <Group spacing={5} noWrap>
          <ThemeIcon
            color={isIncome ? 'teal' : 'pink'}
            size={30}
            variant="light"
          >
            {isIncome ? (
              <IconSquareRoundedPlusFilled />
            ) : (
              <IconSquareRoundedMinusFilled />
            )}
          </ThemeIcon>
          <Text tt="uppercase" fw={700} color={theme.colors['blue'][6]}>
            {FINANCE_OPERATION_TYPE[row.operationType]?.label}
          </Text>
        </Group>

        <Group spacing={2} pr={10} noWrap>
          <IconCalendarFilled size={20} color="blue" />
          <DateTimeCell
            dateValue={row.createdAt}
            fw={700}
            dateSeparator="."
            color={theme.colors['blue'][6]}
          />
        </Group>
      </Group>

      <MobileDivider />

      <Group position="apart">
        <MobileCell name="sourceType">
          <Text color="dimmed">
            {FINANCE_SOURCE_TYPE[row.sourceType].label}
          </Text>
        </MobileCell>

        <MobileCell name="amount">
          <CurrencyCell value={row.amount} color="dimmed" />
        </MobileCell>
      </Group>

      <MobileDivider />
    </MobileCard>
  );
};
