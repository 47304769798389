import { IconPlus } from '@tabler/icons-react';
import { Button } from '@mantine/core';

export const AddButton = ({ form, isActive }) => {
  return (
    <>
      <Button
        disabled={!isActive}
        size="xs"
        mb={20}
        color="teal"
        leftIcon={<IconPlus size={14} />}
        onClick={() => {
          form.insertListItem(
            'schedule',
            {
              day: '',
              time: '',
              subject: '',
              description: '',
            },
            0,
          );
        }}
      >
        Добавить
      </Button>
    </>
  );
};
