import { object, string, number, array } from 'yup';
import { yupResolver } from '@mantine/form';
import { messages } from 'utils/messages';

export const jobSchema = object({
  title: string().required(messages.required),
  day: string().nullable(),
  roles: array().min(1, messages.required).of(string()),
  condition: string(),
  description: string(),
  period: number()
    .transform((c, o) => (o === '' ? null : c))
    .min(0)
    .max(60, 'Макс. значение 60 месяцев'),
});

export const initialFormState = {
  initialValues: {
    title: '',
    roles: [],
    condition: '',
    description: '',
    period: 0,
  },
  validate: yupResolver(jobSchema),
  validateInputOnBlur: true,
};
