import { PaperBackground } from 'components/theme-backgrounds';
import { Button, Flex, Text } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';

export const NoResults = ({ onAdd, title }) => {
  return (
    <PaperBackground p={50}>
      <Flex direction="column" align="center" justify="center" gap={20}>
        <Text color="grey" align="center">
          {title || 'Не найдено записей'}
        </Text>
        {onAdd && (
          <Button
            size="xs"
            display="block"
            color="teal"
            leftIcon={<IconPlus size={16} />}
            onClick={() => {
              if (typeof onAdd === 'function') {
                onAdd();
              }
            }}
          >
            Создать
          </Button>
        )}
      </Flex>
    </PaperBackground>
  );
};
