import { Avatar, Group, Text } from '@mantine/core';
import { getFullName } from 'utils/string';

export const EmailCell = ({ row, size = 40, withAvatar = true }) => {
  if (!row) {
    return null;
  }

  const fullName = getFullName(row);

  return (
    <Group spacing="sm">
      {withAvatar && (
        <Avatar size={size} src={encodeURI(row.avatar?.fileUrl)} radius={40} />
      )}
      <div>
        <Text fz="sm" fw={500}>
          {fullName || 'имя не указано'}
        </Text>
        <Text fz="xs" c="dimmed">
          {row.email}
        </Text>
      </div>
    </Group>
  );
};
