import { GLOBAL_ROLE, GROUP_ROLE } from '../roles';
import { createFullAccess } from '../utils';

export const financePermissions = [
  ...createFullAccess(GLOBAL_ROLE.admin, 'finance'),
  ...createFullAccess(GROUP_ROLE.group_admin, 'finance'),
  ...createFullAccess(GROUP_ROLE.group_paymaster, 'finance'),

  // { role: GROUP_ROLE.group_member, resource: 'finance', action: 'read' },
  // { role: GROUP_ROLE.group_leader, resource: 'finance', action: 'read' },
  {
    role: GROUP_ROLE.group_storekeeper,
    resource: 'finance',
    action: 'read',
  },
];
