import { BaseForm } from './BaseForm';
import { useEditJob } from 'modules/group/hooks/use-job-mutation';
import { initialFormState } from './schema';
import { useForm, FormProvider } from 'components/ui/form';
import { DrawerFactory } from 'core/providers/DrawerProvider';

export const EditForm = ({ data }) => {
  const form = useForm({ ...initialFormState, initialValues: data });

  const updateMutation = useEditJob();

  const submitHandler = (values) => {
    updateMutation.mutate(values, {
      onSuccess: () => {
        form.reset();
        DrawerFactory.close();
      },
    });
  };

  return (
    <FormProvider form={form}>
      <BaseForm onSubmit={submitHandler} />
    </FormProvider>
  );
};
