import { object, string, array } from 'yup';
import { yupResolver } from '@mantine/form';
import { messages } from 'utils/messages';

export const memberSchema = object({
  roles: array().of(string()),
  comment: string(),
});

export const initialFormState = {
  initialValues: {
    roles: [],
    comment: '',
  },
  validate: yupResolver(memberSchema),
  validateInputOnBlur: true,
};

export const addUserSchema = object({
  email: string().email(messages.email).required(messages.required),
});

export const addUserInitialFormState = {
  initialValues: {
    email: '',
  },
  validate: yupResolver(addUserSchema),
  validateInputOnBlur: true,
};
