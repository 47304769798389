import { modals } from '@mantine/modals';
import { Text, Button, Stack } from '@mantine/core';
import { authLinks } from 'modules/auth/routing/routes';

const Message = () => {
  return (
    <Stack align="center">
      <Text weight={700}>Время действия вашей сессии истекло</Text>

      <Text size="sm" mb={20} mt={10}>
        Пожалуйста, авторизуйтесь заново
      </Text>

      <Button
        onClick={() => {
          window.location.href = authLinks.login;
        }}
      >
        Войти
      </Button>
    </Stack>
  );
};

export const openReAuthModal = () => {
  return modals.open({
    children: <Message />,
    trapFocus: false,
    withCloseButton: false,
    radius: 'lg',
  });
};
