import { JobsCell, UserCell } from './cells';

export const columnsConfig = [
  {
    title: 'Участник',
    name: 'email',
    maxWidth: 300,
    render: (row) => <UserCell row={row} />,
  },
  {
    title: 'Служение',
    name: 'groupRoles',
    maxWidth: 250,
    render: (row) => <JobsCell row={row} />,
  },
  {
    title: 'Комментарий',
    name: 'groupComment',
    // maxWidth: 200,
  },
];
