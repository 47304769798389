import { useMutation } from '@tanstack/react-query';
import { addStoreItem, editStoreItem, deleteStoreItem, fixStore } from '../api';
import { useOptions } from './use-query-options';

const mutationOptions = ({ queryClient, spinner, groupId }) => ({
  onMutate: () => {
    spinner.setActive(true);
  },
  onSettled: () => {
    spinner.setActive(false);
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['group', groupId] });
    queryClient.invalidateQueries({ queryKey: ['store', groupId] });
  },
});

export const useAddStoreItem = () => {
  const options = useOptions();

  return useMutation({
    mutationFn: (item) => addStoreItem({ groupId: options.groupId, item }),
    ...mutationOptions(options),
  });
};

export const useEditStoreItem = () => {
  const options = useOptions();

  return useMutation({
    mutationFn: (item) => editStoreItem({ groupId: options.groupId, item }),
    ...mutationOptions(options),
  });
};

export const useDeleteStoreItem = () => {
  const options = useOptions();

  return useMutation({
    mutationFn: (itemId) =>
      deleteStoreItem({ groupId: options.groupId, itemId }),
    ...mutationOptions(options),
  });
};

export const useFixStore = () => {
  const { groupId, queryClient, spinner } = useOptions();

  return useMutation({
    mutationFn: (data) => fixStore({ groupId, data }),
    onMutate: () => {
      spinner.setActive(true);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['group', groupId] });
      queryClient.invalidateQueries({ queryKey: ['store', groupId] });
      queryClient.invalidateQueries({ queryKey: [`orders-${groupId}`] });
    },

    onSettled: () => {
      spinner.setActive(false);
    },
  });
};
