import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSpinner } from 'core/providers/SpinnerProvider';
import {
  addNews,
  addGroupNews,
  updateNews,
  deleteNews,
  deleteGroupNews,
  updateGroupNews,
} from '../api';
import { useGroupId } from 'modules/group/hooks/use-group-query';

const mutationOptions = ({ queryClient, spinner, groupId }) => ({
  onMutate: () => {
    spinner.setActive(true);
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['news'] });

    if (groupId) {
      queryClient.invalidateQueries({ queryKey: ['group-news', groupId] });
    }
  },

  onSettled: () => {
    spinner.setActive(false);
  },
});

const useOptions = () => {
  const queryClient = useQueryClient();
  const spinner = useSpinner();
  const groupId = useGroupId();

  return {
    queryClient,
    spinner,
    groupId,
  };
};

export const useAddNews = (groupId) => {
  const options = useOptions();

  const addFn = groupId ? addGroupNews : addNews;

  return useMutation({ mutationFn: addFn, ...mutationOptions(options) });
};

export const useUpdateNews = (groupId) => {
  const options = useOptions();

  const updateFn = groupId ? updateGroupNews : updateNews;

  return useMutation({
    mutationFn: (data) => updateFn({ news: data, id: data.id }),
    ...mutationOptions(options),
  });
};

export const useDeleteNews = (groupId) => {
  const options = useOptions();

  const deleteFn = groupId ? deleteGroupNews : deleteNews;

  return useMutation({
    mutationFn: (data) => deleteFn(data),
    ...mutationOptions(options),
  });
};
