import { CurrencyCell } from 'components/Table/cells';
import { useGroup } from 'modules/group/context';

export const MoneyCell = ({ row }) => {
  const group = useGroup();

  return (
    <CurrencyCell value={row?.money} currency={group?.currency} onlyValue />
  );
};
