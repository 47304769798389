import { FilterForm } from './FilterForm';
import { WEEK_DAYS } from 'core/const';

export const filter = {
  initialState: {
    day: '',
    city: '',
    country: '',
  },
  labels: {
    day: (value) => WEEK_DAYS[value],
  },
  Form: FilterForm,
};
