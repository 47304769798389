import { MoneyCell } from './cells';
import {
  UpdatedCell,
  DateTimeCell,
  CurrencyCell,
} from 'components/Table/cells';

export const columnsConfig = [
  {
    title: 'Дата',
    name: 'date',
    maxWidth: 50,
    render: (row) => <DateTimeCell dateValue={row.date} withTime={false} />,
  },
  {
    title: 'Ведущий',
    name: 'leader',
    maxWidth: 200,
  },
  {
    title: 'Люди',
    name: 'participants',
    maxWidth: 50,
  },
  {
    title: '7-ая',
    name: 'money',
    maxWidth: 50,
    render: (row) => <MoneyCell row={row} />,
  },
  {
    title: 'Литература',
    name: 'moneyLiterature',
    width: 130,
    maxWidth: 130,
    render: (row) => <CurrencyCell value={row?.moneyLiterature} />,
  },
  {
    title: 'Перевод',
    name: 'moneyTransfer',
    width: 130,
    maxWidth: 130,
    render: (row) => <CurrencyCell value={row?.moneyTransfer} />,
  },
  {
    title: 'Новички',
    name: 'newcomersCount',
    maxWidth: 50,
  },
  {
    title: 'Изменено',
    name: 'updatedAt',
    width: 200,
    render: (row) => <UpdatedCell row={row} />,
  },
];
