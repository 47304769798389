import { Group, Text, rem, Box } from '@mantine/core';
import { IconArchiveFilled } from '@tabler/icons-react';

const Wrapper = ({
  children,
  bg = 'white',
  color = 'black',
  withBorder = true,
  ...rest
}) => (
  <Box
    bg={bg}
    pl={15}
    pr={15}
    pt={5}
    pb={5}
    style={{
      borderRadius: rem(8),
      color,
      fontSize: rem(12),
      fontWeight: 700,
      border: withBorder ? '1px solid' : 'none',
    }}
    {...rest}
  >
    {children}
  </Box>
);

export const Total = ({ total }) => (
  <Group spacing={5} noWrap>
    <IconArchiveFilled size={16} />
    <Text>{total}</Text>
  </Group>
);

export const TotalWithBg = ({ total, ...wrapperProps }) => (
  <Wrapper {...wrapperProps}>
    <Total total={total} />
  </Wrapper>
);
