import { object, string, date, boolean } from 'yup';
import { yupResolver } from '@mantine/form';
// import { messages } from 'utils/messages';

export const jobMemberSchema = object({
  // startDate: date().required(messages.required),
  // endDate: date().required(messages.required),
  // unregisteredUserName: string().when(
  //   'isUnregistered',
  //   ([isUnregistered], schema) => {
  //     return isUnregistered
  //       ? schema.required(messages.required)
  //       : schema.nullable();
  //   },
  // ),
  // email: string().when('isUnregistered', ([isUnregistered], schema) => {
  //   return !isUnregistered
  //     ? schema.required(messages.required)
  //     : schema.nullable();
  // }),
  startDate: date().nullable(),
  endDate: date().nullable(),
  isUnregistered: boolean(),
  unregisteredUserName: string().nullable(),
  email: string().nullable(),
  comment: string(),
});

export const initialFormState = {
  initialValues: {
    startDate: new Date(),
    endDate: null,
    isUnregistered: false,
    unregisteredUserName: '',
    email: '',
    comment: '',
  },
  validate: yupResolver(jobMemberSchema),
  validateInputOnBlur: true,
};
