import { httpClient } from 'core/providers/HttpClientProvider';

export const getUser = async (id) => {
  const { data } = await httpClient.get(`/user/${id}`);

  return data;
};

export const addUser = async (user) => {
  return await httpClient.post('/user', user);
};

export const deleteUser = async (id) => {
  return await httpClient.delete(`/user/${id}`);
};

export const updateUser = async (user) => {
  const { data } = await httpClient.put(`/user/${user.id}`, user, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return data;
};

export const updateProfile = async (user) => {
  const { data } = await httpClient.put(`/user/profile/${user.id}`, user, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return data;
};

export const searchUserByEmail = async ({ email, groupId }) => {
  const { data } = await httpClient.get(
    `/member/search-email/${email}/${groupId}`,
  );

  return data;
};

export const addUserGroup = async ({ userId, groupId }) => {
  const { data } = await httpClient.post(`/member/${userId}/${groupId}`);

  return data;
};

export const updateUserGroupRole = async ({ user, groupId }) => {
  const { data } = await httpClient.put(`/member/${user.id}/${groupId}`, user);

  return data;
};

export const deleteUserGroup = async ({ userId, groupId }) => {
  const { data } = await httpClient.delete(`/member/${userId}/${groupId}`);

  return data;
};
