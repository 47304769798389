import { useAuth } from 'modules/auth/use-auth';
import { GLOBAL_ROLE } from 'core/rbac';
import { rbac } from 'core/rbac/rbac';
import { useGroupId } from 'modules/group/hooks/use-group-query';

const checkPermission = (role, action, resource) => {
  const permission = rbac.can(role)[action](resource);

  return permission.granted;
};

const getUserGroup = (user, groupId) => {
  if (user?.groups?.length && groupId) {
    return user.groups.find((group) => group.groupId === +groupId);
  }

  return null;
};

const checkUserGroupPermission = ({ group, action, resource }) => {
  let isGranted = false;

  if (group?.roles?.length) {
    for (let role of group.roles) {
      const isGranted = checkPermission(role, action, resource);

      if (isGranted) {
        return isGranted;
      }
    }
  }

  return isGranted;
};

export const useGrantAccess = () => {
  const { user } = useAuth();
  const groupIdFromUrl = useGroupId();

  const currentUser = user || {};

  if (!currentUser?.role) {
    currentUser.role = GLOBAL_ROLE.guest;
  }

  const can = (action, resource) => {
    return checkPermission(currentUser.role, action, resource);
  };

  const canGroup = (action, resource, groupId) => {
    // check global permission first
    if (can(action, resource)) {
      return true;
    }

    const group = getUserGroup(currentUser, groupId || groupIdFromUrl);

    if (group) {
      return checkUserGroupPermission({
        group,
        action,
        resource,
      });
    }

    return false;
  };

  return { can, canGroup };
};
