import { Button, Paper, PasswordInput, Title } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { object, string } from 'yup';
import { useResetPasswordMutation } from '../hooks/use-auth-mutation';
import { authLinks } from '../routing/routes';
import { showError, showSuccess } from 'core/notifications';
import { useNavigate, Navigate } from 'react-router-dom';
import { BackToMain } from '../components/BackToMain';
import { messages } from 'utils/messages';
import { getHotkeyHandler } from '@mantine/hooks';
import { useSearch } from 'hooks';

const passwordResetSchema = object().shape({
  password: string().required(messages.required).min(8, 'Минимум 8 символов'),
  passwordConfirm: string()
    .required(messages.required)
    .when('password', ([password], schema) => {
      return schema.test('passwordConfirm', 'Пароли не совпадают', (value) => {
        return password === value;
      });
    }),
});

const initialState = {
  initialValues: { password: '', passwordConfirm: '' },
  validate: yupResolver(passwordResetSchema),
  validateInputOnBlur: true,
};

export const PasswordReset = () => {
  const { resetToken } = useSearch();

  const { mutate, isLoading } = useResetPasswordMutation();
  const form = useForm(initialState);
  const canSubmit = form.isValid() && form.isDirty();

  const navigate = useNavigate();

  const submitHandler = () => {
    mutate(
      { ...form.values, resetToken },
      {
        onSuccess: () => {
          showSuccess({ title: 'Пароль успешно изменен' });
          navigate(authLinks.login);
        },
        onError: (error) => {
          showError({
            title: 'Ошибка при изменении пароля',
            message: error.response.data.error.message,
          });
        },
      },
    );
  };

  if (!resetToken) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      <Title align="center" sx={() => ({ fontWeight: 900 })}>
        Введите новый пароль
      </Title>

      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <PasswordInput
          label="Пароль"
          placeholder="********"
          mt="md"
          {...form.getInputProps('password')}
        />
        <PasswordInput
          label="Повторить пароль"
          placeholder="********"
          mt="md"
          {...form.getInputProps('passwordConfirm')}
          onKeyDown={getHotkeyHandler([['Enter', submitHandler]])}
        />

        <Button
          fullWidth
          mt="xl"
          disabled={!canSubmit}
          loading={isLoading}
          onClick={submitHandler}
        >
          Сохранить
        </Button>

        <BackToMain />
      </Paper>
    </div>
  );
};
