import { ROLE_LABELS } from 'core/rbac';
import { Badge, Group } from '@mantine/core';

export const RoleCell = ({ row }) => {
  return (
    <Group spacing={5}>
      {row.roles.map((role) => (
        <Badge key={role} color="blue" size="xs" variant="light">
          {ROLE_LABELS[role]}
        </Badge>
      ))}
    </Group>
  );
};
