import { modals } from '@mantine/modals';
import { Text, Checkbox, Stack, Anchor } from '@mantine/core';
import { useState } from 'react';
import { STORAGE_KEY } from './state';
import { DrawerFactory } from 'core/providers/DrawerProvider';
import { EditUserForm } from 'modules/user/form';
import { useAuth } from 'modules/auth/use-auth';

const ProfileText = ({ user }) => {
  const openProfile = () => {
    DrawerFactory.open({
      header: 'Мой профиль',
      component: <EditUserForm user={user} isOwnProfile />,
    });
  };

  if (!user?.email) {
    return (
      <Text fz={14}>
        Или вы можете самостоятельно задать местоположение в настройках вашего
        профиля.
      </Text>
    );
  }

  return (
    <Text fz={14}>
      Или вы можете самостоятельно задать местоположение в настройках
      <Anchor
        onClick={() => {
          modals.closeAll();
          openProfile();
        }}
      >
        &nbsp;вашего профиля.
      </Anchor>
    </Text>
  );
};

const Message = () => {
  const [checked, setChecked] = useState(0);
  const { user } = useAuth();

  return (
    <Stack spacing={15}>
      <Text fz={14}>
        К сожалению, мы не смогли автоматически определить вашу локацию, так как
        вы запретили доступ к ней на уровне браузера.
      </Text>
      <Text fz={14}>
        Для того чтобы отображать актуальную для вашего региона информацию&nbsp;
        <Text fz={14} display="inline" fw={700}>
          (новости, мероприятия, расписание собраний)
        </Text>
        , нам необходимо знать ваше местоположение.
      </Text>
      <Text fz={14}>
        Вы можете изменить доступ к геоданным в настройках вашего браузера (
        <Anchor
          href="https://support.google.com/chrome/answer/142065?hl=ru"
          target="_blank"
        >
          Chrome
        </Anchor>
        &nbsp;,&nbsp;
        <Anchor
          href="https://support.apple.com/ru-ru/guide/personal-safety/ips9bf20ad2f"
          target="_blank"
        >
          Safari
        </Anchor>
        ).
      </Text>

      <ProfileText user={user} />

      <Checkbox
        size="sm"
        onChange={(event) => {
          const value = event.currentTarget.checked ? 1 : 0;

          setChecked(value);
          localStorage.setItem(STORAGE_KEY, value);
        }}
        checked={checked}
        label="Больше не показывать это сообщение"
        mt={15}
        mb={5}
      />
    </Stack>
  );
};

export const showInformMessage = () => {
  modals.open({
    title: (
      <Text fw={700} fz={16}>
        Ваше местоположение не определено
      </Text>
    ),
    children: <Message />,
    trapFocus: false,
    // closeOnClickOutside: false,
    // closeOnEscape: false,
    // withCloseButton: false,
  });
};
