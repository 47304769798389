import { GLOBAL_ROLE, GROUP_ROLE } from '../roles';
import { createFullAccess } from '../utils';

export const jobPermissions = [
  ...createFullAccess(GLOBAL_ROLE.admin, 'job'),
  ...createFullAccess(GROUP_ROLE.group_admin, 'job'),

  {
    role: GROUP_ROLE.group_storekeeper,
    resource: 'job',
    action: 'read',
  },
  {
    role: GROUP_ROLE.group_paymaster,
    resource: 'job',
    action: 'read',
  },
  {
    role: GROUP_ROLE.group_member,
    resource: 'job',
    action: 'read',
  },
  {
    role: GROUP_ROLE.group_leader,
    resource: 'job',
    action: 'read',
  },
];
