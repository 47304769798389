import { useMediaQuery } from 'hooks';

export const DesktopPDFRender = ({ data }) => {
  const temp_url = window.URL.createObjectURL(data);
  const { screenHeight } = useMediaQuery();

  return (
    <div style={{ height: screenHeight - 60 }}>
      <object
        style={{ border: 'none' }}
        data={temp_url}
        // type="application/pdf"
        width="100%"
        height="100%"
      >
        <span>Формат файла не поддерживается</span>
      </object>
    </div>
  );
};
