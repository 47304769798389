import {
  DatePickerField,
  TextAreaField,
  TextField,
  NumberField,
  useFormContext,
} from 'components/ui/form';
import { Expandable } from 'components/Expandable';
import { TransferList } from 'components/TransferList';
import { Group, Text } from '@mantine/core';
import { useStyles } from './MeetingForm.styles';
import {
  IconCalendarFilled,
  IconCoins,
  IconUsersGroup,
  IconHeartbeat,
  IconAward,
  IconToiletPaper,
  IconCoffee,
  IconSchool,
  IconScooter,
  IconSpeakerphone,
  IconCreditCard,
} from '@tabler/icons-react';
import { useGroup } from 'modules/group/context';
import { useBeforeUnload } from './use-before-unload';
import { InfoTooltip } from 'components/ui';

const LabelWithTooltip = ({ label, title, required }) => {
  title = required ? (
    <span>
      {title}{' '}
      <Text display="inline" c="red">
        *
      </Text>
    </span>
  ) : (
    title
  );

  return <InfoTooltip size={14} label={label} title={title} />;
};

export const BaseForm = () => {
  const { store, meetingFormOptionalFields: optionalFields = {} } = useGroup();

  const { classes } = useStyles();
  const form = useFormContext();

  const releasedData = form.getInputProps('released')?.value || [];

  useBeforeUnload();

  return (
    <div className={classes.form}>
      <Group className={classes.fieldsGroup}>
        <DatePickerField
          clearable
          icon={<IconCalendarFilled size="1rem" />}
          maxDate={new Date()}
          valueFormat="DD.MM.YYYY"
          required
          label="Дата"
          placeholder="Дата"
          // name="dateISO"
          name="date"
        />
        <TextField
          required
          label="Ведущий"
          name="leader"
          icon={<IconSpeakerphone size="1rem" />}
        />
      </Group>

      <Group className={classes.fieldsGroup}>
        <NumberField
          min={1}
          required
          label="Присутствовало"
          name="participants"
          icon={<IconUsersGroup size="1rem" />}
        />
        <NumberField
          label={
            <LabelWithTooltip
              required
              title="7-ая традиция"
              label="Наличные деньги, собранные на группе"
            />
          }
          icon={<IconCoins size="1rem" />}
          name="money"
          step={10000}
          min={0}
        />
      </Group>

      <Group className={classes.fieldsGroup}>
        {optionalFields.teaMan && (
          <TextField
            label="Чайханщик"
            name="teaMan"
            icon={<IconCoffee size="1rem" />}
            style={{ flexGrow: optionalFields.cleaner ? 1 : 0 }}
          />
        )}
        {optionalFields.cleaner && (
          <TextField
            style={{ flexGrow: optionalFields.teaMan ? 1 : 0 }}
            label="Уборщик"
            name="cleaner"
            icon={<IconToiletPaper size="1rem" />}
          />
        )}
      </Group>

      <TextField
        label="Новички (необязательное поле)"
        name="newcomers"
        icon={<IconHeartbeat size="1rem" />}
        placeholder="Имена через запятую или пробел"
      />
      {optionalFields.guests && (
        <TextField
          label="Гости (необязательное поле)"
          name="guests"
          icon={<IconScooter size="1rem" />}
          placeholder="Имена через запятую или пробел"
        />
      )}
      {optionalFields.before30days && (
        <TextField
          label="До 30 дней (необязательное поле)"
          name="before30days"
          icon={<IconSchool size="1rem" />}
          placeholder="Имена через запятую или пробел"
        />
      )}
      {optionalFields.anniversaries && (
        <TextField
          label="Юбиляры (необязательное поле)"
          name="anniversaries"
          icon={<IconAward size="1rem" />}
          placeholder="Имена через запятую или пробел"
        />
      )}

      <Expandable
        label="Добавить литературу/медальки"
        content={
          <TransferList
            height={300}
            needReset={releasedData}
            data={store}
            dataOut={releasedData}
            labels={{ in: 'Наличие', out: 'Продано' }}
            onChange={(data) => form.setFieldValue('released', data.out)}
            accessor="priceOut"
          />
        }
        buttonGradient
        mt={10}
        isOpened={releasedData.length > 0}
      />

      <Group className={classes.fieldsGroup}>
        <NumberField
          label={
            <LabelWithTooltip
              title="Перевод на карту"
              label="Деньги, поступившие на карту в день проведения собрания (обычно заполняется казначеем)"
            />
          }
          icon={<IconCreditCard size="1rem" />}
          name="moneyTransfer"
          step={1000}
          min={0}
        />
        <NumberField
          // label={
          //   <LabelWithTooltip
          //     title="Продажа литературы"
          //     label="Деньги, полученные за продажу литературы"
          //   />
          // }
          label="Проданная литература"
          icon={<IconCoins size="1rem" />}
          name="moneyLiterature"
          step={1000}
          min={0}
        />
      </Group>

      {optionalFields.moneyRent && (
        <NumberField
          className={classes.rentField}
          label={
            <LabelWithTooltip
              title="Аренда помещения"
              label="Деньги, уплаченные за аренду помещения в день проведения собрания (обычно заполняется казначеем)"
            />
          }
          icon={<IconCoins size="1rem" />}
          name="moneyRent"
          step={1000}
          min={0}
        />
      )}

      <TextAreaField
        label="Комментарий"
        // placeholder="Комментарий..."
        name="comment"
        minRows={3}
        mt={10}
      />
    </div>
  );
};
