import { GLOBAL_ROLE, GROUP_ROLE } from '../roles';
import { createFullAccess } from '../utils';

export const meetingPermissions = [
  ...createFullAccess(GLOBAL_ROLE.admin, 'meeting'),
  ...createFullAccess(GROUP_ROLE.group_admin, 'meeting'),
  ...createFullAccess(GROUP_ROLE.group_leader, 'meeting'),
  ...createFullAccess(GROUP_ROLE.group_paymaster, 'meeting'),
  ...createFullAccess(GROUP_ROLE.group_storekeeper, 'meeting'),

  {
    role: GROUP_ROLE.group_member,
    resource: 'meeting',
    action: 'read',
  },
];
