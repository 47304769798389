const isValidPath = (path) => path && !path?.includes(':');

export const getNavItems = (config = []) => {
  const menu = [];

  config.forEach(
    ({ path, title, children, icon, index, onlyNavigation, resource }) => {
      if (title) {
        if (!path && !index) {
          menu.push({ title, icon });
        }
        if (isValidPath(path)) {
          const subMenu = children?.length ? getNavItems(children) : [];

          menu.push({
            path,
            title,
            icon,
            subMenu,
            onlyNavigation,
            resource,
          });
        }
      }
    },
  );

  return menu;
};

export const getRoutes = (config = [], checkPermission) => {
  const routes = [];

  const canAccessRoute = (route) => {
    if (route.resource && typeof checkPermission === 'function') {
      return checkPermission('read', route.resource);
    }

    return true;
  };

  config.forEach((route) => {
    if (route.path && route.element && canAccessRoute(route)) {
      routes.push({
        path: route.path,
        element: route.element,
        children: route.children || [],
      });
    }
  });

  return routes;
};
