import { LoadingOverlay, Loader, Box } from '@mantine/core';
import { useStyles } from './Spinner.styles';

export const Spinner = ({
  visible = true,
  center = false,
  fullscreen = false,
  ...rest
}) => {
  const { classes } = useStyles();

  if (!visible) {
    return null;
  }

  if (center) {
    return (
      <Box className={classes.centered}>
        <Loader {...rest} />
      </Box>
    );
  }

  if (fullscreen) {
    return (
      <div className="full-screen-fixed">
        <LoadingOverlay visible={visible} overlayBlur={0} {...rest} />
      </div>
    );
  }

  return <Loader {...rest} />;
};
