import { createStyles, rem } from '@mantine/core';
import { whiteDark, isDark } from 'core/theme/utils';

export const useStyles = createStyles((theme) => ({
  uploader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: rem(10),
    backgroundColor: whiteDark(theme),
    padding: rem(20),
    borderRadius: theme.radius.md,
    border: '1px solid',
    borderColor: isDark(theme) ? theme.colors.gray[8] : theme.colors.gray[4],
  },
}));
