import { GLOBAL_ROLE, GROUP_ROLE } from '../roles';
import { createFullAccess } from '../utils';

export const newsPermissions = [
  ...createFullAccess(GLOBAL_ROLE.admin, 'news'),
  ...createFullAccess(GLOBAL_ROLE.newsMaker, 'news'),
  ...createFullAccess(GROUP_ROLE.group_admin, 'news'),

  {
    role: GROUP_ROLE.group_member,
    resource: 'news',
    action: 'read',
  },
  {
    role: GROUP_ROLE.group_leader,
    resource: 'news',
    action: 'read',
  },
  {
    role: GROUP_ROLE.group_storekeeper,
    resource: 'news',
    action: 'read',
  },
  {
    role: GROUP_ROLE.group_paymaster,
    resource: 'news',
    action: 'read',
  },

  // { role: GLOBAL_ROLE.user, resource: 'news', action: 'read' },
  // { role: GLOBAL_ROLE.guest, resource: 'news', action: 'read' },
];
