export const getAddressByCoords = async ({ lat, lng }) => {
  try {
    if (!lat || !lng) {
      return {};
    }

    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json&accept-language=ru-RU`,
    );

    if (!response.ok) {
      return {};
    }

    return await response.json();
  } catch (error) {
    console.error('Ошибка при определении геолокации:', error);

    return {};
  }
};
