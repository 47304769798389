import { createRoot } from 'react-dom/client';
import { App } from './components/App';
// import { registerServiceWorker } from './register-service-worker';
// import { registerWorkBox } from './register-workbox';

const rootElement = document.getElementById('root');

if (!rootElement) {
  throw new Error('Failed to find the root element');
}

const root = createRoot(rootElement);

root.render(<App />);

// registerServiceWorker();
// registerWorkBox();
