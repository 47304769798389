import { Title, Text, Container } from '@mantine/core';
import { useStyles } from './Maintenance.styles';
import { IconPuzzleFilled } from '@tabler/icons-react';

export function Maintenance() {
  const { classes } = useStyles();

  return (
    <Container className={classes.root}>
      <div className={classes.label}>
        <IconPuzzleFilled size="20%" />
      </div>
      <Title className={classes.title}>Технические работы</Title>
      <Text
        color="dimmed"
        size="lg"
        align="center"
        className={classes.description}
      >
        В настоящий момент на нашем сайте ведутся технические работы с целью
        улучшения его функциональности и качества обслуживания. Мы приносим
        извинения за временные неудобства и благодарим вас за понимание.
      </Text>
    </Container>
  );
}
