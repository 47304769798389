import { GLOBAL_ROLE, GROUP_ROLE } from '../roles';
import { createFullAccess } from '../utils';

export const requestPermissions = [
  ...createFullAccess(GLOBAL_ROLE.admin, 'request'),
  ...createFullAccess(GROUP_ROLE.group_admin, 'request'),

  {
    role: GLOBAL_ROLE.user,
    resource: 'request',
    action: 'create',
  },
];
