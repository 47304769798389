import { Select } from '@mantine/core';
import { useFormContext } from './context';

export const SelectField = ({ name, onChange: onChangeFromProps, ...rest }) => {
  const form = useFormContext();
  const selectProps = form.getInputProps(name);

  return (
    <Select
      {...selectProps}
      onChange={(value) => {
        selectProps.onChange(value);

        if (typeof onChangeFromProps === 'function') {
          onChangeFromProps(value);
        }
      }}
      dropdownPosition="bottom"
      {...rest}
    />
  );
};
