import { ActionsCell, TruncatedCell } from './cells';
import { useStyles } from './Table.styles';
import { Box, Text, Group } from '@mantine/core';
import { StatusIndicator } from '../StatusIndicator';
import { useDisclosure } from '@mantine/hooks';
import { IconChevronDown } from '@tabler/icons-react';
import { useTable } from './context';

export const ExpandableRow = ({ row, render, classes }) => {
  const [opened, { toggle }] = useDisclosure(false);

  return (
    <Box className={classes?.rowExpandable}>
      {opened && (
        <Box className={classes?.rowExpandableContent}>{render(row)}</Box>
      )}

      <Group
        spacing={5}
        align="center"
        position="center"
        className={classes?.rowExpandableToggle}
        onClick={toggle}
      >
        <Text>{opened ? 'Закрыть' : 'Подробнее'}</Text>
        <IconChevronDown
          size={16}
          style={{ transform: opened ? 'rotate(180deg)' : '' }}
        />
      </Group>
    </Box>
  );
};

export const TableRow = ({ row }) => {
  const { classes } = useStyles({ backgroundStatus: row.backgroundStatus });
  const { columns, expandableRowRender } = useTable();

  return (
    <div className={classes.row}>
      <StatusIndicator status={row.status} />

      {/* Main row */}
      <Box style={{ position: 'relative' }}>
        <div className={classes.rowColumns}>
          {columns.map((column) => {
            const cell = column.render ? column.render(row) : row[column.name];

            return (
              <Box
                key={column.name}
                miw={column.width}
                maw={column.maxWidth}
                className={classes.cell}
              >
                {column.truncated ? <TruncatedCell value={cell} /> : cell}
              </Box>
            );
          })}
        </div>

        <ActionsCell row={row} className={classes.rowActions} />
      </Box>

      {expandableRowRender && (
        <ExpandableRow
          render={expandableRowRender}
          row={row}
          classes={classes}
        />
      )}
    </div>
  );
};
