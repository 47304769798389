import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  addUser,
  deleteUser,
  updateUser,
  updateProfile,
  updateUserGroupRole,
  deleteUserGroup,
  addUserGroup,
} from '../api';
import { useSpinner } from 'core/providers/SpinnerProvider';
import { useGroupId } from 'modules/group/hooks/use-group-query';

const mutationOptions = ({ queryClient, spinner }) => ({
  onMutate: () => {
    spinner.setActive(true);
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['users'] });
  },

  onSettled: () => {
    spinner.setActive(false);
  },
});

const useOptions = () => {
  const queryClient = useQueryClient();
  const spinner = useSpinner();

  return { queryClient, spinner };
};

export const useAddUser = () => {
  const options = useOptions();

  return useMutation({ mutationFn: addUser, ...mutationOptions(options) });
};

export const useUpdateUser = (isOwnProfile) => {
  const options = useOptions();

  return useMutation({
    mutationFn: isOwnProfile ? updateProfile : updateUser,
    ...mutationOptions(options),
  });
};

export const useDeleteUser = () => {
  const options = useOptions();

  return useMutation({ mutationFn: deleteUser, ...mutationOptions(options) });
};

export const useAddUserGroup = () => {
  const { spinner, queryClient } = useOptions();
  const groupId = useGroupId();

  return useMutation({
    mutationFn: (userId) => addUserGroup({ userId, groupId }),
    onMutate: () => {
      spinner.setActive(true);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['members'] });
      queryClient.invalidateQueries({ queryKey: [`members-group-${groupId}`] });
    },

    onSettled: () => {
      spinner.setActive(false);
    },
  });
};

export const useUpdateUserGroupRole = () => {
  const { spinner, queryClient } = useOptions();
  const groupId = useGroupId();

  return useMutation({
    mutationFn: (user) => updateUserGroupRole({ user, groupId }),
    onMutate: () => {
      spinner.setActive(true);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['members'] });
      queryClient.invalidateQueries({ queryKey: [`members-group-${groupId}`] });
    },

    onSettled: () => {
      spinner.setActive(false);
    },
  });
};

export const useDeleteUserGroup = () => {
  const { spinner, queryClient } = useOptions();
  const groupIdFromUrl = useGroupId();

  return useMutation({
    mutationFn: ({ userId, groupId }) => deleteUserGroup({ userId, groupId }),
    onMutate: () => {
      spinner.setActive(true);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['members'] });
      // Staff section
      queryClient.invalidateQueries({
        queryKey: [`members-group-${groupIdFromUrl}`],
      });
      // ActiveList section
      queryClient.invalidateQueries({
        queryKey: [`members-${groupIdFromUrl}`],
      });
    },

    onSettled: () => {
      spinner.setActive(false);
    },
  });
};
