import { STATUS_LABELS, STATUS } from '../../const';
import { Badge } from '@mantine/core';

export const StatusCell = ({ value, ...rest }) => {
  const color = value === STATUS.completed ? 'teal' : 'blue';

  return (
    <Badge color={color} {...rest}>
      {STATUS_LABELS[value]}
    </Badge>
  );
};
