import { useRef } from 'react';
import { NumberInput, Group, ActionIcon, rem } from '@mantine/core';

export const IncrementNumberInput = ({ value, onChange, height = 40 }) => {
  const handlers = useRef();

  return (
    <Group spacing={5} noWrap>
      <ActionIcon
        size={height}
        variant="default"
        onClick={() => handlers.current.decrement()}
      >
        –
      </ActionIcon>

      <NumberInput
        hideControls
        value={value || 0}
        onChange={(v) => onChange(v)}
        handlersRef={handlers}
        max={1000}
        min={0}
        step={1}
        styles={{
          input: {
            width: rem(50),
            textAlign: 'center',
            height: rem(height),
            minHeight: rem(height),
          },
        }}
      />

      <ActionIcon
        size={height}
        variant="default"
        onClick={() => handlers.current.increment()}
      >
        +
      </ActionIcon>
    </Group>
  );
};
