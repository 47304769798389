import {
  Avatar,
  Badge,
  Group,
  Text,
  Box,
  Indicator,
  Flex,
  ActionIcon,
} from '@mantine/core';
import {
  IconLogout,
  IconTrash,
  IconPaint,
  IconUserCircle,
  IconEdit,
  IconFlag3,
  IconMapPinFilled,
} from '@tabler/icons-react';
import { ThemeSwitcher } from 'components/ThemeSwitcher';
import {
  useDeleteUser,
  useDeleteUserGroup,
} from 'modules/user/hooks/use-user-mutation';
import { showSuccess } from 'core/notifications';
import { useStyles } from './Header.styles';
import { openDeleteModal } from 'components/ui/Modal';
import { DrawerFactory } from 'core/providers/DrawerProvider';
import { EditUserForm } from 'modules/user/form';
import { useNavigate } from 'react-router-dom';
import { ROLE_LABELS } from 'core/rbac';
import { Section } from 'components/Drawer';
import { authApi } from '../../../modules/auth/state';
import { datesDiff } from 'utils/date';
import { getPlural } from 'utils/string';

export const UserInfo = ({ user, logout }) => {
  const { classes, theme } = useStyles();
  const navigate = useNavigate();

  const statusColor = user?.status === 'active' ? 'teal' : 'red';
  const userName =
    `${user?.firstName || ''} ${user?.lastName || ''}`.trim() ||
    'Имя не указано';
  const avatarColor =
    theme.colorScheme === 'dark' ? theme.white : theme.colors.blue[6];

  const { mutate: deleteUser } = useDeleteUser();

  const deleteUserHandler = () => {
    return openDeleteModal({
      title: 'Удаление аккаунта',
      children:
        'Вы действительно хотите удалить ваш аккаунт? Все данные будут утеряны.',
      onConfirm: () => {
        deleteUser(user.id, {
          onSuccess: () => {
            logout();
            showSuccess({ title: 'Пользователь удален' });
          },
        });
      },
    });
  };

  const deleteUserGroupMutation = useDeleteUserGroup();

  const deleteUserGroupHandler = (groupId) => {
    return openDeleteModal({
      title: 'Удаление группы',
      children:
        'Вы действительно хотите удалить группу? Доступ к группе будет закрыт.',
      onConfirm: () => {
        deleteUserGroupMutation.mutate(
          { userId: user.id, groupId },
          {
            onSuccess: (user) => {
              authApi.setUser(user);
              showSuccess({ title: 'Группа удалена из вашего списка' });
              DrawerFactory.close();
            },
          },
        );
      },
    });
  };

  const editUserHandler = () => {
    DrawerFactory.open({
      header: 'Редактирование профиля',
      component: <EditUserForm user={user} isOwnProfile />,
    });
  };

  const cleanPeriod = datesDiff(user?.cleanDate);

  const Profile = () => {
    return (
      <>
        <Group>
          <Indicator
            size={16}
            offset={7}
            position="bottom-end"
            color={statusColor}
            withBorder
          >
            <Avatar
              radius="50%"
              size="xl"
              src={encodeURI(user?.avatar?.fileUrl)}
            />
          </Indicator>

          <Box>
            <Text size="sm" weight={500}>
              {userName}
            </Text>

            <Text color="dimmed" size="xs">
              {user?.email}
            </Text>

            {(user?.country || user?.city) && (
              <Group spacing={2} className={classes.cityIconWrapper} mt={5}>
                <IconMapPinFilled size={14} />
                <Text color="dimmed" size="xs">
                  {user?.country}, {user?.city}
                </Text>
              </Group>
            )}

            <Flex
              mt={10}
              gap={10}
              align="center"
              className={classes.userInfoBadges}
            >
              <Badge variant="filled">{ROLE_LABELS[user?.role]}</Badge>
            </Flex>
          </Box>
        </Group>

        {cleanPeriod && (
          <Section title="Счетчик чистого времени">
            <Text
              mt={20}
              sx={{ fontFamily: 'Greycliff CF, sans-serif' }}
              lh={1}
              fw={700}
              variant="gradient"
              size={50}
              gradient={{
                from: 'yellow',
                to: 'red',
                deg: 45,
              }}
              align="center"
            >
              {cleanPeriod.totalDays}&nbsp;
              {getPlural(cleanPeriod.totalDays, ['день', 'дня', 'дней'])}
            </Text>

            <Text
              // gradient={{ from: 'indigo', to: 'cyan', deg: 90 }}
              sx={{ fontFamily: 'Greycliff CF, sans-serif' }}
              ta="center"
              variant="gradient"
              fw={700}
              size={25}
              gradient={{
                from: 'blue',
                to: 'pink',
                deg: 120,
              }}
              color="white"
            >
              {cleanPeriod.years}&nbsp;
              {getPlural(cleanPeriod.years, ['год', 'года', 'лет'])}&nbsp;
              {cleanPeriod.months}&nbsp;
              {getPlural(cleanPeriod.months, ['месяц', 'месяца', 'месяцев'])}
              &nbsp;
              {cleanPeriod.days}&nbsp;
              {getPlural(cleanPeriod.days, ['день', 'дня', 'дней'])}&nbsp;
            </Text>
          </Section>
        )}

        <Section title="Настройки">
          <Box className={classes.userInfoSectionItem}>
            <Group align="center" position="apart" w="100%">
              <Group align="center">
                <IconPaint size={14} style={{ flexShrink: 0 }} />
                <Text fz={14}>Сменить тему</Text>
              </Group>
              <ThemeSwitcher size="md" />
            </Group>
          </Box>
        </Section>

        <Section title="Мои группы">
          {user?.groups?.map((group) => {
            return (
              <Box
                className={classes.userInfoSectionItem}
                key={group.groupId}
                onClick={() => {
                  navigate(`/groups/${group.groupId}`);
                  DrawerFactory.close();
                }}
              >
                <Group align="center" position="apart" w="100%">
                  <Group>
                    <IconFlag3 size={14} style={{ flexShrink: 0 }} />

                    <Box>
                      <Text mb={5} fz={14}>
                        {group.groupTitle}
                      </Text>
                      <Group spacing={5}>
                        {group.roles.map((role) => (
                          <Badge
                            key={role}
                            color="blue"
                            size="xs"
                            variant="light"
                          >
                            {ROLE_LABELS[role]}
                          </Badge>
                        ))}
                      </Group>
                    </Box>
                  </Group>

                  <ActionIcon
                    variant="light"
                    color="red"
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteUserGroupHandler(group.groupId);
                    }}
                  >
                    <IconTrash size={20} />
                  </ActionIcon>
                </Group>
              </Box>
            );
          })}
        </Section>

        <Section title="Управление">
          <Box
            className={classes.userInfoSectionItem}
            onClick={() => {
              DrawerFactory.close();
              editUserHandler();
            }}
          >
            <IconEdit size={14} style={{ flexShrink: 0 }} />
            <Text fz={14}>Редактировать профиль</Text>
          </Box>

          <Box
            className={classes.userInfoSectionItem}
            onClick={() => {
              logout();
              DrawerFactory.close();
            }}
          >
            <IconLogout size={14} style={{ flexShrink: 0 }} />
            <Text fz={14}>Выйти</Text>
          </Box>

          <Box
            className={classes.userInfoSectionItem}
            onClick={() => {
              deleteUserHandler();
              DrawerFactory.close();
            }}
          >
            <IconTrash size={14} color="red" style={{ flexShrink: 0 }} />
            <Text color="red" fz={14}>
              Удалить аккаунт
            </Text>
          </Box>
        </Section>
      </>
    );
  };

  return (
    <Group
      className={classes.userInfo}
      spacing={0}
      align="center"
      onClick={() => {
        DrawerFactory.open({
          position: 'right',
          header: 'Мой профиль',
          component: <Profile />,
          bgColor: theme.white,
        });
      }}
    >
      {user.avatar?.fileUrl && (
        <Avatar radius="50%" size={35} src={encodeURI(user.avatar?.fileUrl)} />
      )}

      {!user.avatar?.fileUrl && (
        <IconUserCircle size={35} color={avatarColor} />
      )}
    </Group>
  );
};
