import { useNavigate } from 'react-router-dom';
import { Anchor } from '@mantine/core';

export const Link = ({ to, label, className, onClick, children, ...props }) => {
  const navigate = useNavigate();

  return (
    <Anchor
      href={to}
      className={className}
      onClick={(event) => {
        event.preventDefault();
        if (typeof onClick === 'function') {
          onClick();
        }
        navigate(to);
      }}
      {...props}
    >
      {label || children}
    </Anchor>
  );
};
