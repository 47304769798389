import { ProtectedRoutes } from 'core/routing/ProtectedRoutes';
import { useAuth } from 'modules/auth/use-auth';
import { useEffect } from 'react';
import { Spinner } from 'components/ui';

export const RouterProvider = () => {
  const { isLogged, isLoading, loadUser } = useAuth();

  useEffect(() => {
    if (isLogged) {
      loadUser();
    }
  }, []);

  if (isLoading) {
    return <Spinner fullscreen />;
  }

  return <ProtectedRoutes />;
};
