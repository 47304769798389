import { MobileCard, MobileCell, MobileDivider } from 'components/Table/mobile';
import { DateTimeCell } from 'components/Table/cells';
import { Group, Text } from '@mantine/core';
import { MemberCell, JobNameCell } from './cells';

export const MobileRender = ({ row }) => {
  return (
    <MobileCard key={row.id} row={row}>
      <MobileCell>
        <JobNameCell row={row} isMobile />
      </MobileCell>

      {row.userInfo && (
        <>
          <MobileDivider />

          <MobileCell name="member" withHeader={false}>
            <MemberCell userInfo={row.userInfo} />
          </MobileCell>
        </>
      )}

      {row.userInfo?.startDate && (
        <>
          <MobileDivider />
          <Group position="apart">
            <MobileCell name="startDate">
              <DateTimeCell dateValue={row.userInfo.startDate} color="dimmed" />
            </MobileCell>

            <MobileCell name="endDate" align="right">
              <DateTimeCell dateValue={row.userInfo?.endDate} color="dimmed" />
            </MobileCell>
          </Group>
        </>
      )}

      {row.userInfo?.comment && (
        <>
          <MobileDivider />

          <MobileCell name="comment">
            <Text>{row.userInfo?.comment}</Text>
          </MobileCell>
        </>
      )}
    </MobileCard>
  );
};
