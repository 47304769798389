import { useEffect } from 'react';
import { getAddressByCoords } from './get-address-by-coords';
import { setLocation, STORAGE_KEY } from './state';
import { showInformMessage } from './showInformMessage';
import { userStorageApi } from 'core/local-storage';

const user = userStorageApi.get();

export const GeoLocationProvider = ({ children }) => {
  useEffect(() => {
    if ('geolocation' in navigator && !user?.useManualLocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;

          const location = await getAddressByCoords({ lat, lng });

          setLocation({ ...location, isLoading: false });
        },
        (error) => {
          if (error.code === 1 || error.PERMISSION_DENIED) {
            const disableMessage = localStorage.getItem(STORAGE_KEY);

            if ((!user?.country || !user?.city) && disableMessage !== '1') {
              showInformMessage();
            }
          }

          console.error('Ошибка при определении геолокации:', error);
          setLocation({ isLoading: false });
        },
        { maximumAge: 60 * 1000, timeout: 5000 },
      );
    } else {
      // console.error('Геолокация не поддерживается браузером');
      setLocation({ isLoading: false });
    }
  }, []);

  return <>{children}</>;
};
