import { Table } from 'components/Table';
import { columnsConfig } from './columns-config';
import { useGroupId } from 'modules/group/hooks/use-group-query';
import { DrawerFactory } from 'core/providers/DrawerProvider';
import { AddForm, EditForm } from './form';
import { useDeleteUserGroup } from 'modules/user/hooks/use-user-mutation';
import { showSuccess } from 'core/notifications';
import { MobileRender } from './MobileRender';
import { TitleRender } from './TitleRender';

export const Staff = () => {
  const groupId = useGroupId();
  const deleteUserGroupMutation = useDeleteUserGroup();

  const addUserHandler = () => {
    DrawerFactory.open({
      header: 'Поиск пользователя',
      component: <AddForm />,
    });
  };

  const editUserHandler = (row) => {
    DrawerFactory.open({
      header: 'Список ролей',
      component: <EditForm data={row} />,
    });
  };

  const deleteUserHandler = (row) => {
    deleteUserGroupMutation.mutate(
      { userId: row.id, groupId },
      {
        onSuccess: () => {
          showSuccess({ title: 'Пользователь удален из группы' });
        },
      },
    );
  };

  return (
    <Table
      resource="member"
      id="table-group-staff"
      columns={columnsConfig}
      resourceEndpoint={`/member/group/${groupId}`}
      tableKey={`members-group-${groupId}`}
      noPadding={true}
      onView={false}
      onEdit={editUserHandler}
      onDelete={deleteUserHandler}
      onAdd={addUserHandler}
      titleRender={(data, pagination) => (
        <TitleRender onAdd={addUserHandler} totalRecords={pagination?.total} />
      )}
      mobileRender={(row) => <MobileRender row={row} />}
    />
  );
};
