import { AddButton } from 'components/ui';
import { Group, Button, Text } from '@mantine/core';
import { IconReplace } from '@tabler/icons-react';
import { useGrantAccess, useMediaQuery } from 'hooks';
import { DrawerFactory } from 'core/providers/DrawerProvider';
import { FixForm } from './form';

export const TitleRender = ({ addHandler, group }) => {
  const { canGroup } = useGrantAccess();
  const { isMobile } = useMediaQuery();

  const fixStoreHandler = () => {
    DrawerFactory.open({
      header: 'Корректировка склада',
      component: <FixForm group={{ ...group }} />,
    });
  };

  return (
    <Group
      align="center"
      spacing={10}
      mb={20}
      position={isMobile ? 'apart' : 'right'}
    >
      {canGroup('update', 'store') && (
        <Button
          variant="light"
          color="blue"
          size="xs"
          onClick={fixStoreHandler}
        >
          <IconReplace size={20} />
          <Text ml={10}>Коррекция</Text>
        </Button>
      )}
      {canGroup('create', 'store') && (
        <AddButton onClick={addHandler} ml={0} label="Новый заказ" />
      )}
    </Group>
  );
};
