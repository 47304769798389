import { Text, Box, Tooltip } from '@mantine/core';

export const TruncatedCell = ({ value }) => {
  return (
    <Box style={{ cursor: 'pointer', overflow: 'hidden' }}>
      <Tooltip
        position="top-start"
        label={<Text>{value}</Text>}
        withArrow
        events={{ hover: true, touch: true }}
      >
        <Text truncate>{value}</Text>
      </Tooltip>
    </Box>
  );
};
