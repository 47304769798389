import { createStyles, rem } from '@mantine/core';
import { isDark } from 'core/theme/utils';

export const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    gap: rem(16),
    [theme.fn.smallerThan('xs')]: { flexDirection: 'column' },
  },
  column: {
    width: `calc((50% - 8px))`,
    backgroundColor: isDark(theme) ? theme.colors.dark[6] : theme.white,
    padding: rem(12),
    paddingTop: 0,
    border: `1px solid ${
      isDark(theme) ? theme.colors.dark[4] : theme.colors.gray[4]
    }`,
    borderRadius: theme.radius.sm,

    [theme.fn.smallerThan('xs')]: { width: `100%` },
  },
  title: {
    marginBottom: rem(10),
    textAlign: 'center',
    fontSize: rem(14),
    color: isDark(theme) ? theme.colors.dark[0] : theme.colors.gray[6],
    padding: rem(8),
    borderBottom: `1px solid ${
      isDark(theme) ? theme.colors.dark[4] : theme.colors.gray[4]
    }`,
  },
  item: {
    'cursor': 'pointer',
    'padding': rem(5),

    '&:nth-of-type(odd)': {
      backgroundColor: isDark(theme)
        ? theme.colors.dark[8]
        : theme.colors.gray[0],
    },

    '&:hover': {
      backgroundColor: isDark(theme)
        ? theme.colors.blue[9]
        : theme.colors.blue[0],
      borderRadius: theme.radius.sm,
    },
  },
  exchangeIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',

    [theme.fn.smallerThan('xs')]: {
      transform: 'translate(-50%,-50%) rotate(90deg)',
    },
  },
  itemTitle: { color: isDark(theme) ? theme.white : theme.colors.dark[9] },
  itemIndicator: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: isDark(theme) ? theme.white : theme.black,
  },
}));
