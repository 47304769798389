import { Text, Group, Box, rem } from '@mantine/core';
import { NavButton } from 'components/ui';
import { authLinks } from '../routing/routes';
import { useEffect, useState } from 'react';
import { httpClient } from 'core/providers/HttpClientProvider';
import { useSearch } from 'hooks';
import { Navigate, useNavigate } from 'react-router-dom';
import { useSpinner } from 'core/providers/SpinnerProvider';
import { showAlert } from 'core/notifications';

const titleStyles = (theme) => ({
  fontFamily: 'Greycliff CF, sans-serif',
  textAlign: 'center',
  fontWeight: 900,
  fontSize: rem(38),

  [theme.fn.smallerThan('sm')]: { fontSize: rem(32) },
});

const processExpiredError = (navigate) => {
  showAlert({
    title: 'Срок действия вашей ссылки истек',
    message: <Text>Пожалуйста пройдите регистрацию еще раз</Text>,
    autoClose: 10000,
  });
  setTimeout(() => {
    navigate(authLinks.signup);
  }, 2000);
};

const processConfirmedError = (navigate) => {
  showAlert({
    title: 'Вы уже зарегистрированы',
    message: <Text>Пожалуйста войдите в систему</Text>,
    autoClose: 10000,
  });
  setTimeout(() => {
    navigate(authLinks.login);
  }, 2000);
};

export function Confirmation() {
  const { confirmationToken } = useSearch();
  const spinner = useSpinner();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (confirmationToken) {
      spinner.setActive(true);

      httpClient
        .get(`/auth/verify-email/${confirmationToken}`)
        .then(() => {
          setSuccess(true);
        })
        .catch(({ response }) => {
          if (response.status === 401) {
            processExpiredError(navigate);
          } else if (response.status === 422) {
            processConfirmedError(navigate);
          } else {
            setTimeout(() => {
              navigate('/404');
            }, 1000);
          }
        })
        .finally(() => {
          spinner.setActive(false);
        });
    }
  }, []);

  if (!confirmationToken) {
    return <Navigate to="/" />;
  }

  if (!success) {
    return null;
  }

  return (
    <Box pt={80} pb={80}>
      <Text sx={titleStyles}>Регистрация завершена</Text>
      <Text color="dimmed" size="lg" align="center" m={30}>
        Пожалуйста войдите в систему
      </Text>
      <Group position="center">
        <NavButton to={authLinks.login} size="md">
          Войти
        </NavButton>
      </Group>
    </Box>
  );
}
