import { useQuery } from '@tanstack/react-query';
import { getNews, getGroupNews } from 'pages/Home/api';
import { useGroupId } from 'modules/group/hooks/use-group-query';

const queryOptions = {
  retry: false,
  refetchOnWindowFocus: false,
  refetchOnMount: true,
  keepPreviousData: true,
  staleTime: 5000,
};

export const useNewsQuery = ({ country, city, isLoading }) => {
  return useQuery({
    queryKey: ['news'],
    queryFn: () => getNews({ country, city }),
    ...queryOptions,
    enabled: !isLoading,
  });
};

export const useGroupNewsQuery = () => {
  const groupId = useGroupId();

  return useQuery({
    queryKey: ['group-news', groupId],
    queryFn: () => getGroupNews(groupId),
    ...queryOptions,
  });
};
