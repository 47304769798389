import { UpdatedCell } from 'components/Table/cells';
import { Link } from 'components/ui';

export const columnsConfig = [
  {
    title: 'Название',
    name: 'title',
    render: (row) => <Link to={`./${row.id || row['_id']}`}>{row.title}</Link>,
    width: 200,
    maxWidth: 250,
  },
  {
    title: 'Страна',
    name: 'country',
    maxWidth: 150,
  },
  {
    title: 'Город',
    name: 'city',
    maxWidth: 150,
  },
  {
    title: 'Адрес',
    name: 'address',
    width: 200,
  },
  {
    title: 'Телефон',
    name: 'phone',
    width: 150,
    maxWidth: 150,
  },
  {
    title: 'Изменен',
    name: 'updatedAt',
    width: 200,
    render: (row) => <UpdatedCell row={row} />,
  },
];
