import { initialFormState } from './schema';
import { useForm, FormProvider } from 'components/ui/form';
import { DrawerFactory } from 'core/providers/DrawerProvider';
import { BaseForm } from './BaseForm';
import { useAddFinanceOperation } from 'modules/group/hooks/use-finance-mutation';
import { useGroupId } from 'modules/group/hooks/use-group-query';

export const AddForm = () => {
  const form = useForm(initialFormState);
  const groupId = useGroupId();
  const addMutation = useAddFinanceOperation();

  const submitHandler = (values) => {
    addMutation.mutate(
      { ...values, groupId },
      {
        onSuccess: () => {
          form.reset();
          DrawerFactory.close();
        },
      },
    );
  };

  return (
    <FormProvider form={form}>
      <BaseForm onSubmit={submitHandler} />
    </FormProvider>
  );
};
